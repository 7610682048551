import React, { Component } from 'react';

export default class DayWorkOut extends Component {
	constructor(props){
		super(props);
		this.state = {
			width: 0,
			height: 0,
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)

	}
	handleRemoveWorkOut(e){
		let workOutArray = this.props.workOuts;
		let key = e.target.getAttribute('data-index');
		let calories = workOutArray[key].calories
		this.handleWorkOutItemRemoval(workOutArray, key, calories)
	}
	handleWorkOutItemRemoval(workout, key, calories){
		workout.splice(key, 1);
		this.props.handleRemoveWorkOut(workout, calories);
	}
	componentDidMount() {
	  this.updateWindowDimensions();
	  window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	render() {
		let workOuts = this.props.workOuts.map((item, key)=>
			<div key={key}>	
				<div className="text-left mobileWorkOutSummaryTable">
					<div className="workOutSummaryMobile">
						<table className="table w-full mb-4">
							<tbody>
								<tr>
									<td className="text-left"><strong>Workout:</strong></td>
									<td>{this.props.workOuts[key].exercise}</td>
								</tr>
								<tr>
									<td><strong>Burned:</strong> </td>
									<td>{this.props.workOuts[key].calories.toFixed(0)} Calories</td>
								</tr>
								<tr>
									<td><strong>Time:</strong> </td>
									<td>{this.props.workOuts[key].minutes} Minutes</td>
								</tr>
							</tbody>
						</table>
						
					</div>
					<div className="workoutRemovalContainer">
						<button 
							className="flaticon-minus removeSnackButton" 
							onClick={this.handleRemoveWorkOut.bind(this)} 
							data-index={key}
						><span className="hidden">calculate</span></button>
					</div>
				</div>
			</div>
		);
		let desktopWorkOuts = this.props.workOuts.map((item, key)=>
			<table className="table workOutTable mb-4" key={key}>
				<tbody>
					<tr>
						<td className="w-6/12 text-left text-sm">{this.props.workOuts[key].exercise}</td>
						<td className="w-2/12 text-center">{this.props.workOuts[key].calories.toFixed(0)}</td>
						<td className="w-2/12 text-center">{this.props.workOuts[key].minutes}</td>
						<td className="w-2/12 text-right">
							<button 
								className="flaticon-minus text-2xl removeSnackButton mr-3" 
								onClick={this.handleRemoveWorkOut.bind(this)} 
								data-index={key}
							><span className="hidden">calculate</span></button>
						</td>
					</tr>
				</tbody>
			</table>
		);
		let desktopSize = this.state.width >= 767
		return (
			<div className="dayWorkOut">
				{desktopSize?
					<div>
						<table className="w-full workOutTable">
							<tbody>
								<tr>
									<th className="w-6/12 py-2 text-left"><h6>Exercise</h6></th>
									<th className="w-2/12 py-2 text-center"><h6>Calories Burned</h6></th>
									<th className="w-2/12 py-2 text-center"><h6>Minutes</h6></th>
									<th className="w-2/12 py-2 text-right"><h6>Remove</h6></th>
								</tr>
							</tbody>
						</table>
						{desktopWorkOuts}	
					</div>
					:
					<div>{workOuts}</div>
				}
			</div>
		);
	}
}
