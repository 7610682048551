export const initialPlan = [
	{
		day: 'Sunday',
		breakfast: {
			label: 'breakfast',
			mealName: 'breakfast',
			meal:{
				title: '',
			},
		},
		morningSnack: {
			label: 'morningSnack',
			mealName: 'morning snck',
			meal:{
				title: '',
			},
		},
		lunch: {
			label: 'lunch',
			mealName: 'lunch',
			meal:{
				title: '',
			},
		},
		afternoonSnack: {
			label: 'afternoonSnack',
			mealName: 'afternoon snack',
			meal:{
				title: '',
			},
		},
		dinner: {
			label: 'dinner',
			mealName: 'dinner',
			meal:{
				title: '',
			},
		},
		eveningSnack: {
			label: 'eveningSnack',
			mealName: 'evening snack',
			meal:{
				title: '',
			},
		},
	},
	{
		day: 'Monday',
		preferSnacks: false,
		breakfast: {
			label: 'breakfast',
			mealName: 'breakfast',
			meal: {
				title: ''
			}	
		},
		morningSnack: {
			label: 'morningSnack',
			mealName: 'morning snack',
			meal:{
				title: '',
			},
		},
		lunch: {
			label: 'lunch',
			mealName: 'lunch',
			meal: {
				title: ''
			},	
		},
		afternoonSnack: {
			label: 'afternoonSnack',
			mealName: 'afternoon snack',
			meal:{
				title: '',
			},
		},
		dinner: {
			label: 'dinner',
			mealName: 'dinner',
			meal:{
				title: '',
			},
		},
		eveningSnack: {
			label: 'eveningSnack',
			mealName: 'evening snack',
			meal:{
				title: '',
			},
		},
	},
	{
		day: 'Tuesday',
		breakfast: {
			label: 'breakfast',
			mealName: 'breakfast',
			meal:{
				title: '',
			},
		},
		morningSnack: {
			label: 'morningSnack',
			mealName: 'morning snack',
			meal:{
				title: '',
			},
		},
		lunch: {
			label: 'lunch',
			mealName: 'lunch',
			meal:{
				title: '',
			},
		},
		afternoonSnack: {
			label: 'afternoonSnack',
			mealName: 'afternoon snack',
			meal:{
				title: '',
			},
		},
		dinner: {
			label: 'dinner',
			mealName: 'dinner',
			meal:{
				title: '',
			},
		},
		eveningSnack: {
			label: 'eveningSnack',
			mealName: 'evening snack',
			meal:{
				title: '',
			},
		},
	},
	{
		day: 'Wednesday',
		breakfast: {
			label: 'breakfast',
			mealName: 'breakfast',
			meal:{
				title: '',
			},
		},
		morningSnack: {
			label: 'morningSnack',
			mealName: 'morning snack',
			meal:{
				title: '',
			},
		},
		lunch: {
			label: 'lunch',
			mealName: 'lunch',
			meal:{
				title: '',
			},
		},
		afternoonSnack: {
			label: 'afternoonSnack',
			mealName: 'afternoon snack',
			meal:{
				title: '',
			},
		},
		dinner: {
			label: 'dinner',
			mealName: 'dinner',
			meal:{
				title: '',
			},
		},
		eveningSnack: {
			label: 'eveningSnack',
			mealName: 'evening snack',
			meal:{
				title: '',
			},
		},
	},
	{
		day: 'Thursday',
		breakfast: {
			label: 'breakfast',
			mealName: 'breakfast',
			meal:{
				title: '',
			},
		},
		morningSnack: {
			label: 'morningSnack',
			mealName: 'morning snack',
			meal:{
				title: '',
			},
		},
		lunch: {
			label: 'lunch',
			mealName: 'lunch',
			meal:{
				title: '',
			},
		},
		afternoonSnack: {
			label: 'afternoonSnack',
			mealName: 'afternoon snack',
			meal:{
				title: '',
			},
		},
		dinner: {
			label: 'dinner',
			mealName: 'dinner',
			meal:{
				title: '',
			},
		},
		eveningSnack: {
			label: 'eveningSnack',
			mealName: 'evening snack',
			meal:{
				title: '',
			},
		},
	},
	{
		day: 'Friday',
		breakfast: {
			label: 'breakfast',
			mealName: 'breakfast',
			meal:{
				title: '',
			},
		},
		morningSnack: {
			label: 'morningSnack',
			mealName: 'morning snack',
			meal:{
				title: '',
			},
		},
		lunch: {
			label: 'lunch',
			mealName: 'lunch',
			meal:{
				title: '',
			},
		},
		afternoonSnack: {
			label: 'afternoonSnack',
			mealName: 'afternoon snack',
			meal:{
				title: '',
			},
		},
		dinner: {
			label: 'dinner',
			mealName: 'dinner',
			meal:{
				title: '',
			},
		},
		eveningSnack: {
			label: 'eveningSnack',
			mealName: 'evening snack',
			meal:{
				title: '',
			},
		},
	},
	{
		day: 'Saturday',
		breakfast: {
			label: 'breakfast',
			mealName: 'breakfast',
			meal:{
				title: '',
			},
		},
		morningSnack: {
			label: 'morningSnack',
			mealName: 'morning snack',
			meal:{
				title: '',
			},
		},
		lunch: {
			label: 'lunch',
			mealName: 'lunch',
			meal:{
				title: '',
			},
		},
		afternoonSnack: {
			label: 'afternoonSnack',
			mealName: 'afternoon snack',
			meal:{
				title: '',
			},
		},
		dinner: {
			label: 'dinner',
			mealName: 'dinner',
			meal:{
				title: '',
			},
		},
		eveningSnack: {
			label: 'eveningSnack',
			mealName: 'evening snack',
			meal:{
				title: '',
			},
		},
	},
	
]
