import React, { Component } from 'react';
export default class JournalEntryRow extends Component {
	render() {
		const date = new Date(this.props.timestamp)
		const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }) 
		const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date ) 
		return (
			<div className="mt-4 lg:mt-0 py-3 lg:py-1 px-4 lg:px-0 lg:mb-1 shadow-sm border border-gray-300 rounded-lg clearfix">
				<div className="flex flex-wrap lg:items-center w-full journalEntriesTableRow">
					<div className="text-left w-full md:w-6/12 lg:w-3/12 flex">
						<span className="inline-block mr-auto lg:hidden">Date</span> 
						<strong className="inline-block ml-auto md:ml-2">{day} {month} {year}</strong>
					</div>
					<div className="w-full md:w-6/12 lg:w-2/12 text-left flex">
						<span className="inline-block mr-auto lg:hidden">Calorie Intake</span>
						<span className="inline-block ml-auto md:ml-2">
							<strong>{this.props.dayCalories}</strong> <span className="mr-1 text-sm">kcal</span>
						</span>
					</div>
					<div className="lg:w-2/12 w-full md:w-6/12 text-left flex">
						<span className="inline-block mr-auto lg:hidden">Goal</span>
						<span className="inline-block ml-auto md:ml-2">
							<strong>{this.props.dayCaloriesGoal}</strong> <span className="mr-1 text-sm">kcal</span>
						</span>
					</div>
					<div className="lg:w-2/12 w-full md:w-6/12 text-left flex">
						<span className="inline-block mr-auto lg:hidden">Calories Burned</span>
						<span className="inline-block ml-auto md:ml-2"><strong>{this.props.totalBurn}</strong> <span className="mr-1 text-sm"> kcal</span></span>
					</div>
						<div className="lg:w-2/12 w-full md:w-6/12 text-left flex">
							<span className="inline-block mr-auto lg:hidden">Ketone Reading</span>
							<span className="ml-2 grKetone w-5 h-5 inline-block border rounded-lg mr-2" data-ketone={this.props.ketoneReading}></span> <span>{this.props.ketoneReading}</span>
					</div>
					<button className="lg:w-1/12 text-right cursor-pointer w-full hidden md:inline-block" onClick={()=>this.props.handleEntry(this.props.entry)}>
						<div className="desktopJournalEditButton lg:w-auto w-full"><span className="flaticon-pencil"></span></div>
					</button>
					<button className="cursor-pointer w-full inline-block bg-primary rounded-lg md:hidden py-4 my-6" onClick={()=>this.props.handleEntry(this.props.entry)}>
						<div className="text-white text-center lg:w-auto w-full">Update Journal</div>
					</button>
				</div>	
	 		</div>
		);
	}
}
