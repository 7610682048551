import React, {Component} from 'react'
import CircleChart from './CircleChart';
import {db} from '../../firebase/';
import {connect} from 'react-redux';
import {Link} from 'gatsby';
import {storeUser, storeUserId, storeRecipes, storeCalculator, addNotification} from "../../redux/actions/";
function mapDispatchToProps(dispatch) {
  return {
    storeUser: user => dispatch(storeUser(user)),
    storeRecipes: recipes => dispatch(storeRecipes(recipes)),
    storeUserId: id => dispatch(storeUserId(id)),
    storeCalculator: stats => dispatch(storeCalculator(stats)),
    addNotification: notification => dispatch(addNotification(notification))
  };
}

const mapStateToProps = state => {
  return {user: state.user, uid: state.uid, calculator: state.calculator};
};
const ConnectedOutputs = ({user, uid, calculator, storeUser, addNotification}) => (<Outputs
  user={user}
  uid={uid}
  calculator={calculator}
  storeUser={storeUser}
  addNotification={addNotification}/>);

class Outputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      saved: false
    }
  }
  refreshUserData() {
    db.getUserInfo(this.props.uid)
      .then((user) => {
        this
          .props
          .storeUser(user)
      })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      if (this.props.calculator) {
        if (this.props.calculator.weight && this.state.saved === false && this.props.uid.length > 0) {
          this.saveStatsToProfile();
        }
      }
    }
  }

  saveStatsToProfile() {
    this.setState({saved: true})
    let timestamp = +new Date();
    let date = new Date(timestamp)
    let chartDate = this.state.year + '-' + this.state.month + '-' + this.state.day;
    this.props.addNotification('Your progress and macros have been saved');

    db.saveUserStats(this.props.uid, this.props.calculator, this.state.day, this.state.month, this.state.year, timestamp, date, chartDate)
    .catch((err) => console.log(err));
    db.saveUserProgress(this.props.uid, this.props.calculator, this.state.day, this.state.month, this.state.year, timestamp, date, chartDate)
    .then(() => {
      this.refreshUserData();
    })
    .catch((err) => console.log(err))

  }
  render() {
    let carbsRatio = parseInt(this.props.calculator.carbsRatio);
    let fatRatio = parseInt(this.props.calculator.fatRatio);
    let proteinRatio = parseInt(this.props.calculator.proteinRatio);
    let isUser = this.props.uid.length > 0 && this.props.calculator.proteinRatio > 0;
    let {verified} = this.props.user
    return (
      <div className="max-w-md mx-auto my-8">
        <div className="flex">
          <div className="clearfix">
            <CircleChart
              percentage={carbsRatio}
              strokeColor="#f15a22"
              label="Carbs"
              grams={(this.props.calculator.carbs / 4)}/>
          </div>
          <div className="clearFix">
            <CircleChart
              percentage={fatRatio}
              strokeColor="#f15a22"
              label="Fat"
              grams={(this.props.calculator.fat / 9)}/>
          </div>
          <div className="clearFix">
            <CircleChart
              percentage={proteinRatio}
              strokeColor="#f15a22"
              label="Protein"
              grams={(this.props.calculator.protein / 4)}/>
          </div>
        </div>
        {isUser && 
          <div className="my-4">
            <button
              onClick={this
              .saveStatsToProfile
              .bind(this)}
              className="bg-secondary text-white py-2 rounded-lg w-full">
              Save stats to profile
            </button>
          </div>
        }
        {verified? null:
          <Link to="/free-trial" className="bg-primary py-2 w-full text-white rouned-lg font-bold inline-block text-center mt-10 rounded-lg">
            Start 14 Day FREE Trial
          </Link>
        }
      </div>
    )
  }
}

const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(ConnectedOutputs);

export default ReduxComponent;