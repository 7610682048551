import React, { Component } from 'react';
import { auth } from '../../../firebase';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
  showPassword: false,
  open: false,
};
class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = (event) => {
    const { passwordOne } = this.state;
    event.preventDefault();
    auth.doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
    
  }
  doPasswordFormVisible(){
    if(this.state.open === false){
      this.setState({open: true})
    }
    else {
      this.setState({open: false})
    }
  }
  render() {
    const {passwordOne,passwordTwo,error,open} = this.state;
    const passwordsDontMatch = passwordTwo.length > 0 && passwordOne !== passwordTwo;
    return (
      <div>
        <p>
          <strong>Password:</strong> &ensp;
          <button 
              className={"link " + this.state.showPassword}
              onClick={this.doPasswordFormVisible.bind(this)}
          >
              Reset Password
          </button>
        </p>
        {open&&
          <div className="popupModalWapper">
            <button className="fixed z-10 h-screen w-screen top-0 left-0 bg-black bg-opacity-50" onClick={this.doPasswordFormVisible.bind(this)}>
              <span className="hidden">close</span>
            </button>
            <div className="max-w-md mx-auto rounded-lg shadow-lg p-5 z-50 relative bg-white">
              <form onSubmit={this.onSubmit} className="passwordChange">
                  <h3 className="subTitleHeading text-center mb-3 text-secondary">Reset Your Password</h3>
                  <div className="text-center">
                    <label className="my-1 block text-secondary text-sm font-medium">Reset Password
                    <input value={passwordOne}
                      onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
                      type="password"
                      placeholder="New Password"
                      className="formControl mb-3"
                    />
                    </label>
                    <label className="my-1 block text-secondary text-sm font-medium">Confirm Password
                    <input value={passwordTwo}
                      onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
                      type="password"
                      placeholder="Confirm New Password"
                      className="formControl"
                    />
                    </label>
                  </div>
                  <div className="text-center mt-4">
                    <button type="submit" className="mb-2 bg-primary text-white rounded-lg py-2 w-full">Reset My Password</button>
                  </div>
                  {error &&
                    <div className="sansSerif text-center errorMsg my-2 py-1">  
                      <p>{error.message}</p> 
                    </div>
                  }
                  {passwordsDontMatch &&
                    <div className="sansSerif text-center text-red-700">  
                      <p>Passwords dont match</p>
                    </div>
                  }
              </form>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default PasswordChangeForm;