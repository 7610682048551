import React, { Component } from 'react';
import {storage, db} from '../../../firebase'
import ImageUploader from 'react-images-upload';
import '../../../Content/css/dashboard.css'
import {Link} from 'gatsby';
import GirlDashboardPic from '../../../Content/images/Dashboard/GirlBg.png';
import BoyDashboardPic from '../../../Content/images/Dashboard/BoyBg.png';
import UserVerification from './UserVerification';
import PasswordChange from './PasswordChange';
import MaleAvatar from '../../../Content/images/Dashboard/MaleAvatar.png';
import FemaleAvatar from '../../../Content/images/Dashboard/FemaleAvatar.png';
import CameraIcon from '../../../Content/images/Dashboard/cameraIcon.png';
import '../../../Content/css/profile.css';
import Challenges from './Challenges';

export default class ProfileDashboard extends Component {
	componentDidMount() {
		window.scrollTo(0,0)
	}
	
	render() {
		
		let {user} = this.props;
		let {country, verified} = this.props.user;
		let {large} = this.props;

		return (
			<div className="profileDashboardContainer w-full">
				{large &&
					<DashboardHero user={this.props.user}/>
					
				}
				<div className="profileDashboardHeader">
					<div className="pageGrid">
						<div className="profileAccountFlex">
							<ProfileAvatar user={this.props.user} uid={this.props.uid}/>
							<div className="profileWrapper">
								<h2 className="text-2xl mb-2 font-medium">Your Profile</h2>
								<p><strong>Name: </strong>{this.props.user.username}</p>
								<p><strong>Email: </strong>{this.props.user.email}</p>
								{country &&
									<p><strong>Country: </strong>{this.props.user.country.label}</p>
								}
								<PasswordChange/>
								
								{verified?
								<>
									<p><strong>Verified </strong><span className="programmeVIP flaticon-sticker"></span></p>
									<Challenges user={this.props.user} uid={this.props.uid}/>
								</>
								:
								<UserVerification user={this.props.user} uid={this.props.uid}/>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="pageGrid">
					<div className="profileDashboard">
						<div className="dashboardTileWrapper">
							<Link to="/profile/progress-tracker">
							<div className="dashboardTile grey">
								<div>
									<h4 className="subHeadingTitle caps"><i>Progress</i><br/>Tracker</h4>
								</div>
							</div>
							</Link>
						</div>
						<div className="dashboardTileWrapper">
							<Link to="/profile/daily-journal">
								<div className="dashboardTile white">
									{user&&
										<div><h4 className="subHeadingTitle caps"><i>Daily</i><br/>Journal</h4></div>
									}
								</div>
							</Link>
						</div>
						<div className="dashboardTileWrapper transparent">
							<div className="dashboardTile transparent">
								
							</div>
						</div>
						<div className="dashboardTileWrapper">
							<Link to="/profile/favorite-recipes">
								<div className="dashboardTile orange">
									{user&&
										<div><h4 className="subHeadingTitle caps"><i>Favorite</i><br/>Recipes</h4></div>
									}
								</div>
							</Link>
						</div>
						<div className="dashboardTileWrapper">
							<Link to="/profile/meal-plans">
								<div className="dashboardTile grey">
									{user&&
										<div><h4 className="subHeadingTitle caps"><i>Meal</i><br/>Plans</h4></div>
									}
								</div>
							</Link>
						</div>
						<div className="dashboardTileWrapper">
							<Link to="/profile/shopping-list">
							<div className="dashboardTile white">
								{user&&
									<div><h4 className="subHeadingTitle caps"><i>Shopping</i><br/>List</h4></div>
								}
							</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}




export class DashboardHero extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			img: GirlDashboardPic,
		}
	}
	handleHero(){
		if(this.props.user.stats.sex === 'Male'){
			this.setState({img: BoyDashboardPic})
		}
		else {
			this.setState({img: GirlDashboardPic})
		}
	}
	
	componentDidUpdate(nextProps){
		if(this.props !== nextProps && this.props.user.stats) {
			this.handleHero();
		}
	}
	componentDidMount(){
		if(this.props.user.stats){
			this.handleHero();
		}	
	}
	render() {
		return (
			<div className="dashboardPicWrapper"><img src={this.state.img} alt=""/></div>
		);
	}
}






//
// PROFILE AVATAR COMPONENT
//


export class ProfileAvatar extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			pictures: [],
			sex: '',
			imageFieldOpen: false, 
			uploadConfirmed: false,
			avatarImage: '',
		}
	}
	componentDidMount(){
		this.checkAvatar()
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props){
			this.checkAvatar()
		}
	}
	
	checkAvatar(){
		if(this.props.user){
			if(this.props.user.avatarImageUrl){
				this.setState({avatarImage: this.props.user.avatarImageUrl})
			}
			else{
				 this.checkSex();
			}
		}
	}
	checkSex(){
		if(this.props.user.stats){
			if(this.props.user.stats.sex){
				this.setState({sex: this.props.user.stats.sex})
			}
		}
	}
	storeUserAvatarImage(){
		storage.storeUserAvatar(this.props.uid, this.state.picture[0]).then((url)=>{
       		if(url){
       			this.setState({
	       				uploadConfirmed: true,
	       				avatarImage: url,
	       		})
       			db.storeuploadedAvatar(this.props.uid, url).then(()=>{
					this.setState({
		       				uploadConfirmed: false,
		       				avatarImage: url,
		       				preview: '',
		       				previewVisible: true,
		       		})
	       			this.handleOpenImageField();
       			})
       			
       		}
       	})
	}
	handleChangeProfileAvatar(picture, pictureDataURLs) {
        this.setState({
            picture: picture,
            url: pictureDataURLs,
        });
        this.setState({
        	previewVisible: true,
        	preview: pictureDataURLs,
        })
        
    }
    handleCancelAvatarChoice(){
    	this.setState({
    		preview: '',
    		previewVisible: false,
    	})
    }
    handleConfirmAvatarChoice(){
    	this.storeUserAvatarImage();
    }
    handleOpenImageField(){
    	if(this.state.imageFieldOpen !== true){
    		this.setState({
    			imageFieldOpen: true
    		})
    	}
    	else{
    		this.setState({
    			imageFieldOpen: false,
    		})
    	}
    }
	render(){
		const {preview, sex} = this.state,
		noSex = sex !== 'Male' && sex !== 'Female',
		female = sex === 'Female',
		male = sex === 'Male',
		imageFieldActive = this.state.imageFieldOpen === true,
		hasAvatarImage = this.state.avatarImage.length > 0,
		style = {
			backgroundImage: 'url(' + this.state.avatarImage + ')',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
		},
		previewStyle = {
			backgroundImage: 'url(' + this.state.preview + ')',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			display: 'block',
			margin: '0 auto',
		}
		return(
			<div>
				{imageFieldActive&&
					<div className="imageFieldModal">
						<button className="imageFieldFade" onClick={this.handleOpenImageField.bind(this)}>
							<span className="hidden">Close</span>
						</button>
						<div className="imageFieldModalContent">
							{preview?
							<div className="text-center">
								<h3 className="subHeadingTitle blueGreyText">Preview</h3>
								<div className="customAvatar" style={previewStyle}></div>
								<button onClick={this.handleConfirmAvatarChoice.bind(this)} className="button darkButton mt-5">Confirm</button>&emsp;&emsp;
								<button onClick={this.handleCancelAvatarChoice.bind(this)} className="button darkButton">Cancel</button>
							</div>
							:
							<ImageUploader withIcon={true}
								buttonText='Choose image'
								onChange={this.handleChangeProfileAvatar.bind(this)}
								imgExtension={['.jpg','.png']}
								maxFileSize={5242880}
								withPreview={false}
								singleImage="true"
							/>
							}
						</div>
					</div>
				}
				{hasAvatarImage ?
					<div className="avatarImageWrapper">
						<button className="cameraIconWrapper" onClick={this.handleOpenImageField.bind(this)}>
							<img src={CameraIcon} alt="Change Profile"/>
							<span className="hidden">Change Profile Image</span>
						</button>
						<div className="customAvatar" style={style}></div>
					</div>
				:
					<div className="avatarImageWrapper">
						<button className="cameraIconWrapper" onClick={this.handleOpenImageField.bind(this)}>
							<img src={CameraIcon} alt="Change Profile"/>
							<span className="hidden">Change Profile Image</span>
						</button>
						
						{male&&
							<img src={MaleAvatar} alt="Male Avatar"/>
						}

						{female&&
							<img src={FemaleAvatar} alt="Female Avatar"/>
						}
						{noSex&&
							<img src={FemaleAvatar} alt="Female Avatar"/>
						}
					</div>
				}
			</div>
		)
	}
}