import React, { Component } from 'react';
export default class BeverageDrinks extends Component {
	constructor(props) {
	  super(props);	
	  this.state = {
	  	totals:{
	  		fat: 0,
	  		carbs: 0,
	  		protein: 0,
	  		caffeine: 0,
	  	}
	  };
	}
	handleRemoveMeal(e){
		let button = e.target;
		let index = button.getAttribute('data-index');
		this.props.handleRemoveMeal(index)
	}
	static getDerivedStateFromProps(props, state) {
		//
		return null
    }
	handleViewMacros(e){
		
		let key = e.target.getAttribute('data-index')
		let drinkmacrotable = document.getElementById('drinkmacrotable' + key);
		let status = drinkmacrotable.getAttribute('data-status');
		if(status === 'false'){
			drinkmacrotable.setAttribute('data-status', 'true');
		}
		else {
			drinkmacrotable.setAttribute('data-status', 'false');
		}
	}
	render() {
		const {drinks} = this.props
		let tableRequired = this.props.drinks.length > 0
		let mobileItems = this.props.drinks.map((item, key)=>
			<div key={key} className="mt-4 mb-4">
				<div className="text-center mealNameContainer">
					<button className="mealName" data-index={key} onClick={this.handleViewMacros.bind(this)}>{item.name} 
						<span className="flaticon-down-arrow"></span>
					</button>
					<div className="snackRemovalContainer">
						<button 
							className="flaticon-minus removeSnackButton" 
							onClick={this.handleRemoveMeal.bind(this)} 
							data-index={key}
						>
							<span className="hidden">remove meal</span>
						</button>
					</div>	
				</div>
				<table className="table mobileMealMacrosTable " id={'drinkmacrotable' + key} data-status="false">
					<tbody>	
						<tr>
							<td className="text-left"><strong>Serving</strong><br/>{item.serving} g</td>
							<td className="text-left"><strong>Fat</strong><br/>{item.fat} g</td>
							<td className="text-left"><strong>Carbs</strong><br/>{item.carbs} g</td>
							<td className="text-left"><strong>Protein</strong><br/>{item.protein} g</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
		let items = this.props.drinks.map((item, key)=>
			<tr>
				<td className="text-left"><h6 className="mealName">{item.name}</h6></td>
				<td className="text-center">{item.fat}g</td>
				<td className="text-center">{item.carbs}g</td>
				<td className="text-center">{item.protein}g</td>
				<td className="text-center">{item.caffeine}mg</td>
				<td className="text-center">{item.serving}ml</td>
				<td>
					<div>
						<button className="flaticon-minus removeSnackButton" onClick={this.handleRemoveMeal.bind(this)} data-index={key}>
							<span className="hidden"></span>
						</button>
					</div>
				</td>
			</tr>
			
		)
		if(this.props.windowWidth > 767){
			return (

				<table className="table beverageTotalsTable text-center my-4">
					<tbody>
						{tableRequired&&
							<tr>
								<th className="text-left"><span className="hidden">name</span></th>
								<th className="text-left text-center"><h6>Fat</h6></th>
								<th className="text-left text-center"><h6>Carbs</h6></th>
								<th className="text-left text-center"><h6>Protein</h6></th>
								<th className="text-left text-center"><h6>Caffeine</h6></th>
								<th className="text-left text-center"><h6>Serving</h6></th>
								<th className="text-right"><span className="hidden">total</span></th>
							</tr>
						}
						{drinks&&
						<>{items}</>
						}
					</tbody>
				</table>
			);	
		}
		else {return (<div>{mobileItems}</div>);}
	}
}
