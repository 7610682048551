import React, { Component } from 'react';
import UserShoppingList from './UserShoppingList'
import {Link} from 'gatsby';
export default class UserShoppingListContainer extends Component {

	render() {
		const {shoppingList, handleRemoveItem, handleTickItem, resetList} = this.props;
		return (
			<div>
				{shoppingList?
					<UserShoppingList 
						shoppingList={shoppingList} 
						handleRemoveItem={handleRemoveItem} 
						handleTickItem={handleTickItem}
						resetList={resetList}
					/>
				:
					<div className="text-center" style={{position: 'relative', zIndex: 20}}><Link to="/recipes"><button className="button darkButton">RECIPES PAGE</button></Link></div>
				}
			</div>
		);
	}
}
