import React, { Component } from 'react';
import '../../../Content/css/profile.css';
import MealPlans from './MealPlans';
import { connect } from "react-redux";
import { storeUser} from "../../../redux/actions/";
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
	};
}
function mapStateToProps(state, ownProps) {
	return {
		user: state.user, 
		uid: state.uid, 
		recipes: ownProps.recipes,
		content: ownProps.content,
	};
}
const ProfileConnected = ({ user, uid, recipes, content, storeUser }) => (
  <MealPlansPage user={user} uid={uid} recipes={recipes} content={content} storeUser={storeUser}/>
);  
class MealPlansPage extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	loading: true,
	  	windowWidth: 0,
	  };
	}
	componentDidMount(){
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions.bind(this));
	}
	componentWillUnmount() {
    	window.removeEventListener('resize', this.updateWindowDimensions);
  	}
	updateWindowDimensions() {
	  	this.setState({windowWidth: window.innerWidth})

	}
	render() {
		return (
			<div className="profileWrapper">		
				<MealPlans uid={this.props.uid} 
					content={this.props.content} 
					recipes={this.props.recipes}
					user={this.props.user}	
					storeUser={this.props.storeUser}
				/>
			</div>
		);
	}
}
const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileConnected);

export default Profile;