import React, { Component } from 'react';
import '../../../Content/css/profile.css';
import UserProgressTracker from './UserProgressTracker';
import { connect } from "react-redux";
const mapStateToProps = state => {
	return { user: state.user, uid: state.uid};
  };
const ProgressTrackerConnected = ({ user, uid }) => (
  <ProgressTracker user={user} uid={uid}/>
);  
class ProgressTracker extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	data: {
	        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
	        datasets: [{
	            label: 'My First dataset',
	            backgroundColor: 'rgb(255, 99, 132)',
	            borderColor: 'rgb(255, 99, 132)',
	            data: [0, 10, 5, 2, 20, 30, 45]
	        }]
	    },
	  };
	}	
	componentDidMount() {
		window.scrollTo(0,0);
	}
	render() {
		return (
			<main>
				<div className="profileWrapper">
					<div className="profilePageContainer progressTrackerPage grey">
						<div className="pageGrid clearfix">
							<div className="progressProfileWrapper">
								<UserProgressTracker user={this.props.user} uid={this.props.uid}/>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}
const Progress = connect(mapStateToProps)(ProgressTrackerConnected);
export default Progress;