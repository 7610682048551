import React, { Component } from 'react';
export default class AddMealToPlanButton extends Component {
	handleAddMeal(){
		this.props.handleAddMeal(this.props.day, this.props.meal)
	}
	render() {
		let isMobile = this.props.isMobile === true;
		let isEdit = this.props.label !== 'Add';
		return (
			<div className="buttonWrapper">
				{isMobile?
					<button className="editButton"onClick={this.handleAddMeal.bind(this)}data-status={this.props.label}>
						{isEdit?
							<span className="flaticon-pencil"></span>:
							<span className="flaticon-add-button-inside-black-circle"></span>
						}
					</button>
				:
					<button className="editButton"onClick={this.handleAddMeal.bind(this)}data-status={this.props.label}>
						{this.props.label}
					</button>
				}
			</div>
		);
	}
}
