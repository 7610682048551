import {Event} from '../Analytics/';
export const calculateKetoMacros = (sex, age, height, weight, goal, activityLevel, surplusX, deficitX) => {
        
        let bmr = ((height*6.25) + (weight*9.99) - (age* 4.92) + 5),
        activityRatio = 1,
        restingEnergyExpendature = 0,
        suggestedCalories = 0,
        calories = 0,
        surplus = 0,
        deficit = 0,
        weightLossGoal = 0,
        weightGainGoal = 0,
        carbs = 0,
        protein = 0,
        fat = 0;

        if(sex === 'Male'){
            Event('Keto Calculator', 'Calculated Macros', 'Male');
            if(activityLevel === 'Lightly Active'){
                activityRatio = 1.11;
                bmr = ((height*6.25) + (weight*9.99) - (age* 4.92) - 161)
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            if(activityLevel === 'Moderately Active'){
                activityRatio = 1.25;
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            if(activityLevel === 'Very Active'){
                activityRatio = 1.85;
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            if(activityLevel === '' || activityLevel === 'Sedentary'){
                activityRatio = 1
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            restingEnergyExpendature = Math.floor(bmr*activityRatio)
            weightLossGoal = restingEnergyExpendature;
            weightGainGoal = restingEnergyExpendature;
            if(goal === 'Lose Weight'){
                deficit = (restingEnergyExpendature/5)
                calories = restingEnergyExpendature - deficit;
                carbs = ((calories/100)*5).toFixed(0);
                fat = ((calories/100)*75).toFixed(0)
                protein = ((calories/100)*20).toFixed(0)
            }
            if(goal === 'Maintain'){
                calories = restingEnergyExpendature;
                carbs = ((calories/100)*5).toFixed(0)
                fat = ((calories/100)*75).toFixed(0)
                protein = ((calories/100)*20).toFixed(0)
            }
            if(goal === 'Gain Muscle'){
                surplus = surplusX;
                suggestedCalories = (restingEnergyExpendature/3)*(surplus/100);
                calories = restingEnergyExpendature + suggestedCalories;
                carbs = ((calories/100)*5).toFixed(0);
                fat = ((calories/100)*65).toFixed(0)
                protein = ((calories/100)*30).toFixed(0)
            }
        }
        if(sex === 'Female'){
            Event('Keto Calculator', 'Calculated Macros', 'Female');
            if(activityLevel === 'Lightly Active'){
                activityRatio = 1.12;
                bmr = ((height*6.25) + (weight*9.99) - (age* 4.92) - 161)
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            if(activityLevel === 'Moderately Active'){
                activityRatio = 1.27;
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            if(activityLevel === 'Very Active'){
                activityRatio = 1.45;
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            if(activityLevel === '' || activityLevel === 'Sedentary'){
                activityRatio = 1
                restingEnergyExpendature = Math.floor(bmr*activityRatio);
            }
            if(goal === 'Lose Weight'){
                deficit = (restingEnergyExpendature/5)
                calories = restingEnergyExpendature - deficit;
                carbs = ((calories/100)*5).toFixed(0);
                fat = ((calories/100)*75).toFixed(0)
                protein = ((calories/100)*20).toFixed(0)
        
            }
            if(goal === 'Maintain'){
                calories = restingEnergyExpendature;
                carbs = ((calories/100)*5).toFixed(0)
                fat = ((calories/100)*75).toFixed(0)
                protein = ((calories/100)*20).toFixed(0)
            }
            if(goal === 'Gain Muscle'){
                surplus = surplusX;
                suggestedCalories = (restingEnergyExpendature/3)*(surplus/100);
                calories = restingEnergyExpendature + suggestedCalories;
                carbs = ((calories/100)*5).toFixed(0)
                fat = ((calories/100)*65).toFixed(0)
                protein = ((calories/100)*30).toFixed(0)
                
            }	
        }
        let stats = {
            bmr,
            activityRatio,
            restingEnergyExpendature,
            suggestedCalories,
            calories,
            surplus,
            deficit,
            weightLossGoal,
            weightGainGoal,
            carbs,
            protein,
            fat,
            carbsRatio:(parseInt(carbs)/calories)*100,
			fatRatio:(parseInt(fat)/calories)*100,
            proteinRatio:(parseInt(protein)/calories)*100,
            weightManagementGoal: goal,
        }
        return stats;
				
}
