import React, { Component } from 'react';
import { connect } from "react-redux";
import {db} from '../../../firebase';
import '../../../Content/css/profile.css';
import UserShoppingListContainer from './UserShoppingListContainer';
import '../../../Content/css/shoppingList.css';
import PageHeader from '../../PageComponents/PageHeader';
import shoppingListGirl from '../../../Content/images/ShoppingList/hero.jpg'
import {Link} from 'gatsby';
import { storeUser, storeUserId, storeRecipes } from "../../../redux/actions/";
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
	};
}
const mapStateToProps = state => {
	return { user: state.user, uid: state.uid};
  };

const ShoppingListConnected = ({ user, uid, storeUser }) => (
  <ShoppingList user={user} uid={uid} storeUser={storeUser}/>
);  
class ShoppingList extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	loading: true,
	  	user: {},
	  	uid: this.props.uid,
	  	shoppingList: [],
	  	mode: 'init',
	  	width: 0,
	  	height: 0,
	  	resetPrompt: false,
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight,  });
	}
	componentDidMount(){
		this.updateWindowDimensions();
		this.storeShoppingListToState();
	  	window.addEventListener('resize', this.updateWindowDimensions);
	}
	storeShoppingListToState(){
		if(this.props.user.Shopping_List && Array.isArray(this.props.user.Shopping_List)){
			this.setState({shoppingList: this.props.user.Shopping_List})
		}
	}
	componentDidUpdate(prevProps) {
		if(prevProps !== this.props){
			this.storeShoppingListToState();
		}
	}
	
	handleRemoveItem(item){
		  let list = this.state.shoppingList,
		  user = this.props.user;
		  list.splice(item,1);
		  this.setState({shoppingList: list, mode: 'edit'});
		  db.saveShoppingList(this.props.uid, this.state.shoppingList)
		  user.Shopping_List = list;
		  this.props.storeUser(user)
	}
	promptResetList(){
		this.setState({resetPrompt: true})
	}
	handleResetList(){
		let list = [],
		user = this.props.user;
		db.saveShoppingList(this.props.uid, list);
		this.setState({resetPrompt: false, shoppingList: []});
		user.Shopping_List = list;
		this.props.storeUser(user);
		
	}
	handleTickItem(item){
		let list = this.state.shoppingList,
		user = this.props.user;
		if(list[item].ticked === false){
			list[item].ticked = true;
		}
		else {
			list[item].ticked = false;
		}
		this.setState({shoppingList: list})
		user.Shopping_List = list;
		this.props.storeUser(user)
		db.saveShoppingList(this.props.uid, this.state.shoppingList);
	}
	
	render() {
		let shoppingList = this.state.shoppingList.length > 0;
		let resetPrompt = this.state.resetPrompt === true;
		return (
			<main>
				<div className="profileWrapper">
					<div className="profilePageContainer shoppingListContainer" style={{minHeight: this.state.height - 200}}>
						{shoppingList?
							null:
							<div className="shoppingListHeroWrapper">
								<img src={shoppingListGirl} alt="Shopping list" className="shoppingListHero" style={{height: this.state.height}}/>
							</div>
						}
						<div className="pageGrid clearfix">
							<div>
								{resetPrompt&&
								<div className="shoppingListModalScreen">
									<div className="saveListPrompt">
										<div className="savePromptContent">
											<h5 className="text-secondary text-2xl font-bold">Please Confirm</h5>
											<p>This will remove all items from the shopping list</p>
											<button className="bg-primary text-white py-2 px-4 rounded-lg" onClick={this.handleResetList.bind(this)}>Continue</button>
											&emsp;
											<button className="bg-secondary text-white py-2 px-4 rounded-lg" onClick={()=>{this.setState({resetPrompt: false})}}>Cancel</button>
										</div>
									</div>
								</div>
								}
								<div>
									<div>
										<section className="pt-0 pb-2">
											{shoppingList?
													<PageHeader mainHeading="Shopping List"/>
												:
												<>
													<PageHeader mainHeading="Shopping List" subHeading="Add items from any recipe"/>
													<div style={{position: 'relative', zIndex: 99, textAlign: 'center', marginBottom: '50px'}}>
														<Link to="/recipes" className="rounded-lg py-2 px-4 bg-secondary text-white mt-4 inline-block">All recipes</Link>
													</div>
												</>
											}
										</section>
										<UserShoppingListContainer 
											shoppingList={this.state.shoppingList}  
											handleRemoveItem={this.handleRemoveItem.bind(this)}
											handleTickItem={this.handleTickItem.bind(this)}
											resetList={this.promptResetList.bind(this)}
										/>
									</div>	
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</main>
		);
	}
}

const Shopping = connect(mapStateToProps, mapDispatchToProps)(ShoppingListConnected);
export default Shopping;