import React, { Component } from 'react';
import {Beverages} from './FoodValueTable';
import SelectSearch from 'react-select-search';
import MacroTable from './BeverageMacroTable'
import '../../../../Content/css/searchSelect.css';
import BeverageDrinks from './BeverageDrinks';
export default class BeveragesInput extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	drinks: Beverages.beverages,
	  	beverage: this.props.beverages.beverage,
	  	beverages: this.props.beverages.beverages,
	  	beverageTotals: this.props.beverages.beverageTotals,
	  	width: 0,
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	handleBeverageSearch(beverage){
		this.setState({beverage})
	}
	componentDidMount(){
		this.updateWindowDimensions()
		this.setState({
			beverage: this.props.beverages.beverage,
	  		beverages: this.props.beverages.beverages,
	  		beverageTotals: this.props.beverages.beverageTotals,
		})
	}
	static getDerivedStateFromProps(props, state) {
		//
		return null
    }
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	handleBeverage(beverage, serving){
		let allBeverages = this.props.beverages.beverages;
		let servedBeverageFat = (((beverage.fat/100)*serving)).toFixed(2)
		let servedBeverageProtein = (((beverage.protein/100)*serving)).toFixed(2)
		let servedBeverageCarbs = (((beverage.carbs/100)*serving)).toFixed(2)
		let servedBeverageCaffeine = (((beverage.caffeine/100)*serving)).toFixed(2)
		let addingBeverage = {
			fat: servedBeverageFat,
			protein: servedBeverageProtein,
			carbs: servedBeverageCarbs,
			caffeine: servedBeverageCaffeine,
			name: beverage.name,
			serving: serving,
		};
		if(beverage.caffeine > 0){
			this.props.handleCaffeinatedDrink(this.props.caffeinatedDrinks + 1);
		}
		allBeverages.push(addingBeverage);
		let servedPropFat = this.props.beverages.beverageTotals.fat + ((beverage.fat/100)*serving)
		let servedPropProtein = this.props.beverages.beverageTotals.protein + ((beverage.protein/100)*serving)
		let servedPropCarbs = this.props.beverages.beverageTotals.carbs + ((beverage.carbs/100)*serving)
		let servedPropCaffeine = this.props.beverages.beverageTotals.caffeine + ((beverage.caffeine/100)*serving)
		let beverages = {
			beverage: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		caffeine: 0,
		  		serving: 0,
		  	},
			beverages: allBeverages,
			beverageTotals: {
		  		fat: servedPropFat,
		  		protein: servedPropProtein,
		  		carbs: servedPropCarbs,
		  		caffeine: servedPropCaffeine
		  	}
		}
		this.props.handleBeverages(beverages)
	}
	handleRemoveBeverage(key){
		let allBeverages = this.state.beverages;
		if(allBeverages[key].caffeine > 0){
			this.props.handleCaffeinatedDrink(this.props.caffeinatedDrinks - 1);
		}
		let beverages = {
			beverage: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		caffeine: 0,
		  		serving: 0,
		  	},
			beverages: allBeverages,
			beverageTotals: {
		  		fat: this.props.beverages.beverageTotals.fat - allBeverages[key].fat,
		  		protein: this.props.beverages.beverageTotals.protein - allBeverages[key].protein,
		  		carbs: this.props.beverages.beverageTotals.carbs - allBeverages[key].carbs,
		  		caffeine: this.props.beverages.beverageTotals.caffeine - allBeverages[key].caffeine,
		  	}
		}
		allBeverages.splice(key, 1);
		this.setState({beverages: beverages});
		this.props.handleBeverages(beverages);
		

	}
	render() {
		let beverages = this.props.beverages.beverages.length > 0;
		let totalsRequired = this.props.beverages.beverages.length > 1;
		let beverageTyped = this.state.beverage.value.length > 0;
		let desktopSize = this.state.width > 767;
		if(desktopSize){
			return (
				<div>
					<h4>Beverages</h4>
					{beverages?
						<table className="table beverageTotalsTable text-center mb-4">
							<tbody>
								<BeverageDrinks  handleRemoveMeal={this.handleRemoveBeverage.bind(this)} drinks={this.state.beverages} windowWidth={this.state.width}/>
							</tbody>
						</table>
						:
						null
					}
					{totalsRequired &&
						<table className="table beverageTotalsTable text-center">
							<tbody>
								<tr>
									<td className="py-2 text-left"><h6>Totals</h6></td>
									<td className="py-2 text-center">{this.props.beverages.beverageTotals.fat.toFixed(2)}g</td>
									<td className="py-2 text-center">{this.props.beverages.beverageTotals.carbs.toFixed(2)}g</td>
									<td className="py-2 text-center">{this.props.beverages.beverageTotals.protein.toFixed(2)}g</td>
									<td className="py-2 text-center">{this.props.beverages.beverageTotals.caffeine.toFixed(2)}mg</td>
									<td className="py-2 invisibleText">000g</td>
									<td className="py-2 text-right">
										<div className="invisibleText">
											<span className="flaticon-minus removeSnackButton"></span>
										</div>
									</td>
								</tr>
							</tbody>
					</table>
					}
					<div className="my-4">
					<SelectSearch 
						options={this.state.drinks} 
						value={this.state.beverage.value}
						onChange={this.handleBeverageSearch.bind(this)}
						name="drinks"
						placeholder="Search beverages" 
					/>
					</div>
					{beverageTyped&&
					<MacroTable
						beverage={this.state.beverage}
						handleBeverage={this.handleBeverage.bind(this)}
						servingValueType="ml"
					/>
					}
					
				</div>
			);	
		}
		else {
			return(
				<div>
					<h4>Beverages</h4>
					<SelectSearch 
						options={this.state.drinks} 
						value={this.state.beverage.value}
						onChange={this.handleBeverageSearch.bind(this)}
						name="drinks"
						placeholder="Search beverages" 
					/>
					{beverageTyped &&
					<MacroTable
						beverage={this.state.beverage}
						handleBeverage={this.handleBeverage.bind(this)}
						servingValueType="ml"
					/>
					}
					{beverages &&
						<table className="table beverageTotalsTable text-center">
							<tbody>
								<BeverageDrinks 
									handleRemoveMeal={this.handleRemoveBeverage.bind(this)}
									drinks={this.state.beverages}
									windowWidth={this.state.width}
								/>
							</tbody>
						</table>
						
						

					}
					
					
					{totalsRequired &&
						<table className="table text-center totalsMobileMacroTable">
							<tbody>
								<tr>
									
									<td className="text-center"><strong>Fat</strong><br/>{this.props.beverages.beverageTotals.fat.toFixed(2)}g</td>
									<td className="text-center"><strong>Carbs</strong><br/>{this.props.beverages.beverageTotals.carbs.toFixed(2)}g</td>
									<td className="text-center"><strong>Protein</strong><br/>{this.props.beverages.beverageTotals.protein.toFixed(2)}g</td>
									<td className="text-center"><strong>Caffeine</strong><br/>{this.props.beverages.beverageTotals.caffeine.toFixed(2)}mg</td>
									
									
								</tr>
							</tbody>
					</table>
							
					
					}
					
				</div>
			);
		}
		
	}
}
