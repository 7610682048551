import React, { Component } from 'react';
import SleepHourInputs from './FormInputs/SleepHourInputs';
import SleepQualityInputs from './FormInputs/SleepQualityInputs';
import KetoneInputs from './FormInputs/KetoneInputs';
import WaterCountInputs from './FormInputs/WaterCountInputs';
import MainMealInput from './FormInputs/MealsInput';
import CaffeinatedDrinkInputs from './FormInputs/CaffeinatedDrinkInputs';
import SnackerInput from './FormInputs/SnackerInput';
import BeverageInput from './FormInputs/BeverageInputs';
import DonutChart from 'react-donut-chart';
import {db} from '../../../firebase/';
import ExerciseInput from './FormInputs/ExerciseInput';
import CalorieGoalChart from './CalorieGoalChart';
import JournalFormNav from './JournalFormNav';
import PageHeader from '../../PageComponents/PageHeader';
import {Event} from '../../Analytics/'
let weekday = require('weekday');
let timestamp = + new Date();
export default class ComponentName extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	journalSection: 'morning',
	  	timestamp: new Date( timestamp ),
	  	continuedEdit: false,
	  	day: this.props.day,
	  	month: this.props.month,
	  	year: this.props.year,
	  	weekDay: weekday(this.props.trueWeekDay),
	  	timeKeeping: this.props.timeKeeping,
	  	hoursSlept: '',
	  	qualityOfSleep: '',
	  	wakeupTime:[],
	  	ketoneReading: '',
	  	glassesOfWater: '',
	  	caffeinatedDrinks: 0,
	  	meals: {
			breakfast: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  	},
		  	lunch: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  	},
		  	dinner: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  	}
		},
	  	snacks:{
		  	snack: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  	},
		  	snackMeals: [],
		  	snackTotals: {
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  	}
	  	},
	  	beverages:{
		  	beverage: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  		caffeine: 0,
		  	},
		  	beverages: [],
		  	beverageTotals: {
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		caffeine: 0,
		  	}
	  	},
	  	dayWorkOut:[],
	  	caloriesBurned: 0,
	  	totalBurn: 0,
	  };
	}
	initiateSnacks(){
		let snacks = {
			snack: this.state.snacks.snack,
			snackMeals: [],
			snackTotals: this.state.snacks.snackTotals,
		};
		this.setState({snacks})
	}
	initiateBeverages(){
		let beverages = {
			beverage: this.state.beverages.beverage,
			beverages: [],
			beverageTotals: this.state.beverages.beverageTotals,
		};
		this.setState({beverages})
	}
	componentDidMount(){
		if(this.props.editEntry){
			let edit = this.props.editEntry;
			let currentState = this.state;
			//currentState.push(edit);			
			this.setState(this.props.editEntry)
		    const entry = {...currentState, ...edit};
		    this.setState(entry) 
		    this.resetJournalSection();
		    if(!edit.snacks.snackMeals){
				this.initiateSnacks();
		    }
		    if(!edit.beverages.beverages){
				this.initiateBeverages();
		    }
		}
		this.setState({
			day: this.props.day,
			month: this.props.month,
			year: this.props.year,
			weekDay: this.props.weekDay,
			timeKeeping: this.props.timeKeeping,
		})
			
	}
	resetJournalSection(){
		this.setState({journalSection: 'morning'})
	}
	handleRemoveWorkOut(dayWorkOut, calories){
		this.setState({dayWorkOut, totalBurn: this.state.totalBurn - calories})
	}
	handleHoursSlept(hours){
		this.setState({hoursSlept: hours})
	}
	handleSleepQuality(quality){
		this.setState({qualityOfSleep: quality})
	}
	handleKetones(ketones){
		this.setState({ketoneReading: ketones})
	}
	handleGlassesOfWater(glasses){
		this.setState({glassesOfWater: glasses})
	}
	handleCaffeinatedDrinkInput(selectedValue){
		this.setState({caffeinatedDrinks: selectedValue})
	}
	handleContinueEdit(){
		this.setState({continuedEdit: true,})
	}
	handleCaffeinatedDrink(count){
		this.setState({caffeinatedDrinks: count})	
		let inputs = document.getElementsByName('drinks');
		if(count > 0){
			for(let i = 0; i < inputs.length; i++){
				let drinksNumber = inputs[i].value;
				let inputDrinks = parseInt(drinksNumber)
				if(inputDrinks === count){
					inputs[i].setAttribute("checked", 'checked')
				}
				if(drinksNumber === count){
					inputs[i].setAttribute("checked", 'checked')
				}
				if(inputDrinks < count){
					inputs[i].setAttribute("data-below", true)
				}
				if(inputDrinks > count){
					inputs[i].setAttribute("data-below", false)
				}
			}
		}
		else {
			for(let i = 0; i < inputs.length; i++){
				inputs[i].removeAttribute("checked")
				inputs[i].setAttribute("data-below", false)
			}
		}
	}
	handleCaffeinatedDrinks(drinks){
		this.setState({caffeinatedDrinks: drinks})
	}
	handleWakeupHour(wakeupTimeHour){
		 this.setState({wakeupTimeHour})
	}
	handleWakeupMins(wakeupTimeMins){
		this.setState({wakeupTimeMins})
	}
	handleWakeupSupplement(wakeupSupplement){
		this.setState({wakeupSupplement})
	}
	handleMidMorningHour(midMorningHour){
		 this.setState({midMorningHour})
	}
	handleMidMorningMins(midMorningMins){
		this.setState({midMorningMins})
	}
	handleMidMorningSupplement(midMorningSupplement){
		this.setState({midMorningSupplement})
	}
	handleAfternoonHour(afternoonHour){
		 this.setState({afternoonHour})
	}
	handleAfternoonMins(afternoonMins){
		this.setState({afternoonMins})
	}
	handleAfternoonSupplement(afternoonSupplement){
		this.setState({afternoonSupplement})
	}
	handleEveningHour(eveningHour){
		 this.setState({eveningHour})
	}
	handleEveningMins(eveningMins){
		this.setState({eveningMins})
	}
	handleEveningSupplement(eveningSupplement){
		this.setState({eveningSupplement})
	}
	handleSaveJournalEntry(){
		//db.saveJournalEntry(this.props.uid, this.props.date, this.state)
	}
	handleBreakfastHour(breakfastHour){
		this.setState({breakfastHour})
	}
	handleBreakfastMins(breakfastMins){
		this.setState({breakfastMins})
	}
	handleBreakfastMeal(breakfastMeal){
		this.setState({breakfastMeal})
	}
	handleLunchHour(lunchHour){
		this.setState({lunchHour})
	}
	handleLunchMins(lunchMins){
		this.setState({lunchMins})
	}
	handleLunchMeal(lunchMeal){
		this.setState({lunchMeal})
	}
	handleDinnerHour(dinnerHour){
		this.setState({dinnerHour})
	}
	handleDinnerMins(dinnerMins){
		this.setState({dinnerMins})
	}
	handleDinnerMeal(dinnerMeal){
		this.setState({dinnerMeal})
	}
	handleSnack1Hour(snack1Hour){
		this.setState({snack1Hour})
	}
	handleSnack1Mins(snack1Mins){
		this.setState({snack1Mins})
	}
	handleSnack1Meal(snack1Meal){
		this.setState({snack1Meal})
	}
	handleSnack2Hour(snack2Hour){
		this.setState({snack2Hour})
	}
	handleSnack2Mins(snack2Mins){
		this.setState({snack2Mins})
	}
	handleSnack2Meal(snack2Meal){
		this.setState({snack2Meal})
	}
	handleSnack3Hour(snack3Hour){
		this.setState({snack3Hour})
	}
	handleSnack3Mins(snack3Mins){
		this.setState({snack3Mins})
	}
	handleSnack3Meal(snack3Meal){
		this.setState({snack3Meal})
	}
	handleExercise(e){
		this.setState({exercise: e.target.name})
		
	}
	handleGoBack(){
		this.props.closeOnSave()
		
	}
	handleSaveJournal(){
		let fat = 0 + parseInt(this.state.meals.breakfast.fat) +
			parseInt(this.state.meals.lunch.fat) +
			parseInt(this.state.beverages.beverageTotals.fat) +
			parseInt(this.state.meals.dinner.fat) +
			parseInt(this.state.snacks.snackTotals.fat);
  		let carbs = 0 + parseInt(this.state.meals.breakfast.carbs) +
  			parseInt(this.state.meals.lunch.carbs) +
  			parseInt(this.state.beverages.beverageTotals.carbs) +
  			parseInt(this.state.meals.dinner.carbs) +
  			parseInt(this.state.snacks.snackTotals.carbs);
  		let protein = 0 + this.state.meals.breakfast.protein +
  			parseInt(this.state.meals.lunch.protein) +
  			parseInt(this.state.beverages.beverageTotals.protein) +
  			parseInt(this.state.meals.dinner.protein) +
  			parseInt(this.state.snacks.snackTotals.protein);
  		let fatCalories = fat * 9;
  		let carbCalories = carbs * 4;
  		let proteinCalories = protein * 4;
  		let intakeCalories =  fatCalories + carbCalories + proteinCalories;
  		let dayCalories = intakeCalories - this.state.totalBurn;
  		let dailyCarbsGoal = parseInt(this.props.user.stats.dailyCarbGrams);
  		let dailyProteinGoal = parseInt(this.props.user.stats.dailyProteinGrams);
  		let dailyFatGoal = parseInt(this.props.user.stats.dailyFatGrams);
  		let dailyGoal = dailyCarbsGoal + dailyProteinGoal + dailyFatGoal;
  		let dailyCarbsCaloriesGoal = parseInt(this.props.user.stats.dailyCarbCalories);
  		let dailyProteinCaloriesGoal = parseInt(this.props.user.stats.dailyProteinCalories);
  		let dailyFatCaloriesGoal = parseInt(this.props.user.stats.dailyFatCalories);
  		let dailyCalorieGoal = parseFloat(dailyCarbsCaloriesGoal) + parseFloat(dailyProteinCaloriesGoal) + parseFloat(dailyFatCaloriesGoal);
  		let totals = {
				fatCalories: fatCalories,
				carbCalories: carbCalories,
				proteinCalories: proteinCalories,
				intakeCalories: intakeCalories,
				dayCalories: dayCalories,
				dailyCarbsGoal: dailyCarbsGoal,
				dailyProteinGoal: dailyProteinGoal,
				dailyFatGoal: dailyFatGoal,
				dailyGoal: dailyGoal,
				dailyCarbsCaloriesGoal: dailyCarbsCaloriesGoal,
				dailyProteinCaloriesGoal: dailyProteinCaloriesGoal,
				dailyFatCaloriesGoal: dailyFatCaloriesGoal,
				dailyCalorieGoal: dailyCalorieGoal,
  		}
  		this.setState({totals:totals})
  		setTimeout(()=>{
			Event('Journal', 'Saved Journal', '/profile/journal')
  			db.handleSaveJournal(
				this.props.uid,
				this.state,
				this.state.day,
				this.state.month,
				this.state.year,
			).then(()=>{
				this.props.reloadUserData('Your Journal has been saved');
				this.props.closeOnSave()
			})
			.then(()=>{
				let timestamp = +new Date();
				let InitialState = {
				  	journalSection: 'morning',
				  	continuedEdit: false,
				  	timestamp: new Date( timestamp ),
				  	day: this.props.day,
				  	month: this.props.month,
				  	year: this.props.year,
				  	weekDay: weekday(this.props.trueWeekDay),
				  	timeKeeping: this.props.timeKeeping,
				  	hoursSlept: '',
				  	qualityOfSleep: '',
				  	wakeupTime:[],
				  	ketoneReading: '',
				  	glassesOfWater: '',
				  	caffeinatedDrinks: 0,
				  	meals: {
						breakfast: {
					  		value: '',
					  		fat: 0,
					  		protein: 0,
					  		carbs: 0,
					  		serving: 0,
					  	},
					  	lunch: {
					  		value: '',
					  		fat: 0,
					  		protein: 0,
					  		carbs: 0,
					  		serving: 0,
					  	},
					  	dinner: {
					  		value: '',
					  		fat: 0,
					  		protein: 0,
					  		carbs: 0,
					  		serving: 0,
					  	}
					},
				  	snacks:{
					  	snack: {
					  		value: '',
					  		fat: 0,
					  		protein: 0,
					  		carbs: 0,
					  		serving: 0,
					  	},
					  	snackMeals: [],
					  	snackTotals: {
					  		fat: 0,
					  		protein: 0,
					  		carbs: 0,
					  	}
				  	},
				  	beverages:{
					  	beverage: {
					  		value: '',
					  		fat: 0,
					  		protein: 0,
					  		carbs: 0,
					  		serving: 0,
					  		caffeine: 0,
					  	},
					  	beverages: [],
					  	beverageTotals: {
					  		fat: 0,
					  		protein: 0,
					  		carbs: 0,
					  		caffeine: 0,
					  	}
				  	},
				  	dayWorkOut:[],
				  	caloriesBurned: 0,
				  	totalBurn: 0,
				  }
				this.setState(InitialState)
			})
  		},300)
		
	}
	handleMealsInput(meals){
		this.setState({meals})
	}
	handleSnacks(snacks){
		this.setState({snacks})
	}
	handleBeverages(beverages){
		this.setState({beverages})
	}
	handleDayWorkOut(dayWorkOut){
		this.setState({dayWorkOut})
	}
	handleCaloriesBurned(caloriesBurned){
		this.setState({
			caloriesBurned,
			totalBurn: this.state.totalBurn + caloriesBurned,
		})
	}
	handleJournalSection(journalSection){
		this.setState({
			journalSection,
		})
	}
	handleTotals(dailyCalorieGoal, dayCalories, dailyGoal, dayTotalGrams){
		this.setState({
			dailyCalorieGoal, dayCalories, dailyGoal, dayTotalGrams
		})
	}
	render() {
		let fat = parseInt(this.state.meals.breakfast.fat) +
			parseInt(this.state.meals.lunch.fat) +
			parseInt(this.state.beverages.beverageTotals.fat) +
			parseInt(this.state.meals.dinner.fat) +
			parseInt(this.state.snacks.snackTotals.fat);
  		let carbs = 0 + parseInt(this.state.meals.breakfast.carbs) +
  			parseInt(this.state.meals.lunch.carbs) +
  			parseInt(this.state.beverages.beverageTotals.carbs) +
  			parseInt(this.state.meals.dinner.carbs) +
  			parseInt(this.state.snacks.snackTotals.carbs);
  		let protein = 0 + parseInt(this.state.meals.breakfast.protein) +
  			parseInt(this.state.meals.lunch.protein) +
  			parseInt(this.state.beverages.beverageTotals.protein) +
  			parseInt(this.state.meals.dinner.protein) +
  			parseInt(this.state.snacks.snackTotals.protein);
  		let isSmallDevice = this.props.windowWidth < 500;
  		let dayTotalGrams = fat + carbs + protein;
  		let fatRatio = 0 + ((fat/dayTotalGrams) * 100);
  		let carbsRatio = 0 + ((carbs/dayTotalGrams) * 100);
  		let proteinRatio = 0 + ((protein/dayTotalGrams) * 100);
  		let fatCalories = fat * 9;
  		let carbCalories = carbs * 4;
  		let proteinCalories = protein * 4;
  		let intakeCalories =  fatCalories + carbCalories + proteinCalories;
  		let dayCalories = intakeCalories - this.state.totalBurn;
  		let emptyFat = fat <= 0;
  		let emptyCarbs = carbs <= 0;
  		let emptyProtein = protein <= 0;
  		let dailyCarbsGoal = this.props.user.stats.dailyCarbGrams;
  		let dailyProteinGoal = this.props.user.stats.dailyProteinGrams;
  		let dailyFatGoal = this.props.user.stats.dailyFatGrams;
  		let dailyCarbsCaloriesGoal = this.props.user.stats.dailyCarbCalories;
  		let dailyProteinCaloriesGoal = this.props.user.stats.dailyProteinCalories;
  		let dailyFatCaloriesGoal = this.props.user.stats.dailyFatCalories;
  		let dailyCalorieGoal = parseInt(dailyCarbsCaloriesGoal) + parseInt(dailyProteinCaloriesGoal) + parseInt(dailyFatCaloriesGoal);
  		let remainingCalories = dailyCalorieGoal -  dayCalories;
  		let morningTab = this.state.journalSection === 'morning';
  		let snacksTab = this.state.journalSection === 'snacks';
  		let drinksTab = this.state.journalSection === 'drinks';
  		let exerciseTab = this.state.journalSection === 'exercise';
  		let mealsTab = this.state.journalSection === 'meals';
  		let continuedEdit = this.state.continuedEdit === true;
  		let journalEntryDate = weekday(this.state.weekDay) + '&emsp;' + this.state.day + ' / ' + this.state.month + ' / ' + this.state.year

		return (

			<div className="dailyJournalWrapper">
				<PageHeader mainHeading="Daily Journal" subHeading={journalEntryDate}/>
				<div className="text-center">
					&emsp;<br/>
					<button onClick={this.handleSaveJournal.bind(this)} className="py-2 px-6 bg-primary text-white rounded-lg">
						Save Journal
					</button>
					&emsp;
					<button onClick={this.handleGoBack.bind(this)} className="py-2 px-6 bg-secondary text-white rounded-lg">Go Back</button>
				</div>
				<div id="journalNotification" className="journalNotification">
					<div className="journalNotificationConent">
						<p id="notificationMessage"></p>
					</div>
				</div>
				<section className="journalFormWrapper">
					
					<JournalFormNav
						section={this.state.journalSection}
						handleJournalSection={this.handleJournalSection.bind(this)}
						handleContinueEdit={this.handleContinueEdit.bind(this)}
						continuedEdit={continuedEdit}
					/>
					
					<div>
						{morningTab &&
							<div className="flex flex-wrap">
								<div className="w-full md:w-6/12">
									<div className="formSectionWrapper">
										<h4>SLEEP & RECUPERATION</h4>
										<div>
											<SleepHourInputs handleHours={this.handleHoursSlept.bind(this)} hours={this.state.hoursSlept}/>
										</div>
										<div>
											<SleepQualityInputs quality={this.state.qualityOfSleep} handleQuality={this.handleSleepQuality.bind(this)}/>
										</div>
									</div>
								</div>
								<div className="md:w-6/12 w-full">
									<div className="formSectionWrapper">
										<h4>MORNING KETONE READING</h4>
										<KetoneInputs handleKetones={this.handleKetones.bind(this)}
											ketones={this.state.ketoneReading}
											recipesMacros={this.props.recipesMacros}
										/>
									</div>
								</div>
							</div>
						}
						<div className="formSectionWrapper">
							<div className="clearfix">
								<div className="row">
									<div className="w-full">
									{mealsTab&&	
										<MainMealInput meals={this.state.meals}
											handleMeal={this.handleMealsInput.bind(this)}
											userRecipes={this.props.user.Custom_Recipes}
											trueWeekDay={this.props.trueWeekDay}
											mealPlans={this.props.mealPlans}
											recipesMacroTable={this.props.recipesMacroTable}
											uid={this.props.uid}
										/>
									}
									{drinksTab&&
										<div>
										<BeverageInput caffeinatedDrinks={this.state.caffeinatedDrinks}
											handleBeverages={this.handleBeverages.bind(this)}
											beverages={this.state.beverages}
											handleCaffeinatedDrink={this.handleCaffeinatedDrink.bind(this)}
										/>
										<div className="row">
											<div className="col-sm-5">
												<div className="formSectionWrapper">
													<h5>Water (250ml glass)</h5>
													<WaterCountInputs handleGlasses={this.handleGlassesOfWater.bind(this)} glasses={this.state.glassesOfWater}/>
												</div>
											</div>
											<div className="col-sm-1"></div>
											<div className="col-sm-6">
												<div className="formSectionWrapper">
													<h5>Caffeinated drinks</h5>
													<CaffeinatedDrinkInputs handleDrinks={this.handleCaffeinatedDrinks.bind(this)}
														drinks={this.state.caffeinatedDrinks}
														allBeverages={this.state.allBeverages}
													/>
												</div>
											</div>
										</div>
										</div>
									}
									{snacksTab&&
										<SnackerInput handleSnacks={this.handleSnacks.bind(this)}snacks={this.state.snacks}/>
									}
									{exerciseTab&&
										<ExerciseInput handleRemoveWorkOut={this.handleRemoveWorkOut.bind(this)}
											weight={this.props.user.stats.weight}
											handleCaloriesBurned={this.handleCaloriesBurned.bind(this)}
											handleDayWorkOut={this.handleDayWorkOut.bind(this)}
											dayWorkOut={this.state.dayWorkOut}
										/>
									}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="formSectionWrapper">
						<div className="dayTotalMacroWrapper">
							<h4 className="text-center">Days Macros</h4>
							{isSmallDevice ?
								<table className="table dayTotalTable">
									<tbody>
										<tr>
											<td className="p-2"><h6>Intake Calories:</h6><span>{intakeCalories.toFixed(0)}</span></td>
											<td className="p-2"><h6>Calories Burned:</h6><span>{this.state.totalBurn.toFixed(0)}</span></td>
										</tr>
										<tr>
											<td className="p-2"><h6>Goal: </h6><span>{dailyCalorieGoal.toFixed(0)}</span></td>
											<td className="p-2"><h6>Remaining:</h6><span>{remainingCalories.toFixed(0)}</span></td>
										</tr>
									</tbody>
								</table> 
								:
								<table className="table dayTotalTable">
									<tbody>
										<tr>
											<td className="p-2"><h6>Intake Calories:</h6><span>{intakeCalories.toFixed(0)}</span></td>
											<td className="p-2"><h6>Calories Burned:</h6><span>{this.state.totalBurn.toFixed(0)}</span></td>
											<td className="p-2"><h6>Goal: </h6><span>{dailyCalorieGoal.toFixed(0)}</span></td>
											<td className="p-2"><h6>Remaining:</h6><span>{remainingCalories.toFixed(0)}</span></td>
										</tr>
									</tbody>
								</table>
							}
							<div className="caloriesCounter">
								<table className="table text-center goalCounterTable">
									{isSmallDevice?
										<tbody>
											<tr>
												<th className="p-2">Fat</th>
												<th className="p-2">Carbs</th>
												<th className="p-2">Protein</th>
											</tr>
											<tr>
												<td className="p-2">{fat.toFixed(0)}/{dailyFatGoal.toFixed(0)}<span>g</span></td>
												<td className="p-2">{carbs.toFixed(0)}/{dailyCarbsGoal.toFixed(0)}<span>g</span></td>
												<td className="p-2">{protein.toFixed(0)}/{dailyProteinGoal.toFixed(0)}<span>g</span></td>
											</tr>
										</tbody>
										:
										<tbody>
											<tr>
												<th className="p-2"><span className="hidden">current</span></th>
												<th className="p-2">Fat</th>
												<th className="p-2">Carbs</th>
												<th className="p-2">Protein</th>
											</tr>
											<tr>
												<th className="text-left">Current</th>
												<td>{fat.toFixed(0)}<span>g</span></td>
												<td>{carbs.toFixed(0)}<span>g</span></td>
												<td>{protein.toFixed(0)}<span>g</span></td>
											</tr>
											<tr>
												<th className="text-left p-2">Goal</th>
												<td className="p-2">
													{dailyFatGoal.toFixed(0)}<span>g</span>
													<CalorieGoalChart total={fat} goal={dailyFatGoal.toFixed(0)}/>
												</td>									
												<td className="p-2">
													{dailyCarbsGoal.toFixed(0)}<span>g</span>
													<CalorieGoalChart total={carbs} goal={dailyCarbsGoal.toFixed(0)}/>
												</td>
												<td className="p-2">
													{dailyProteinGoal.toFixed(0)}<span>g</span>
													<CalorieGoalChart total={protein} goal={dailyProteinGoal.toFixed(0)}/>
												</td>
											</tr>
										</tbody>
									}
								</table>
							</div>
							
						</div>

						<div className="pieGraphWrapper">
							<div className="pieGraphContainer">
								<DonutChart width={300}
									height={300}
									legend={false}
									strokeColor="#FFF"
									innerRadius={0.5}
									emptyOffset={0}
									outerRadius={0.9}
									onMouseEnter={(item) => item}
									clickToggle={false}
									colorFunction={(colors, index) => colors[(index % colors.length)]}
									onClick={(item, toggled) => (toggled ? null : null)}
									formatValues={(values, total) => `${(values / total * 100).toFixed(2)}%`}
									colors={[
										'#b5b5b5',
										'#f15b29',
										'#f99c70'
									]}
									emptyColor="#fafafa"
								    data={[{
								        label: 'Fats',
								        value: fatRatio,
								        isEmpty: emptyFat,
								    },
								    {
								        label: 'Protein',
								        value: proteinRatio,
								        isEmpty: emptyProtein,
								    },
								    {
								        label: 'Carbs',
								        value: carbsRatio,
								        isEmpty: emptyCarbs,
								    }]} 
								/>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
