import React, { Component } from 'react';
export default class SnackMeals extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	totals:{
	  		fat: 0,
	  		carbs: 0,
	  		protein: 0,
	  	},
	  	width: 0,
	  	height: 0,
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	componentDidMount() {
	  this.updateWindowDimensions();
	  window.addEventListener('resize', this.updateWindowDimensions);
	}
	handleViewMacros(e){
		
		let key = e.target.getAttribute('data-index')
		let snackmacrotable = document.getElementById('snackmacrotable' + key);
		let status = snackmacrotable.getAttribute('data-status');
		if(status === 'false'){
			snackmacrotable.setAttribute('data-status', 'true');
		}
		else {
			snackmacrotable.setAttribute('data-status', 'false');
		}
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	handleRemoveMeal(e){
		let button = e.target;
		let index = button.getAttribute('data-index');
		this.props.handleRemoveMeal(index)
	}
	render() {
		const tableRequired = this.props.meals.length > 0;
		let desktopItems = this.props.meals.map((item, key)=>
			<tr>
				<td className="text-left py-2"><h6 className="mealName">{item.name}</h6></td>
				<td className="py-2">{item.fat} g</td>
				<td className="py-2">{item.carbs} g</td>
				<td className="py-2">{item.protein} g</td>
				{
				item.product?
					<td className="py-2">{item.serving} Serve</td>
				:
					<td className="py-2">{item.serving} g</td>
				}
				<td className="py-2">
					<button className="flaticon-minus removeSnackButton" 
						onClick={this.handleRemoveMeal.bind(this)} 
						data-index={key}
					><span className="hidden">remove meal</span></button>
				</td>
			</tr>
		);
		let mobileItems = this.props.meals.map((item, key)=>
			<div key={key} >
				<div className="text-center mealNameContainer mb-3 mt-3">
					<button className="mealName" onClick={this.handleViewMacros.bind(this)} data-index={key}>
					{item.name}&ensp;
					<span className="flaticon-down-arrow"></span>
					</button>
					<div className="snackInfoContainer">
						
					</div>
					<div className="snackRemovalContainer">
						<button 
							className="flaticon-minus removeSnackButton" 
							onClick={this.handleRemoveMeal.bind(this)} 
							data-index={key}
						>
							<span className="hidden">
								Remove meal
							</span>
						</button>
					</div>
				</div>

				<table className="table mobileMealMacrosTable" id={'snackmacrotable' + key} data-status="false">
					<tbody>	
						<tr>
							<td><strong>Serving</strong><br/>{item.serving} g</td>
							<td><strong>Fat</strong><br/>{item.fat} g</td>
							<td><strong>Carbs</strong><br/>{item.carbs} g</td>
							<td><strong>Protein</strong><br/>{item.protein} g</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
		if(this.state.width > 767){
			return (
				<table className="table mealMacrosTable text-center">
					<tbody>
						{tableRequired?
							<tr>
								<th className="text-left py-2"><h4>Snacks</h4></th>
								<th className="py-2">Fat</th>
								<th className="py-2">Carbs</th>
								<th className="py-2">Protein</th>
								<th className="py-2">Serving</th>
								<th className="py-2"><span className="hidden">total</span></th>
							</tr>
							:null}
						{desktopItems}
					</tbody>
				</table>
			);	
		}
		else {
			return (
				<div className="snackMealsContainer">
					{mobileItems}
				</div>
					
			);	
		}
		
	}
}
