import React, { Component } from 'react';

export default class CurrentDayFinder extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	date: [new Date().getDate(),new Date().getMonth() + 1,new Date().getFullYear(),]
	  };
	}
	componentDidMount(){
		const {date} = this.state; 
		if(date[0] === parseInt(this.props.day) &&
			date[1] === parseInt(this.props.month) &&
			date[2] === parseInt(this.props.year)){
			let todayButton = document.getElementById('journalCreateTodayButton');
			todayButton.style.display = 'none';
		}
	}
	render() {
		return (
			<div></div>
		);
	}
}
