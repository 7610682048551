import React, { Component } from 'react';
import MealPlanSelectorButton from './MealPlanSelectorButton';
import PageHeader from '../PageComponents/PageHeader';
import MealPlansIntroduction from './MealPlansIntroduction';
export default class MealPlanSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			plans: [],
		}
	}
	
	handleAddMealPlan(){
		if(this.props.count > 0){
			this.props.addNewMealPlan(this.props.count + 1)
		}
		else{
			this.props.addNewMealPlan(1)
		}
	}
	componentDidMount() {
		this.loadMealPlans();
	}
	loadMealPlans(){
		if(typeof this.props.plans === 'object'){
			this.setState({
				plans: this.props.plans,
			})
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props){
			this.loadMealPlans();
		}
	}
	
	render() {
		let count = Object.keys(this.props.plans).length
		let items = Object.keys(this.props.plans).map((item) =>
			<div key={item} className="sm:w-6/12 md:w-4/12 mealPlanSelectorWrapper text-center">
				<div className="mealPlanSelector mx-2 shadow-lg bg-white rounded-lg">
					<MealPlanSelectorButton week={item}
						editWeek={this.props.editWeek}
						plan={this.props.plans[item]}
						recipeImages={this.props.recipeImages}
					/>
				</div>
			</div>
		);
		const hasMealPlans = count !== 0;
		return (
			<main>
				{hasMealPlans?
				<section>
					<PageHeader mainHeading="Meal Plans" subHeading="Your Saved Meal Plans"/>
					<section className="pageGrid mt-5">
						<div className="sm:flex flex-wrap">
							{items}
							<div className="sm:w-6/12 md:w-4/12 mealPlanSelectorWrapper text-center">
								<div className="mealPlanSelector mx-2 shadow-lg bg-primary text-white rounded-lg">
									<div>
										<div>
											<h5 style={{width: '100%'}} className="text-white text-2xl mb-4">Make a new plan</h5>
											<button className="bg-white rounded-lg text-gray-700 py-2 px-4" onClick={this.handleAddMealPlan.bind(this)}>
												Create new plan
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
				:
				<MealPlansIntroduction style={this.props.style} handleAddMealPlan={this.handleAddMealPlan.bind(this)} user={this.props.user}/>
				}
			</main>
			
		);
	}
	
}
