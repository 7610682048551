import React, {Fragment} from 'react';
import {db} from '../../../../firebase/';
import {Ingredients} from './IngredientsValueTable';
import SelectSearch from 'react-select-search';
import '../../../../Content/css/searchSelect.css';
import '../../../../Content/css/recipeCreator.css';
import PageHeader from '../../../PageComponents/PageHeader';
export default class UserRecipeCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipes: this.props.recipes,
      recipeStructure: {
        name: '',
        ingredients: [],
        fat: 0,
        carbs: 0,
        protein: 0,
        label: '',
        value: ''
      },
      ingredientsDb: [],
      ingredientsSearch: '',
      nameNotification: '',
      ingredientsNotification: '',
      mode: 'init',
      savePrompt: false,
      edited: false
    }
  }
  componentDidMount() {
    this.setState({ingredientsDb: Ingredients.ingredients})
  }
  handleCreateNewRecipe() {
    let html = document.documentElement;
    html
      .classList
      .add('noScroll')
    this.setState({mode: 'create'})
  }
  handleSaveCustomRecipe() {
    let html = document.documentElement;
    let verifiedName = false;
    let verifiedIngredients = false;
    html
      .classList
      .remove('noScroll')
    if (this.state.savePrompt === true) {
      this.setState({savePrompt: false})
    }
    if (this.state.recipeStructure.name.length === 0) {
      this.setState({nameNotification: 'Please enter a name for your recipe before saving'})
      verifiedName = false
    } else {
      this.setState({nameNotification: ''})
      verifiedName = true
    }
    if (this.state.recipeStructure.ingredients.length === 0) {
      this.setState({ingredientsNotification: 'Please add ingredients to this recipe before saving'})

      verifiedIngredients = false
    } else {
      this.setState({ingredientsNotification: ''})
      verifiedIngredients = true
    }

    if (verifiedName === true && verifiedIngredients === true) {
      this.setState({nameNotification: ''})
      let recipeStructure = this.state.recipeStructure
      if (this.state.recipeStructure.totalFat) {} else {
        recipeStructure.totalFat = recipeStructure.fat
        recipeStructure.totalCarbs = recipeStructure.Carbs
        recipeStructure.totalProtein = recipeStructure.Protein
      }
      this.saveCustomRecipe(recipeStructure)

    }
  }
  saveCustomRecipe(recipe) {
    // once saved reset the recipe Structure template
    let html = document.documentElement;
    html
      .classList
      .add('noScroll')
    db
      .storeCustomRecipe(this.props.uid, this.state.recipeStructure)
      .then(() => {
        this.setState({mode: 'init'})
        this
          .props
          .handleAddPlannedMeal(recipe)
      })
    this.setState({
      recipeStructure: {
        name: '',
        ingredients: [],
        fat: 0,
        carbs: 0,
        protein: 0
      },
      editing: false
    })
  }
  handleIngredientsSearch(ingredient) {
    // set state to editing
    this.setState({editing: true})
    //set default serving amount (in grams)
    let serving = 100;
    // include serving amounts * default serving
    let servingCarbs = ingredient.carbs;
    let servingFat = ingredient.fat;
    let servingProtein = ingredient.protein;
    let ingredients = this.state.recipeStructure.ingredients;
    ingredient.serving = serving;
    if (ingredient.category) {} else {
      ingredient.category = 'staples'

    }
    ingredient.servingFat = servingFat
    ingredient.servingCarbs = servingCarbs
    ingredient.servingProtein = servingProtein
    // add ingredient to list
    ingredients.push(ingredient);
    // init totals
    let fat = 0,
      protein = 0,
      carbs = 0
    // add up values to work out totals
    ingredients.map((ingredient, index) => {
      fat = fat + ((parseFloat(ingredient.fat) / 100) * serving);
      protein = protein + ((parseFloat(ingredient.protein) / 100) * serving);
      carbs = carbs + ((parseFloat(ingredient.carbs) / 100) * serving);
      ingredient.value = serving;
      ingredient.rawValue = 'g';
      return ingredient;
    })

    //save all to temp List
    this.setState({
      recipeStructure: {
        name: this.state.recipeStructure.name,
        label: this.state.recipeStructure.name,
        value: this.state.recipeStructure.name,
        ingredients: ingredients,
        fat: fat,
        carbs: carbs,
        protein: protein,
        serving: serving,
        ingredients_parts: [
          {
            ingredients: ingredients
          }
        ]
      }
    })

  }
  handleIngredientServing(e) {
    let ingredientList = this.state.recipeStructure.ingredients;
    let input = e.target;
    let key = input.getAttribute('data-inputkey');
    let serving = input.value;

    if (input.value > 0) {
      serving = input.value;
      ingredientList[key].serving = serving;
      ingredientList[key].value = serving;
      ingredientList[key].servingFat = ((ingredientList[key].fat / 100) * serving).toFixed(1);
      ingredientList[key].servingCarbs = ((ingredientList[key].carbs / 100) * serving).toFixed(1);
      ingredientList[key].servingProtein = ((ingredientList[key].protein / 100) * serving).toFixed(1);
    } else {
      serving = 0;
      ingredientList[key].serving = '';
      ingredientList[key].value = 0;
      ingredientList[key].servingFat = (ingredientList[key].fat / 100) * serving;
      ingredientList[key].servingCarbs = (ingredientList[key].carbs / 100) * serving;
      ingredientList[key].servingProtein = (ingredientList[key].protein / 100) * serving;
    }
    let fat = 0;
    let protein = 0;
    let carbs = 0
    for (let i = 0; ingredientList.length > i; i++) {
      let portion = ingredientList[i].serving
      fat = fat + ((parseFloat(ingredientList[i].fat) / 100) * parseInt(portion));
      protein = protein + ((parseFloat(ingredientList[i].protein) / 100) * parseInt(portion));
      carbs = carbs + ((parseFloat(ingredientList[i].carbs) / 100) * parseInt(portion));

    }
    this.setState({
      recipeStructure: {
        name: this.state.recipeStructure.name,
        value: this.state.recipeStructure.name,
        label: this.state.recipeStructure.name,
        ingredients: ingredientList,
        fat: fat,
        carbs: carbs,
        protein: protein
      }
    })
  }
  handleSelectARecipe(e) {
    let value = e.target.value;

    if (value !== 'Select one of your previously created recipes') {
      let recipeStructure = this.props.userRecipes[value]
      let recipe = {
        name: recipeStructure.name,
        ingredients: recipeStructure.ingredients,
        label: recipeStructure.name,
        value: recipeStructure.name,
        fat: recipeStructure.totalFat,
        protein: recipeStructure.totalProtein,
        carbs: recipeStructure.totalCarbs
      }

      this.setState({recipeStructure: recipe})
    }
  }
  handleChangeRecipeName(e) {
    let value = e.target.value;
    this.setState({
      recipeStructure: {
        name: value,
        label: value,
        value: value,
        ingredients: this.state.recipeStructure.ingredients,
        fat: this.state.recipeStructure.fat,
        carbs: this.state.recipeStructure.carbs,
        protein: this.state.recipeStructure.protein,
        serving: ''
      }
    })
  }
  handleCancelRecipeCreator(e) {
    if (this.state.recipeStructure.ingredients.length > 0) {
      this.setState({savePrompt: true})
    } else {
      let html = document.documentElement;
      html
        .classList
        .remove('noScroll')
      this.setState({mode: 'init', savePrompt: false, edited: false})
    }

  }
  handleConfirmCancel(e) {
    this.setState({
      savePrompt: false,
      recipeStructure: {
        name: '',
        ingredients: [],
        fat: 0,
        carbs: 0,
        protein: 0,
        label: '',
        value: ''
      },
      edited: false,
      mode: 'init'
    })
    let html = document.documentElement;
    html
      .classList
      .remove('noScroll')

  }
  handleRemoveIngredient(e) {
    let input = e.target;
    let key = input.getAttribute('data-key');
    let list = this.state.recipeStructure.ingredients;
    list.splice(key, 1);
    let fat = 0;
    let protein = 0;
    let carbs = 0
    for (let i = 0; list.length > i; i++) {
      let portion = list[i].serving
      fat = fat + ((parseFloat(list[i].fat) / 100) * parseInt(portion));
      protein = protein + ((parseFloat(list[i].protein) / 100) * parseInt(portion));
      carbs = carbs + ((parseFloat(list[i].carbs) / 100) * parseInt(portion));

    }
    this.setState({
      recipeStructure: {
        name: this.state.recipeStructure.name,
        value: this.state.recipeStructure.value,
        label: this.state.recipeStructure.label,
        ingredients: list,
        fat: fat,
        carbs: carbs,
        protein: protein
      }
    })

  }
  componentWillUnmount() {
    let html = document.documentElement;
    html
      .classList
      .remove('noScroll')
  }

  render() {
    let initMode = this.state.mode === 'init';
    let createMode = this.state.mode === 'create';
    let showNameNotification = this.state.nameNotification.length > 0;
    let showIngredientsNotification = this.state.ingredientsNotification.length > 0;
    let totals = this.state.recipeStructure.fat > 0 || this.state.recipeStructure.carbs > 0 || this.state.recipeStructure.protein > 0;

    let {userRecipes} = this.props;
    let savePrompt = this.state.savePrompt === true;

    let mobileIngredientsList = this
      .state
      .recipeStructure
      .ingredients
      .map((item, key) => <tr data-ingredient={key} className="border-b mb-2 pb-2">
        <td className="max-w-sm pr-5">{item.name}</td>
        <td className="text-center">{item
            .servingFat
            .toFixed(0)}g</td>
        <td className="text-center">{item
            .servingProtein
            .toFixed(0)}g</td>
        <td className="text-center">{item
            .servingCarbs
            .toFixed(0)}g</td>
        <td className="w-20 flex items-center pb-2">
          <input
            type="text"
            className="formControl numberInput"
            value={item.serving}
            data-inputkey={key}
            placeholder="serving"
            onChange={this
            .handleIngredientServing
            .bind(this)}/>
          <span className="ml-1">
            g</span>
        </td>
        <td>
          <button
            className="bg-secondary text-white text-sm py-2 px-2 rounded-lg"
            onClick={this
            .handleRemoveIngredient
            .bind(this)}
            data-key={key}>Remove</button>
        </td>
      </tr>);
    //
    const light = this.props.light === true;
    return (
      <Fragment>
        {initMode
          ? <Fragment>
              {light
                ? <button
                    onClick={this
                    .handleCreateNewRecipe
                    .bind(this)}
                    className="py-3 mb-4 px-4 text-secondary rounded-lg bg-white shadow-md ml-4 whitespace-no-wrap">
                    <span class="flaticon-add-1"></span>
                    Create a recipe
                  </button>
                : <button
                  onClick={this
                  .handleCreateNewRecipe
                  .bind(this)}
                  className="py-2 px-4 text-white rounded-lg bg-primary ml-4">
                  <span class="flaticon-add-1"></span>
                  Create a new recipe
                </button>
}
            </Fragment>

          : <div className="recipeCreatorModal text-left">
            <button
              className="recipeCreatorFade"
              onClick={this
              .handleCancelRecipeCreator
              .bind(this)}>
              <span className="hidden">cancel</span >
            </button>
            {savePrompt && <button
              className="savePrompt"
              onClick={this
              .handleCancelRecipeCreator
              .bind(this)}>
              <div className="promptWrapper text-center">
                <div className="promptContent rounded-lg shadow-lg">
                  <p className="mb-4">You have unsaved changes that will be lost, do you wish to save?</p>
                  <button
                    className="bg-primary py-2 px-4 rounded-lg text-white"
                    onClick={this
                    .handleSaveCustomRecipe
                    .bind(this)}>Yes</button>&emsp;
                  <button
                    className="bg-secondary py-2 px-4 rounded-lg text-white"
                    onClick={this
                    .handleConfirmCancel
                    .bind(this)}>No</button>
                </div>
              </div>
            </button>
}
            <div className="recipeCreatorModalContent">
              <div className="cancelWrapper">
                <button
                  onClick={this
                  .handleCancelRecipeCreator
                  .bind(this)}
                  className="flaticon-cancel"
                  style={{
                  color: '#f15b29',
                  fontSize: '20px'
                }}>
                  <span className="hidden">cancel</span>
                </button>
              </div>
              <div className="recipeCreator">
                <div className="text-center mb-4">

                  <PageHeader
                    mainHeading="Recipe Creator"
                    subHeading="Add ingredients to make up a recipe.</br>You can save this recipe to use again later."/> {showNameNotification && <div className="text-center my-2">
                    <span className="text-red-700 font-medium">{this.state.nameNotification}</span>
                  </div>
}
                  {showIngredientsNotification && <div className="text-center my-2">
                    <span className="text-red-700 font-medium">{this.state.ingredientsNotification}</span>
                  </div>
}
                  <div className="md:hidden text-center mt-5 mb-5">
                    <button
                      onClick={this
                      .handleSaveCustomRecipe
                      .bind(this)}
                      className="rounded-lg px-6 py-2 bg-primary text-white mb-4">Save Recipe</button>
                  </div>
                </div>
                {createMode && <Fragment>
                  {userRecipes && <select
                    className="my-10 border py-2 px-4 rounded-lg bg-gray-100 w-full"
                    onBlur={this
                    .handleSelectARecipe
                    .bind(this)}>
                    <option selected="selected" data-placeholder="true">Select one of your previously created recipes</option>
                    {Object
                      .keys(this.props.userRecipes)
                      .map((keyName, i) => (
                        <option>{this.props.userRecipes[keyName].name}</option>
                      ))}
                  </select>
}
                  <div className="mb-3 md:flex w-full items-center">
                    <label className="font-bold text-secondary" htmlFor="recipeName">Edit Recipe Name</label>
                    <div className="max-w-md ml-4">
                      <input
                        type="text"
                        onChange={this
                        .handleChangeRecipeName
                        .bind(this)}
                        value={this.state.recipeStructure.name}
                        className="formControl"
                        placeholder="Recipe Name"
                        name="recipeName"
                        id="recipeName"/>
                    </div>
                  </div >
                  <SelectSearch
                    options={this.state.ingredientsDb}
                    value={this.state.ingredientsSearch}
                    onChange={this
                    .handleIngredientsSearch
                    .bind(this)}
                    name="customRecipeIngredients"
                    placeholder="Search for ingredients to add"/>
                  <table className="table">
                    <tr>
                      <th>Ingredient</th>
                      <th className="text-center">Fat</th>
                      <th className="text-center">Protein</th>
                      <th className="text-center">Carbs</th>
                      <th>Serving</th>
                      <th>Remove</th>
                    </tr>
                    {mobileIngredientsList
}
                    {totals && <tr className="mt-5">
                      <th className=" font-bold text-secondary py-5">Total Recipe Macros</th>
                      <td className=" font-bold text-secondary py-5 text-center">{parseFloat(this.state.recipeStructure.fat.toFixed(0))}g</td>
                      <td className=" font-bold text-secondary py-5 text-center">{parseFloat(this.state.recipeStructure.protein.toFixed(0))}g</td>
                      <td className=" font-bold text-secondary py-5 text-center">{parseFloat(this.state.recipeStructure.carbs.toFixed(0))}g</td>
                      <td className=" font-bold text-secondary py-5"></td>
                      <td className=" font-bold text-secondary py-5"></td>
                    </tr>
}
                  </table>
                  <div className="md:block hidden text-center mt-5 mb-5 text-white">
                    <button
                      onClick={this
                      .handleSaveCustomRecipe
                      .bind(this)}
                      className="rounded-lg px-6 py-2 bg-primary text-white mb-4 mx-2">Save Recipe</button >
                    <button
                      onClick={this
                      .handleCancelRecipeCreator
                      .bind(this)}
                      className="bg-secondary text-white rouned-lg mx-2 py-2 px-6 rounded-lg">
                      cancel
                    </button>
                  </div>
                </Fragment>
}
              </div>
            </div> 
						</div>
						}
      </Fragment>
    );
  }
}