import React, { Component } from 'react'
import Logo from '../../Content/images/logos/white.png';
import Banner from '../../Content/images/KetoCalculator/banner.jpg';
import Helmet from 'react-helmet';
import Inputs from './Inputs';
import Outputs from './Outputs';
import ImageUploader from 'react-images-upload';
import MaleAvatar from '../../Content/images/Dashboard/MaleAvatar.png';
import FemaleAvatar from '../../Content/images/Dashboard/FemaleAvatar.png';
import CameraIcon from '../../Content/images/Dashboard/cameraIcon.png';
import {storage, db} from '../../firebase';
import {Link} from 'gatsby';
let shareUrl = '/';

export default class CalculatorPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta name="title" content="Keto Calculator | New Image Keto | Alpha Lipid SDII Programme"/>
                    <meta name="description" content="A free keto calculator for the ketogenic diet to work out your macros to manage weight loss or muscle gain.  The best way to start a Keto plan."/>
                    <meta name="keywords" content="Free, Keto, Calculator, Ketogenic Diet, Macros, Weight Loss, Weight Management, Starting Keto, Plan, Programme, Alpha Lipid™" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                    <meta property="og:url" content={shareUrl} />
                    <meta property="og:title" content="Keto Calculator | New Image Keto | Alpha Lipid SDII Programme"/>
                    <meta name="twitter:title" content="Keto Calculator | New Image Keto | Alpha Lipid SDII Programme"/>
                    <meta property="og:description" content="A free keto calculator for the ketogenic diet to work out your macros to manage weight loss or muscle gain.  The best way to start a Keto plan." />
                    <meta property="twitter:description" content="A free keto calculator for the ketogenic diet to work out your macros to manage weight loss or muscle gain.  The best way to start a Keto plan." />
                    <meta property="og:image" content={Banner} />
                    <meta property="og:image:url" content={Banner}/>
                    <meta name="twitter:image" content={Banner}/>
                    <meta name="twitter:card" content={Banner}/>
                </Helmet> 
                <div className="bg-primary pt-10 text-center">
                    <div className="max-w-md mx-auto">
                        
                        <img src={Logo} alt="New_Image™ Keto" className="block w-64 mx-auto"/>
                        <h1 className="block text-3xl font-bold text-white">THE KETO CALCULATOR</h1>
                    </div>
                </div>
                <div className="relative">
                    <div className="absolute w-full z-10 top-0 bg-primary" style={{height: '50%'}}></div>
                    <div className="absolute w-full z-10 bottom-0" style={{height: '50%', background: '#f1f1f1'}}></div>
                    <div className="relative z-20"><ProfileAvatar uid={this.props.uid} user={this.props.user}/></div>
                </div>
                <div style={{background: '#f1f1f1'}} className="py-10"> 
                    <Inputs calculator={this.props.calculator} user={this.props.user} uid={this.props.uid}/>
                    <Outputs calculator={this.props.calculator} uid={this.props.uid}/>
                    <div className="text-center max-w-sm mx-auto">
						<h2 className="text-2xl font-medium text-primary">Got your macros. Now what?</h2>
						<span className="mb-4 block text-sm">Get the most of your programme, use these macros to measure your daily intake and weight loss to
                        <br/><Link to="/profile/progress-tracker" className="text-blue-500 underline">
                            track your progress
                        </Link> and reach your goals.</span>
						<Link className="py-2 px-8 rounded-lg bg-primary text-white shadow-sm my-2" to="/profile">
							NEXT
						</Link>
					</div>
                </div>
            </div>
        )
    }
}



// PROFILE AVATAR COMPONENT



export class ProfileAvatar  extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			pictures: [],
			sex: this.props.sex,
			imageFieldOpen: false, 
			uploadConfirmed: false,
			avatarImage: '',
		}
	}
	componentDidMount(){
		this.checkAvatar()
	}
	componentWillUpdate(nextProps){
		if(this.props !== nextProps){
			this.checkAvatar()
		}
	}
	checkAvatar(){
		if(this.props.user){
			if(this.props.user.avatarImageUrl){
				this.setState({
					avatarImage: this.props.user.avatarImageUrl,
				})
			}
			else{
				this.checkSex();
			}
		}
	}
	checkSex(){
		if(this.props.user.stats){
			if(this.props.user.stats.sex){
				this.setState({sex: this.props.user.stats.sex})
			}
		}
		else {
			if(this.props.sex === 'Female' || this.props.sex === 'Male'){
				this.setState({sex: this.props.sex})	
			}
			else {
				this.setState({sex: 'Female'})
			}
		}
	}
	storeUserAvatarImage(){
		storage.storeUserAvatar(this.props.uid, this.state.picture[0]).then((url)=>{
       		if(url){
       			this.setState({uploadConfirmed: true,avatarImage: url})
       			db.storeuploadedAvatar(this.props.uid, url).then(()=>{
					this.setState({
                        uploadConfirmed: false,
                        avatarImage: url,
                        preview: '',
                        previewVisible: true,
		       		})
	       			this.handleOpenImageField();
       			})
       		}
       	})
	}
	handleChangeProfileAvatar(picture, pictureDataURLs) {
        this.setState({
            picture: picture,
            url: pictureDataURLs,
        });
        this.setState({
        	previewVisible: true,
        	preview: pictureDataURLs,
        })
    }
    handleCancelAvatarChoice(){
    	this.setState({
    		preview: '',
    		previewVisible: false,
    	})
    }
    handleConfirmAvatarChoice(){
    	this.storeUserAvatarImage();
    }
    handleOpenImageField(){
        let status = this.state.imageFieldOpen;
    	this.setState({imageFieldOpen: !status})
    }
	render(){
		let {preview} = this.state;
		let male = this.props.user.sex === 'Male';
		let imageFieldActive = this.state.imageFieldOpen === true;
		let hasAvatarImage = this.state.avatarImage.length > 0;
		let style = {
			backgroundImage: 'url(' + this.state.avatarImage + ')',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
		}
		let previewStyle = {
			backgroundImage: 'url(' + this.state.preview + ')',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			display: 'block',
            margin: '0 auto',
            borderRadius: '50%',
            overflow: 'hidden',
            height: '200px',
            width: '200px'
		}
		return(
			<div className="profileAccountFlex getStartedAvatar">
				{imageFieldActive&&
					<div className="imageFieldModal">
						<button className="imageFieldFade" onClick={this.handleOpenImageField.bind(this)}>
							<span className="hidden">Change Image</span>
						</button>
						<div className="bg-white max-w-sm mx-auto shadow-lg rounded-lg z-50 p-5">
							{preview?
							<div className="text-center">
								<h3 className="text-2xl font-medium text-secondary">Preview</h3>
								<div style={previewStyle} className="my-4"></div>
                                <div className="flex items-center w-full my-4">
                                    <button onClick={this.handleConfirmAvatarChoice.bind(this)} className="bg-primary py-2 w-full rounded-lg text-white block mx-0">Confirm</button>&emsp;&emsp;
                                    <button onClick={this.handleCancelAvatarChoice.bind(this)} className="bg-secondary py-2 w-full rounded-lg text-white block mx-0">Cancel</button>
                                </div>
							</div>
							:
							<ImageUploader
				                withIcon={true}
				                buttonText='Choose image'
				                onChange={this.handleChangeProfileAvatar.bind(this)}
				                imgExtension={['.jpg','.png']}
				                maxFileSize={3642880}
				                withPreview={false}
				                singleImage={true}
				            />
				            }
						</div>
					</div>
				}
				{hasAvatarImage ?
					<div className="avatarImageWrapper">
						<button className="cameraIconWrapper" onClick={this.handleOpenImageField.bind(this)}>
							<img src={CameraIcon} alt="Change Profile"/>
							<span className="hidden">Change Profile Image</span>
						</button>
						<div className="customAvatar" style={style}></div>
					</div>
				:
					<div className="avatarImageWrapper">
						<button className="cameraIconWrapper" onClick={this.handleOpenImageField.bind(this)}>
							<img src={CameraIcon} alt="Change Profile"/>
							<span className="hidden">Change Profile Image</span>
						</button>
						{male? <img src={MaleAvatar} alt="Male Avatar"/>:
                        <img src={FemaleAvatar} alt="Female Avatar"/>}
					</div>
				}
			</div>
		)
	}
}