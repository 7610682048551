import React from 'react';
import IntroductionImage from '../../Content/images/Meal Plans/introduction.jpg'
import {Link} from 'gatsby';
import PageHeader from '../PageComponents/PageHeader';
export default class MealPlansIntroduction extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			size: 'landscape'
		}
	}
	checkDimensions(){
		if(this.props.style.width < this.props.style.minHeight){
			this.setState({
				size: 'portrait',
				promptForStats: false,
			})
		}
	}
	componentDidMount(){
		this.checkDimensions();
	}
	componentDidUpdate(lastProps){
		if(lastProps.style !== this.props.style){
			this.checkDimensions();
		}
	}
	togglePrompt(){
		let status = this.state.promptForStats;
		this.setState({promptForStats: !status})
	}
	handleStartMealPlan(){
		if(this.props.user.stats){
			this.props.handleAddMealPlan()
		}
		else {
			this.togglePrompt();
		}
	}
	render() {
		const {promptForStats} = this.state
		return (
			<>
			<div className="mealPlanIntroduction" style={this.props.style}>
				<div className="introductionHeaderWrapper">
					<PageHeader mainHeading="Meal Plans" subHeading="Made Easy"/>
					<div className="text-center">
						<br/>
						<button onClick={this.handleStartMealPlan.bind(this)} className="bg-primary px-6 py-2 rounded-lg text-white my-2">
							Start Meal Plan
						</button>
					</div>
				</div>
				<img src={IntroductionImage} className="mealPlanIntroImage" alt="NewImage Keto online meal plan"/>
			</div>
			{promptForStats &&
				<div className="fixed w-screen h-screen top-0 left-0 z-50 bg-darken-3 bg-black bg-opacity-75">
					<div className="h-full flex h-full items-center">
						<div className="max-w-sm bg-white p-10 rounded-lg shadow-lg mx-auto">
							<p>You currently dont have any stats saved to your profile.  
							We recommend doing this first, before you start a journal.</p>
							<Link className="bg-primary text-white py-2 px-6 inline-block mx-auto" to="/profile/tell-us-about-yourself">
								Start
							</Link>
						</div>
					</div>
				</div>
			}	
			</>
			
		);
	}
}
