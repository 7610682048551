import React, { Component } from 'react';

export default class KetoneInputs extends Component {
	constructor(props){
		super(props);
		this.state = {
			ketones: this.props.ketones,
		}
	}
	handleKetoneReading(e){
		let ketones = e.target.value;
		this.props.handleKetones(ketones);
	}
	componentDidMount(){
		
		this.checkKetones();
	}
	componentDidUpdate(prevProps, prevState){
		if(this.props !== prevProps){
			this.checkKetones();
		}
	}
	checkKetones(){
		let ketones = this.props.ketones;	
		let inputs = document.getElementsByName('ketones');
		for(let i=0; i < inputs.length; i++){			
			let ketonesNumber = inputs[i].value;
			let inputKetones = parseInt(ketonesNumber)
			if(inputKetones === ketones){
				inputs[i].setAttribute("checked", 'checked')
			}
			if(ketonesNumber === ketones){
				inputs[i].setAttribute("checked", 'checked')
			}
		}
		
	}

	render() {
		return (
			<div>
				
				<div>
					<div className="ketoneInput">
						<label htmlFor="ketones">NEG</label>
						<input 
							onClick={this.handleKetoneReading.bind(this)} 
							type="radio" value="0" 
							name="ketones"
						/>
						<span></span>
					</div>
					<div className="ketoneInput">
						<label htmlFor="ketones">&plusmn;</label>
						<input 
							onClick={this.handleKetoneReading.bind(this)} 
							type="radio" value="5" 
							name="ketones"
						/>
						<span></span>
					</div>
					<div className="ketoneInput">
						<label htmlFor="ketones">+</label>
						<input 
							onClick={this.handleKetoneReading.bind(this)} 
							type="radio" value="10" 
							name="ketones"
						/>
						<span></span>
					</div>
					<div className="ketoneInput">
						<label htmlFor="ketones">++</label>
						<input 
							onClick={this.handleKetoneReading.bind(this)} 
							type="radio" value="50" 
							name="ketones"
						/>
						<span></span>
					</div>
					<div className="ketoneInput">
						<label htmlFor="ketones">+++</label>
						<input 
							onClick={this.handleKetoneReading.bind(this)} type="radio" value="100" name="ketones"/>
						<span></span>
					</div>
				</div>
			</div>
		);
	}
}
