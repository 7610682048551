import React from 'react'
import { connect } from 'react-redux'
import { storeUser, storeUserId, storeRecipes, storeCalculator } from "../../redux/actions/";
import CalculatorPage from './TellUsAboutYourself'
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
        storeCalculator: stats => dispatch(storeCalculator(stats))    
	};
}
const mapStateToProps = state => {
	return { user: state.user, uid: state.uid, recipes: state.recipes, calculator: state.calculator};
  };
const ConnectedComponent = ({ user, uid, recipes, calculator }) => (
  <main>
    <div className="relative">
    <CalculatorPage user={user} uid={uid} recipes={recipes} content={recipes} calculator={calculator}/>
    </div>
  </main>
);  

const ReduxComponent = connect(mapStateToProps,mapDispatchToProps)(ConnectedComponent);
  
export default ReduxComponent;