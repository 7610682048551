import React, { Component } from 'react';


export default class SleepQualityInputs extends Component {
	componentDidMount(){
		this.handleUpdateInputs();
	}
	componentDidUpdate(prevProps) {
		if(this.props !== prevProps){
			this.handleUpdateInputs();
		}
	}
	
	handleUpdateInputs(){

		let quality = this.props.quality;
		let inputs = document.getElementsByName('sleepQuality');
		for(let i=0; i < inputs.length; i++){
			let qualityNumber = inputs[i].value;
			let inputQuality = parseInt(qualityNumber)
			if(inputQuality === quality){
				inputs[i].setAttribute("checked", 'checked')
			}
			if(qualityNumber === quality){
				inputs[i].setAttribute("checked", 'checked')
			}
		}
	}
	handleSleepQuality(e){
		this.props.handleQuality(e.target.value);
	}
	render() {
		return (
			<div className="emotionInputsWrapper">
				<div className="inlineLabelWrapper">
					Quality of sleep
				</div>
				<div className="inlineInputsWrapper">
					<div className="emotionInput">
						<input 
							type="radio" 
							name="sleepQuality" 
							onClick={this.handleSleepQuality.bind(this)} 
							value="1"
						/>
						<span className="flaticon-sad-sleepy-emoticon-face-square"></span>
					</div>
					<div className="emotionInput">
						<input 
							type="radio" 
							name="sleepQuality" 
							onClick={this.handleSleepQuality.bind(this)} 
							value="2"
						/>
						<span className="flaticon-sad-face-in-rounded-square"></span>
					</div>
					<div className="emotionInput">
						<input 
							type="radio" 
							name="sleepQuality" 
							onClick={this.handleSleepQuality.bind(this)} 
							value="3"
						/>
						<span className="flaticon-emoticon-square-face-with-closed-eyes-and-mouth-of-straight-lines"></span>
					</div>
					<div className="emotionInput">
						<input 
							type="radio" 
							name="sleepQuality" 
							onClick={this.handleSleepQuality.bind(this)} 
							value="4"
						/>
						<span className="flaticon-smiling-emoticon-square-face"></span>
					</div>
					<div className="emotionInput">
						<input 
							type="radio" 
							name="sleepQuality" 
							onClick={this.handleSleepQuality.bind(this)} 
							value="5"
						/>
						<span className="flaticon-smiling-happy-emoticon-face"></span>
					</div>
				</div>
			</div>
		);
	}
}
