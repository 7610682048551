import React, { Component } from 'react';

export default class MainMeal extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	meals: this.props.meals,
	  	width: 0,
	  	showMacros: false,
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	handleShowMacros(){
		if(this.state.showMacros === false){
			this.setState({
				showMacros: true,
			})
		}
		else {
			this.setState({
				showMacros: false,
			})
		}
		
	}
	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	componentDidMount(){
		window.addEventListener('resize', this.updateWindowDimensions);
		this.updateWindowDimensions();
		
	}
	handleRemoveMeal(e){
		let button = e.target;
		let index = button.getAttribute('data-index');
		this.props.handleRemoveMeal(index)
	}
	render() {
		const {name} = this.props.meals;
		const showMacros = this.state.showMacros === true;
			if(this.state.width > 767){
				return (
					<tr>	
					{name &&	
						<td className="py-1">
							<h6 className="mealName"><strong>{this.props.label} </strong>&ensp; {this.props.meals.title} </h6>
						</td>
					}
					{name &&	
						<td className="text-center py-1">
							{parseFloat(this.props.meals.fat).toFixed(1)}g
						</td>
					}
					{name &&	
						<td className="text-center py-1">
							{parseFloat(this.props.meals.carbs).toFixed(1)}g
						</td>
					}
					{name &&	
						<td className="text-center py-1">
							{parseFloat(this.props.meals.protein).toFixed(1)}g
						</td>
					}
					{name &&	
						<td className="py-1">
							<div>
								<button 
									className="flaticon-minus removeSnackButton" 
									onClick={this.props.handleRemoveMeal} 
								>
									<span className="hidden">Remove meal</span>
								</button>
							</div>
						</td>
					}					
				</tr>
			);
		}
		else {
			return(
				<>	
					{name &&	
						<ul className="mobileMainMealJournalItem mb-2 mt-2">
							
								<li>
									<button className="mealName" onClick={this.handleShowMacros.bind(this)}>
										<strong style={{color: '#f15b29'}}>
											{this.props.label}
										</strong>
										&ensp; 
										{this.props.meals.title}
										&ensp;
									</button>
								</li>
								<li>
									<button  onClick={this.handleShowMacros.bind(this)} className="flaticon-down-arrow">
										<span className="hidden">show macros</span>
									</button>
								</li>
								<li>
									<div className="text-right">
										<button 
											className="flaticon-minus removeSnackButton" 
											onClick={this.props.handleRemoveMeal} 
										>
											<span className="hidden">remove meal</span>
										</button>
									</div>
								</li>
								
						</ul>
					}	
					{showMacros&&
					<ul className="mobileMainMealsMacroTable" data-showmacros={this.state.showMacros}>
						
						{name &&	
							<li className="text-center">
								<strong>Fat:</strong> {this.props.meals.fat}g
							</li>
						}
						{name &&	
							<li className="text-center">
								<strong>Carbs:</strong> {this.props.meals.carbs}g
							</li>
						}
						{name &&	
							<li className="text-center">
								<strong>Protein:</strong> {this.props.meals.protein}g
							</li>
						}
						
					</ul>
					}				
				</>
			)
		}
		
	}
}
