import React, { Component } from 'react';
import ExistingJournalEntries from './ExistingJournalEntries';
import JournalEntryEditor from './JournalEntryEditor';
import {animateScroll as scroll } from 'react-scroll'
import PageHeader from '../../PageComponents/PageHeader';
import {Link, navigate} from 'gatsby';
import JournalHeroes from '../../../Content/images/Journal/WeigthLosscouple.jpg';

export default class JournalEntries extends Component {
	constructor(props) {
	  super(props);	
	  this.state = {
	  	mode: 'init',
	  	mealPlans: this.props.mealPlans,
	  	date: [
	  		new Date().getDate(),
	  		new Date().getMonth() + 1,
	  		new Date().getFullYear()
	  	],
	  	journal: this.props.journal,	
	  	timeKeeping: this.props.timeKeeping,
	  	user: this.props.user,
	  	openEditor: false,
	  	showHeader: true,  	
	  	promptForStats: false,	
	  };
	}
	componentDidMount(){
		this.setState({
			mealPlans: this.props.mealPlans,
			journal: this.props.journal,
			timeKeeping: this.props.timeKeeping,
			user: this.props.user,
		})
	}
	handleMode(mode){
		if(mode === 'init'){
			this.closeOnSave();
		}
		else {
			this.setState({showHeader: false,})
		}
		
	}
	handleScrollToTop(){
		scroll.scrollTo(0, {
		 	duration: 50,
  			smooth: false,
  		});
	}
	handleOpenEditForm(){
		this.handleScrollToTop();
		if(this.props.user.stats){
			this.setState({mode: 'edit', openEditor: true})
		}
		else {
			this.setState({promptForStats: true,})
		}
	}
	handleCloseStatsPrompt(){
		this.setState({promptForStats: false,})
	}
	handleStartStats(){
		navigate('/profile/tell-us-about-yourself');
	}
	closeOnSave(){
		this.handleScrollToTop();
		this.setState({
			mode: 'init',
			openEditor: false,
			showHeader: true,
		})
		
	}
	render() {
		let {journal} = this.props;
		let {showHeader} = this.state;
		let promptForStats = this.state.promptForStats === true;
			if(this.state.mode === 'newentry' || this.state.mode === 'edit'){
				return (
					<div>
						<div className={"journalEditorContainer " + this.state.mode}>
							<JournalEntryEditor uid={this.props.uid} 
								closeOnSave={this.closeOnSave.bind(this)}
								mealPlans={this.props.mealPlans}
								reloadUserData={this.props.reloadUserData}
								timeKeeping={this.props.timeKeeping}
								user={this.props.user}
								recipesMacroTable={this.props.recipesMacroTable}
								userRecipes={this.props.userRecipes}
							/>
						</div>
					</div>
				);		
			}
			if(this.state.mode === 'init'){
				return(
						<div>
							{journal?
								<div>
									{showHeader &&
										<PageHeader mainHeading="Daily Journal" subHeading="tracking your daily journal<br/>helps your progress"/>
									}
									<ExistingJournalEntries journalEntries={this.props.journal}
										uid={this.props.uid}
										mealPlans={this.props.mealPlans}
										reloadUserData={this.props.reloadUserData}
										timeKeeping={this.props.timeKeeping}
										user={this.props.user}
										handleMode={this.handleMode.bind(this)}
										recipesMacroTable={this.props.recipesMacroTable}
										userRecipes={this.props.userRecipes}
									/>
									<div className="text-center">
										<button onClick={this.handleOpenEditForm.bind(this)} id="journalCreateTodayButton" className="py-2 px-6 bg-primary text-white rounded-lg">
											Create an entry for today
										</button>
									</div>
								</div>
							:
							<div className="text-center emptyJournalWrapper">
								<div>
									<img src={JournalHeroes} alt="" className="journalHeroes"/>
									<PageHeader mainHeading="Daily Journal" subHeading="tracking your daily journal<br/>helps your progress"/>
									<br/>	
									<button onClick={this.handleOpenEditForm.bind(this)} className="bg-primary rounded-lg text-white py-2 px-6" style={{zIndex: 2, position: 'relative'}}>
										Start Journal
									</button>
									{promptForStats &&
										<div className="fixed w-screen h-screen top-0 left-0 z-50 bg-darken-3 bg-black bg-opacity-75">
											<div className="h-full flex h-full items-center">
												<div className="max-w-sm bg-white p-10 rounded-lg shadow-lg mx-auto">
													<p>You currently dont have any stats saved to your profile.  
													We recommend doing this first, before you start a journal.</p>
													<Link className="bg-primary text-white py-2 px-6 inline-block mx-auto" to="profile/tell-us-about-yourself">
														Start
													</Link>
												</div>
											</div>
										</div>
									}	
								</div>
							</div>
							}
						</div>
				)
				
			}
			
		}
		
	}

