import React, { Component } from 'react'; 
import {db} from '../../../firebase';
import '../../../Content/css/mealPlanEditor.css'
import DailyMeals from './DailyMeals';
import SnackPreferenceSwitch from './SnackPreferenceSwitch';
import SelectSearch from 'react-select-search';
import MealAddPeek from './MealAddPeek';
import AddFromRecipeFavorites from './FavoriteRecipesSelector'
import ReactToPrint from 'react-to-print';
import { MealPlanSnacks } from './../Journal/FormInputs/FoodValueTable';
import UserRecipeCreator from './../Journal/FormInputs/UserRecipeCreator';
import { storeUser, addNotification, removeNotification } from "../../../redux/actions/";
import { connect } from "react-redux";

// import UserRecipeCreator from '../../User/Journal/FormInputs/UserRecipeCreator';
let Newdate = new Date();
let dayOfWeek = Newdate.getDay();
let findDay = require('weekday');




class MealPlanEditor extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	editMode: 'init',
	  	preferSnacks: false,
	  	activeDay: dayOfWeek,
	  	addTempMeal: {},
	  	favorites: [],
	  	user: this.props.user,
	  	editor: {
	  		customMeal: '',
	  	},
	  	plan: [],
	  	dayStatusClass: 'dayEditor notActive',
	  	editorNotificationMessage: '',
	  	notificationMessageStatus: true,
	  	tempCarbs: 0,
	  	totals:{
	  		fat: 0,
	  		carbs: 0,
	  		protein: 0,
	  	},
	  	selectedItems: [],
	  	customRecipes: {},
	  	tempList: [],
	  	shoppingListPrompt: false,
	  	resetPrompt: false,
	  	foodOptions: this.props.allRecipes.concat(MealPlanSnacks.food),
	  	mealEditorMobileNavOpen: false,
	  	createRecipeModalOpen: false,
	  };
	}
	handleResetPlan(){
		this.props.resetPlan();	
		db.savePlanState(this.props.uid, this.props.initialPlanState, this.props.currentPlan)
		this.setState({editMode: 'reset'})
		setTimeout(()=>{
			this.refreshUser()
			this.setState({
				plan: this.props.initialPlanState,
				editMode: 'init',
				resetPrompt: false,
			})
		},5)
		this.props.addNotification('Meal plan has been reset')
	}
	handleResetPrompt(){
		let promptState = this.state.resetPrompt;
		this.setState({resetPrompt: !promptState,})
	}
	handleCustomRecipeIngredientParts(recipe){
		let list = [];
		for(let i = 0; recipe.ingredients.length > i; i++){
			let recipeDetails = {
				name: recipe.name,
				url: false,
				measurement: 'g',
				cateory: recipe.ingredients[i].category,
				value: recipe.ingredients[i].serving,
			}	
			let recipes = [recipeDetails]
			let ingredientItem = {
				measurement: 'g', 
				rawValue: 'g', 
				value: recipe.ingredients[i].serving, 
				ingredient: recipe.ingredients[i].name,
				category: recipe.ingredients[i].category, 
				recipes: recipes, 
				ticked: false
			}
			list.push(ingredientItem);
		}
		return list

	}
	handleCustomRecipe(recipe){
		let ingredient_parts = this.handleCustomRecipeIngredientParts(recipe)
		//let formattedIngredients = this.handleIngredientParts(ingredient_parts)
		let formattedRecipe = {
			carbs: recipe.carbs,
			protein: recipe.protein,
			fat: recipe.fat,
			totalCarbs: recipe.carbs,
			totalProtein: recipe.protein,
			totalFat: recipe.fat,
			title: recipe.name,
			route: false,
			recipe: {
				title: recipe.name,
				totalCarbs: recipe.carbs,
				totalProtein: recipe.protein,
				totalFat: recipe.fat,
				method: 'Custom recipe',
				ingredients_parts: [{ingredients: ingredient_parts}],
				url: false,
			}
		}
		this.handleAddMealToPlan(formattedRecipe);
	}
	handleIngredientParts(parts, day, meal){
		let ingredientParts = parts;
		let list = []		
		// for the recipe's ingredients Parts
		for(let ip = 0; ingredientParts.length > ip; ip++){
			let ingredients = ingredientParts[ip].ingredients
			//for the ingredients in those parts
			for(let i = 0; ingredients.length > i; i++){
				let value = parseInt(ingredients[i].value);
				if(value === '&frac12;'){ value = (1/2).toFixed(1) }
				if(value === '&frac13;'){ value = (1/3).toFixed(1) }
				if(value === '&frac14;'){ value = (1/4).toFixed(1) }
				if(value === '&frac15;'){ value = (1/5).toFixed(1) }
				if(value === '&frac16;'){ value = (1/6).toFixed(1) }
				if(value === '&frac17;'){ value = (1/7).toFixed(1) }
				if(value === '&frac18;'){ value = (1/8).toFixed(1) }
				if(value > 0){
					
				}
				else {value = 1;}
				let url = false;
				if(day[meal].meal.route){
					url = day[meal].meal.route
				}
				let recipeDetails = {
					name: day[meal].meal.title,
					url: url,
					measurement: ingredients[i].rawValue,
					cateory: ingredients[i].category,
					value: value,
				}	
				let recipes = [recipeDetails]
				let ingredientItem = {
					measurement: ingredients[i].rawValue, 
					value: value, 
					ingredient: ingredients[i].ingredient,
					category: ingredients[i].category, 
					recipes: recipes, 
					ticked: false
				}
				list.push(ingredientItem);
			}
		}
		return list
	}
	handleAllIngredients(){
		let plan = this.state.plan;
		// for days
		let list = [];
		// for all the days in the plan, 
		for(let d = 0; plan.length > d; d++){
			// we use these keys to find the meal type
			let mealsOfTheDay = ['afternoonSnack', 'morningSnack', 'breakfast', 'lunch', 'dinner'];
			for(let m = 0; mealsOfTheDay.length > m; m++){
				// if there are valid ingredients layed out
				let mealName = mealsOfTheDay[m]
				if(plan[d][mealName].meal.recipe){
					if(plan[d][mealName].meal.recipe.ingredients_parts){
						// handle IngredientsParts returns an ingredient list we can use for a shopping list
						let listItems = this.handleIngredientParts(
							plan[d][mealName].meal.recipe.ingredients_parts,
							plan[d], 
							mealName
							
						);
						// add these returned ingredients to a list
						list = list.concat(listItems)
					}
				}
			}
		}
		// filter existing items adding their values if they exist in the list already
		let output = [];
		if(this.props.user.Shopping_List){
			output = this.props.user.Shopping_List;
		}
		for(let i = 0; list.length > i; i++){
			//	filter ingredient to see if it it's on the list already
			var existing = output.filter(function(v, f) {
			    return v.ingredient === list[i].ingredient;
			});
			if(existing.length){
				var existingIndex = output.indexOf(existing[0]);
				// get value and add to the existing item
    			output[existingIndex].value = parseInt(output[existingIndex].value) + parseInt(list[i].value);
    			//merge recipes arrays  
    			//output[existingIndex].recipes = intersection
			}
			else {
				// create a new list item
				output.push(list[i])
			}
		}
		// set filtered list as a temp and wait for prompt to confirm save to db
		this.setState({tempList: output, shoppingListPrompt: true})
		
	}
	updateWindowDimensions() {
		if(window.innerWidth > 999){
			this.setState({deviceLayout: 'desktopLayout'})
		}
		if(window.innerWidth <= 999){
			this.setState({deviceLayout: 'mobileLayout'})
			setTimeout(()=>{this.handleActiveDay(this.state.activeDay)},100)
		}
		if(window.innerWidth <= 999 && window.innerWidth > 401){
			this.setState({deviceLayout: 'smallMobileLayout'})
		}
	}
	handleChangeActiveDay(e){
		let button = e.target
		let day = parseInt(button.getAttribute('data-day'));
		if((day !== this.state.activeDay) && (day >= 0 && day < 7)){
			this.setState({activeDay: day,})
			if(this.state.deviceLayout !== 'desktopLayout'){
				setTimeout(()=>{this.handleActiveDay(day)},100)
			}	
		}
	}
	handleActiveDay(day){
		if(this.state.deviceLayout !== 'desktopLayout'){
			let sunday = document.getElementById('daySelector0'),
				monday = document.getElementById('daySelector1'),
				tuesday = document.getElementById('daySelector2'),
				wednesday = document.getElementById('daySelector3'),
				thursday = document.getElementById('daySelector4'),
				friday = document.getElementById('daySelector5'),
				saturday = document.getElementById('daySelector6');
		
			sunday.setAttribute('data-active', 'false')
			monday.setAttribute('data-active', 'false')
			tuesday.setAttribute('data-active', 'false')
			wednesday.setAttribute('data-active', 'false')
			thursday.setAttribute('data-active', 'false')
			friday.setAttribute('data-active', 'false')
			saturday.setAttribute('data-active', 'false')
				switch(day){
					default:sunday.setAttribute('data-active', 'true');
					case(day === 0):sunday.setAttribute('data-active', 'true');break;
					case(day === 1):monday.setAttribute('data-active', 'true');break;
					case(day === 2):tuesday.setAttribute('data-active', 'true');break;
					case(day === 3):wednesday.setAttribute('data-active', 'true');break;
					case(day === 4):thursday.setAttribute('data-active', 'true');break;
					case(day === 5):friday.setAttribute('data-active', 'true');break;
					case(day === 6):saturday.setAttribute('data-active', 'true');break;
				}
		}
	}
	handleConfirmSave(){
		this.setState({
			shoppingListPrompt: false
		})
		setTimeout(()=>{
			db.saveShoppingList(this.props.uid, this.state.tempList);
			this.refreshUser()
			this.props.addNotification('All ingredients from all meals have been added to your shopping list')
		},20)
	}
	componentDidMount(){
		var recipes = this.props.allRecipes;

		var favorites = recipes.filter(function(recipe) {
			return recipe.favorite === true;
		});
		this.setState({
			favorites,
		})
	  	window.addEventListener('resize', this.updateWindowDimensions.bind(this));
		this.updateWindowDimensions();
		this.setState({
			user: this.props.user,
			plan: this.props.plan,
			editMode: 'init',
		})
		if(this.props.user.Shopping_List){
			this.setState({selectedItems: this.props.user.Shopping_List})
		}
		if(this.props.user.Custom_Recipes){
			this.setState({
				customRecipes: this.props.user.Custom_Recipes,
			})
		}
		if(this.props.user.preferences){
			if(this.props.user.preferences.mealPlan.preferSnacks){
				this.setState({
					preferSnacks: this.props.user.preferences.mealPlan.preferSnacks
				})
			}
		}
		if(this.props.user.Custom_Recipes){
			this.setState({
				customRecipes: this.props.user.Custom_Recipes,
			})
		}
		this.setState({
			user: this.props.user,
			plan: this.props.plan,
		})
	}

	componentDidUpdate(nextProps){
		if(nextProps !== this.props){
			if(this.props.user.preferences){
				if(this.props.user.preferences.mealPlan){
					this.setState({
						preferSnacks: this.props.user.preferences.mealPlan.preferSnacks
					})
				}
			}
			if(this.props.user.Custom_Recipes){
				this.setState({
					customRecipes: this.props.user.Custom_Recipes,
				})
			}
			this.setState({
				user: this.props.user,
				plan: nextProps.plan,
			})
		}
	}
	handleBackToEditor(){
		this.setState({
			editMode: 'init',
		})
	}
	handleAddFromFavorites(day,meal,wholemeal, tempCarbs, tempProtein){
		const {plan} = this.state;
		let daysCarbs = plan[day].totalCarbs >= 0;
		let daysProtein = plan[day].totalProtein >= 0;
		plan[day][meal].meal = wholemeal;
		if(wholemeal.recipe.totalProtein > 0){
			if(daysProtein === true){
				plan[day].totalProtein = plan[day].totalProtein + wholemeal.recipe.totalProtein;
			}
			else{
				plan[day].totalProtein = wholemeal.recipe.totalProtein;
			}		
		}
		if(tempCarbs >= 0){
			if(wholemeal.recipe.totalCarbs >= 0){
				if(daysCarbs === true){
					plan[day].totalCarbs = plan[day].totalCarbs - tempCarbs + wholemeal.recipe.totalCarbs;
				}
				else{
					plan[day].totalCarbs = wholemeal.recipe.totalCarbs;
				}
			}
		}
		else {
			if(wholemeal.recipe.totalCarbs > 0){
				if(daysCarbs === true){
					plan[day].totalCarbs = plan[day].totalCarbs - 0 + wholemeal.recipe.totalCarbs;
				}
				else{plan[day].totalCarbs = wholemeal.recipe.totalCarbs}
			}
		}		
		this.setState({plan})
		db.savePlanState(this.props.uid, plan, this.props.currentPlan).then(()=>{
			this.setState({editMode: 'init'})
			this.refreshUser();
		})
		.catch((e)=>{console.log(e)})
		
	}
	refreshUser(){
		db.getUserInfo(this.props.uid).then((user)=>{
			this.proos.storeUser(user)
		})
		.catch((e)=>{console.log(e)})
	}
	handleAddMeal(day, meal){
		this.setState({
			editMode: 'addRecipe',
			temp: [day, meal],
		})
		
		let object = this.state.plan[day][meal].meal.recipe;
		if(object){
			this.setState({
				tempCarbs: this.state.plan[day][meal].meal.recipe.totalCarbs,
			})	
		}
		else {
			this.setState({tempCarbs:0})
		}
		window.scrollTo(0, 0);
	}
	handleRemoveMeal(day, meal){
		const {plan} = this.state;
		plan[day][meal].meal = {title: ''}
	}
	makeThisActiveMealPlan(){
		db.makeMealPlanActive(this.props.uid, this.state.plan)
		this.refreshUser()
		this.props.addNotification('Your meal plan has been saved');		
	}
	handleRecipesSearch(e){
		this.setState({
			addTempMeal: e,
			requireSaveButton: true
		})	
		
	}
	handleCreateRecipeModal(){
		let status = this.state.createRecipeModalOpen === true;
		this.setState({
			createRecipeModalOpen: !status,
		})
	}
	handleMealPlanMobileNav(){
		let status = this.state.mealEditorMobileNavOpen === true;		
		this.setState({
			mealEditorMobileNavOpen: !status,
		})
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	handleAddMealToPlan(meal){
		const currentPlan = this.state.plan;
		const {temp} = this.state;
		let editHasCarbs = parseInt(currentPlan[temp[0]][temp[1]].meal.totalCarbs) > 0;
		let dayHasCarbs = parseInt(currentPlan[temp[0]].totalCarbs) > 0;
		let editHasFat = parseInt(currentPlan[temp[0]][temp[1]].meal.totalFat) > 0;
		let dayHasFat = parseInt(currentPlan[temp[0]].totalFat) > 0;
		let editHasProtein = parseInt(currentPlan[temp[0]][temp[1]].meal.totalProtein) > 0;
		let dayHasProtein = parseInt(currentPlan[temp[0]].totalProtein) > 0;
		this.setState({addTempMeal: []})
		if(dayHasCarbs){
			if(editHasCarbs){
				currentPlan[temp[0]].totalCarbs = 
				(parseInt(currentPlan[temp[0]].totalCarbs) - 
				parseInt(currentPlan[temp[0]][temp[1]].meal.totalCarbs)) + 
				meal.totalCarbs;
				currentPlan[temp[0]][temp[1]].meal = meal;
				db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
					this.setState({
						plan: currentPlan,
						saveSuccess: true,
						editMode: 'init',
					})
					
				})
				.catch((e)=>{console.log(e)})
			}
			else{
				currentPlan[temp[0]].totalCarbs = parseInt(currentPlan[temp[0]].totalCarbs) + 
				parseInt(meal.totalCarbs);
				currentPlan[temp[0]][temp[1]].meal = meal;
				db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
				this.setState({editMode: 'init'})
					this.setState({
						plan: currentPlan,
						saveSuccess: true,
						editMode: 'init',
					})
					
				})
				.catch((e)=>{console.log(e)})
			}	
		}
		else {
			currentPlan[temp[0]].totalCarbs = meal.totalCarbs;
			currentPlan[temp[0]][temp[1]].meal = meal;
			db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
			this.setState({editMode: 'init'})
				this.setState({
					plan: currentPlan,
					saveSuccess: true,
					editMode: 'init',
				})
			})
		}
		if(dayHasFat){
			if(editHasFat){
				currentPlan[temp[0]].totalFat = 
				(parseInt(currentPlan[temp[0]].totalFat) - 
				parseInt(currentPlan[temp[0]][temp[1]].meal.totalFat)) + 
				parseInt(meal.totalFat);
				currentPlan[temp[0]][temp[1]].meal = meal;
				db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
					this.setState({
						plan: currentPlan,
						saveSuccess: true,
						editMode: 'init',
					})
				})
			}
			else{
				currentPlan[temp[0]].totalFat = parseInt(currentPlan[temp[0]].totalFat) + 
				parseInt(meal.totalFat);
				currentPlan[temp[0]][temp[1]].meal = meal;
				db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
				this.setState({editMode: 'init'})
					this.setState({
						plan: currentPlan,
						saveSuccess: true,
						editMode: 'init',
						
					})
				})
			}	
		}
		else {
			currentPlan[temp[0]].totalFat = parseInt(meal.totalFat);
			currentPlan[temp[0]][temp[1]].meal = meal;
			db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
			this.setState({editMode: 'init'})
				this.setState({
					plan: currentPlan,
					editMode: 'init',
				})
			})
		}
		if(dayHasProtein){
			if(editHasProtein){
				currentPlan[temp[0]].totalProtein = 
				(parseInt(currentPlan[temp[0]].totalProtein) - 
				parseInt(currentPlan[temp[0]][temp[1]].meal.totalProtein)) + 
				parseInt(meal.totalProtein);
				currentPlan[temp[0]][temp[1]].meal = meal;
				db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
					this.setState({
						plan: currentPlan,
						editMode: 'init',
					})
				})
			}
			else{
				currentPlan[temp[0]].totalProtein = parseInt(currentPlan[temp[0]].totalProtein) + 
				parseInt(meal.totalProtein);
				currentPlan[temp[0]][temp[1]].meal = meal;
				db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
				this.setState({editMode: 'init'})
					this.setState({plan: currentPlan,editMode: 'init'})
				})
			}	
		}
		else {
			currentPlan[temp[0]].totalProtein = parseInt(meal.totalProtein);
			currentPlan[temp[0]][temp[1]].meal = meal;
			db.savePlanState(this.props.uid, currentPlan, this.props.currentPlan).then(()=>{
			this.setState({editMode: 'init'})
				this.setState({plan: currentPlan,editMode: 'init'})
			})
		}
		let MealName = currentPlan[temp[0]][temp[1]].meal.title;
		let dayMeal = [temp[1]];
		if(dayMeal === 'afternoonSnack'){dayMeal='afternoon snack'}
		if(dayMeal === 'morningSnack'){dayMeal='morning snack'}
		let day = findDay(temp[0] + 1);
		this.props.addNotification(MealName + ' has been added to ' + day  +  "'s " + dayMeal)
	}
	render() {
		if(this.state.editMode !== 'init' && this.state.editMode !== 'addRecipe'){
			return(<div>ERROR</div>)
		}
		let {plan} = this.state;
		let shoppingListModal = this.state.shoppingListPrompt === true;
		let isMobile = this.state.deviceLayout !== 'desktopLayout'
		let prefersnacks = this.state.preferSnacks === true;	
		let mealEditorMobileNavOpen = this.state.mealEditorMobileNavOpen === true;
		const {dailyCarbGrams, dailyFatGrams, dailyProteinGrams} = this.state.user.stats;	
		let resetPrompt = this.state.resetPrompt === true;
		if(this.state.editMode === 'init'){
			let meals = this.state.plan.map((item, key) =>
				<DailyMeals plan={this.state.plan}
					handleAddMeal={this.handleAddMeal.bind(this)}
					handleRemoveMeal={this.handleRemoveMeal.bind(this)}
					day={key}
					key={key}
					prefersnacks={prefersnacks}
					gravUrl={this.props.gravUrl}
					recipes={this.props.allRecipes}
					recipeImages={this.props.allRecipes}
					user={this.state.user}
					deviceLayout={this.state.deviceLayout}
					activeDay={this.state.activeDay}

				/>
			);
			return (
				<div>
				{shoppingListModal&&
					<div className="shoppingListModalScreen">
						<div className="saveListPrompt">
							<div className="savePromptContent">
								<h5>Confirm</h5>
								<p>This will add all ingredients from the recipes in this meal plan.</p>
								<button className="px-6 rounded-lg text-white py-2 mx-2 bg-primary" onClick={this.handleConfirmSave.bind(this)}>Continue</button>
								&emsp;
								<button className="px-6 rounded-lg text-white py-2 mx-2 bg-secondary" onClick={()=>{this.setState({shoppingListPrompt: false})}}>Cancel</button>
							</div>
						</div>
					</div>
				}
				{resetPrompt&&
					<div className="shoppingListModalScreen">
						<div className="saveListPrompt">
							<div className="savePromptContent">
								<h5>Confirm</h5>
								<p>This will delete all items in the meal plan.  Are you sure you want to continue?</p>
								<button className="px-6 rounded-lg text-white py-2 mx-2 bg-primary" onClick={this.handleResetPlan.bind(this)}>Continue</button>
								&emsp;
								<button className="px-6 rounded-lg text-white py-2 mx-2 bg-secondary" onClick={this.handleResetPrompt.bind(this)}>Cancel</button>
							</div>
						</div>
					</div>
				}
				<div className="mealEditorNavWrapper">
					{isMobile?
						<div>
							<div className="clearfix mobileMealPlanPreferenceBar">
								<span>Settings</span>
								<button className="flaticon-gear-option" onClick={this.handleMealPlanMobileNav.bind(this)}>
									<span className="hidden">Settings</span>
								</button>
							</div>
							{mealEditorMobileNavOpen&&
								<ul className="mealEditorMobileNav">
									<li>
										<SnackPreferenceSwitch uid={this.props.uid} 
											prefersnacks={prefersnacks}
											reloadUserData={this.props.reloadUserData}
											isMobile={isMobile}
										/>
									</li>	
									<li>
										<button className="px-4 mx-2 rounded-lg bg-primary font-medium text-white" onClick={this.handleAllIngredients.bind(this)}>Add to Shopping List</button>
									</li>
									<li>
										<button className="px-4 mx-2 rounded-lg bg-primary font-medium text-white" onClick={this.props.handleMealPlanNav}>All Meal Plans</button>
									</li>
									<li>
										<button className="px-4 mx-2 rounded-lg bg-primary font-medium text-white" onClick={this.handleResetPrompt.bind(this)}>Reset Plan</button>
									</li>
								</ul>
							}	
						</div>
					:
						<ul className="mealEditorNav my-4">
							<li>
								<SnackPreferenceSwitch uid={this.props.uid}  prefersnacks={prefersnacks} reloadUserData={this.props.reloadUserData} isMobile={isMobile}/>
							</li>	
							<li>
								<ReactToPrint trigger={() => <button className="rounded-lg text-xs bg-white text-secondary shadow-md border border-gray-300 px-4 py-2 font-book"><span className="flaticon-printer"></span> Print</button>}
						          content={() => this}
						        /> 
							</li>
							<li>
								<button className="rounded-lg text-xs bg-white text-secondary shadow-md border border-gray-300 px-4 py-2" onClick={this.handleAllIngredients.bind(this)}>Add to Shopping List</button>
							</li>
							<li>
								<button className="rounded-lg text-xs bg-white text-secondary shadow-md border border-gray-300 px-4 py-2" onClick={this.props.handleMealPlanNav}>All Meal Plans</button>
							</li>
							<li>
								<button className="rounded-lg text-xs bg-white text-secondary shadow-md border border-gray-300 px-4 py-2" onClick={this.handleResetPrompt.bind(this)}>Reset Plan</button>
							</li>
							<li>
								<button className="rounded-lg text-xs bg-white text-secondary shadow-md border border-gray-300 px-4 py-2 mr-2" onClick={this.makeThisActiveMealPlan.bind(this)}>Save</button>
							</li>
						</ul>
					}	
					{isMobile&&
						<ul className="daySelectorNavigation">
							<li><button className="daySelectButton" onClick={this.handleChangeActiveDay.bind(this)} id="daySelector0" data-day="0">S</button></li>
							<li><button className="daySelectButton" onClick={this.handleChangeActiveDay.bind(this)} id="daySelector1" data-day="1">M</button></li>
							<li><button className="daySelectButton" onClick={this.handleChangeActiveDay.bind(this)} id="daySelector2" data-day="2">T</button></li>
							<li><button className="daySelectButton" onClick={this.handleChangeActiveDay.bind(this)} id="daySelector3" data-day="3">W</button></li>
							<li><button className="daySelectButton" onClick={this.handleChangeActiveDay.bind(this)} id="daySelector4" data-day="4">T</button></li>
							<li><button className="daySelectButton" onClick={this.handleChangeActiveDay.bind(this)} id="daySelector5" data-day="5">F</button></li>
							<li><button className="daySelectButton" onClick={this.handleChangeActiveDay.bind(this)} id="daySelector6" data-day="6">S</button></li>
						</ul>
					}

				</div>
				<div className="mealEditorWeek rounded-lg shadow-lg border-t border-gray-200">
				
					<div className="dayBlockButton desktopLabels">
							<div className="mealEditorHeader border-t border-gray-200">
								<h5 className="weekHeading">
									Week{this.props.currentPlan}
								</h5>
							</div>
							<div className=" border-gray-200 border mealTile">
								<h5>Breakfast</h5>
							</div>
							<div className={" border-gray-200 border mealTile snackTile " + prefersnacks}>
								<h5>Morning Break</h5>
							</div>
							<div className=" border-gray-200 border mealTile">
								<h5>Lunch</h5>
							</div>
							<div className={" border-gray-200 border mealTile snackTile " + prefersnacks}>
									<h5>Afternoon Break</h5>
							</div>
							<div className=" border-gray-200 border mealTile">
								<h5>Dinner</h5>
							</div>
							<div className={" border-gray-200 border mealTile snackTile " + prefersnacks}>
								<h5>Evening Snack</h5>
							</div> 
							<div className="mealEditorFooter">
								<div style={{fontSize: '12px', padding: 0, margin: 0, textAlign: 'left'}}>
									<div className="py-1 px-2 text-1xl bg-gray-100">
										Protein:
									</div>
									<div className="py-1 px-2 text-1xl">
										Carbs:
									</div>
									<div className="py-1 px-2 text-1xl bg-gray-100">
										Fat:
									 </div>
								</div>
							</div> 
					</div>
					{plan? meals :null}
					<div className="mealPlanGoalsFooter flex items-center flex-wrap max-w-3xl mx-auto">
						<h4 className="font-bold text-secondary">Your Macro Goals (per day):</h4>
						<ul className="mealPlanGoals">
						{dailyProteinGrams&&
							<li className="text-sm"><strong>Protein</strong> {this.state.user.stats.dailyProteinGrams.toFixed(0)}<span className="text-xs">g</span></li>
						}
						{dailyCarbGrams&&
							<li className="text-sm"><strong>Carbs</strong> {this.state.user.stats.dailyCarbGrams.toFixed(0)}<span className="text-xs">g</span></li>
						}
						{dailyFatGrams&&
							<li className="text-sm"><strong>Fat</strong> {this.state.user.stats.dailyFatGrams.toFixed(0)}<span className="text-xs">g</span></li>
						}
						</ul>
						{isMobile&&
							<button className="mealPlanMobileSaveButton" onClick={this.makeThisActiveMealPlan.bind(this)}>
								Save
							</button>
						}
					</div>	
				</div>
				</div>
			);	
		}
		if(this.state.editMode === 'addRecipe'){

			let requireSaveButton = this.state.requireSaveButton;
			let hasFavorites = this.state.favorites.length > 0;
			return(
				<div className="max-w-2xl mx-auto clearfix" style={{minHeight: this.props.style.minHeight}}>
					<div className="text-right px-5">
						<button onClick={this.handleBackToEditor.bind(this)} className="rounded-lg py-2 px-4 bg-secondary text-white">Back</button>
						<UserRecipeCreator userRecipes={this.state.customRecipes} 
							uid={this.props.uid} 
							handleAddPlannedMeal={this.handleCustomRecipe.bind(this)}

						/>
					</div>
					<div className="mt-2 mb-2 p-5">
						<h4>Add one of our recipes</h4>
						<SelectSearch options={this.props.allRecipes}
							value={this.state.addTempMeal.label}
							onChange={this.handleRecipesSearch.bind(this)}
							name="search-recipes" 
							placeholder="Search for recipes/products to add to your meal plan"
						/>
					</div>
					<MealAddPeek meal={this.state.addTempMeal}
						gravUrl={this.props.gravUrl}
						handleAddMealToPlan={this.handleAddMealToPlan.bind(this)}
						uid={this.props.uid}
						requireSaveButton={requireSaveButton}
					/>
					{hasFavorites &&
						<AddFromRecipeFavorites meal={this.state.addTempMeal}
							favorites={this.state.favorites}
							uid={this.props.uid}
							gravUrl={this.props.gravUrl}
							requireSaveButton={requireSaveButton}
							handleAddMealToPlan={this.handleAddMealToPlan.bind(this)}
						/>
					}
					
				</div>
			);
		}
		if(this.state.editMode === 'reset'){
			return (<div></div>)
		}
	}
}
function mapDispatchToProps(dispatch) {
	return {
    storeUser: user => dispatch(storeUser(user)),
    addNotification: notification => dispatch(addNotification(notification)),
    removeNotification: notification => dispatch(removeNotification(notification)),
	};
}
const FavoriteButton = connect(null,mapDispatchToProps)(MealPlanEditor);
export default FavoriteButton;
