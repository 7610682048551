import React, { Component } from 'react'
import {calculateKetoMacros} from '../KetoduxCalculator/Calculator';
import { connect } from 'react-redux'
import { storeUser, storeUserId, storeRecipes, storeCalculator } from "../../redux/actions/";
import {db, storage} from '../../firebase'
import ImageUploader from 'react-images-upload';
import '../../Content/css/ketodux.css'
import requireAuth from "../Authentication/RequireAuthentication";
import {navigate} from 'gatsby';
import PageHeader from '../PageComponents/PageHeader';
import {Event} from '../Analytics/'
function mapDispatchToProps(dispatch) {
    return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
        storeCalculator: stats => dispatch(storeCalculator(stats))
    
    };
}
const mapStateToProps = state => {
    return { user: state.user, uid: state.uid};
};

const InputsConnected = ({ user, uid, calculator, storeCalculator, storeUser }) => (
<Inputs user={user} uid={uid} calculator={calculator} storeCalculator={storeCalculator} storeUser={storeUser}/>
);  
class Inputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            age: '',
            bmr: '',
            sex: '',
            weight: '',
            pounds: '',
            cm: '',
            feet: '',
            inches: '',
            height: '',
            goal: '',
            HeightFormat: 'cm',
            activityLevel: '',
            metric: true,
            suggestedCalories: '',
            value: {min: -100, max: 100},
            deficitX: -100,
            surplusX: 50,
            validate: false,
            thighInch: '',
            thighCm: '',
            abdomenInch: '',
            abdomenCm: '',
            waistInch: '',
            waistCm: '',
            pictures: [],
            dataUrls: [],
            pictureUrls: [],
            day: new Date().getDate(),
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
        }
    }
    componentDidMount() {
        if(this.props.uid.length > 0){
            if(this.props.user.stats){
                let userStats = this.props.user.stats;
                let goalInputs = document.getElementsByName('goal');
                let sexInputs = document.getElementsByName('sex');
                let activityInputs = document.getElementsByName('activityLevel');
                if(this.props.user.stats.waist && this.props.user.stats.waist > 0){
                    this.setState({waistCm: this.props.user.stats.waist, waistInch: this.props.user.stats.waist / 2.54})
                }
                if(this.props.user.stats.abdomen && this.props.user.stats.abdomen > 0){
                    this.setState({abdomenCm: this.props.user.stats.abdomen, abdomenInch: this.props.user.stats.abdomen / 2.54})
                }
                if(this.props.user.stats.thigh && this.props.user.stats.thigh > 0){
                    this.setState({thighCm: this.props.user.stats.thigh, thighInch: this.props.user.stats.thigh / 2.54})
                }
                if(sexInputs){
                    for(let i = 0; sexInputs.length > i; i++){
                        if(sexInputs[i].value === userStats.sex){
                            sexInputs[i].checked = true;
                        }
                    }
                }
                if(goalInputs){
                    for(let i = 0; goalInputs.length > i; i++){
                        if(goalInputs[i].value === userStats.weightManagementGoal){
                            goalInputs[i].checked = true
                        }
                    }
                }
                if(activityInputs){
                    for(let i = 0; activityInputs.length > i; i++){
                        if(activityInputs[i].value === userStats.activityLevel){
                            activityInputs[i].checked = true
                        }
                    }
                }
                this.setState({
                    age: userStats.age,
                    sex: userStats.sex,
                    height: userStats.height,
                    weight: userStats.weight,
                    goal: userStats.weightManagementGoal,
                    activityLevel: userStats.activityLevel
                },()=>{
                    let stats = calculateKetoMacros(
                        userStats.sex,
                        userStats.age,
                        userStats.height,
                        userStats.weight,
                        userStats.weightManagementGoal,
                        userStats.activityLevel,
                        this.state.surplusX,
                        this.state.deficitX
                    );
                    let calculatorOutputs = {...stats, ...this.state}
                    this.props.storeCalculator(calculatorOutputs)
                })
                
            }
        }
    }
    submitForm(){
        let ageInvalid = this.state.age.length === 0;
        let sexValid = this.state.sex.length === 0;
        let activityValid = this.state.activityLevel.length === 0;
        let heightInvalid = this.state.height.length === 0;
        let weightInvalid = this.state.weight.length === 0;
        let goalValid = this.state.goal.length === 0; 
        let validForm = !ageInvalid && !sexValid && !activityValid && !heightInvalid && !weightInvalid && !goalValid
        let {sex, age, height, weight, goal, activityLevel, surplusX, deficitX} = this.state;
        if(validForm){
            let stats = calculateKetoMacros(sex, age, height, weight, goal, activityLevel, surplusX, deficitX);
            let calculatorOutputs = {...stats, ...this.state}
            let timestamp = +new Date();
            let date = new Date( timestamp )
            let chartDate = this.state.year + '-' + this.state.month + '-' + this.state.day;
            Event('Progress Tracker', 'Updated Progress', '/profile/sync-progress');
            if(this.state.pictures.length > 0){
               
                this.setState({imageLoading: true})
                this.storeAllImages().then((urls)=>{
                    this.props.storeCalculator(calculatorOutputs)
                    this.setState({imageLoading: false, pictureUrls: urls})
                    db.saveUserStatsBodyMeasurements(
                        this.props.uid, 
                        calculatorOutputs,
                        this.state.day,
                        this.state.month,
                        this.state.year,
                        timestamp,
                        date, 
                        chartDate
                    ).catch((err)=>console.log(err))
                    db.saveUserProgressBodyMeasurements(this.props.uid, calculatorOutputs, this.state.day, this.state.month, this.state.year,timestamp,date, chartDate)
                    
                    .then(()=>{
                        db.storeProgressImageUrls(this.props.uid, urls, this.state.year, this.state.month, this.state.day);
                        this.refreshUser();
                    })
                })
            }
            else {
            db.saveUserStatsBodyMeasurements(
                this.props.uid, 
                calculatorOutputs,
                this.state.day,
                this.state.month,
                this.state.year,
                timestamp,
                date, 
                chartDate
            )
            .catch((err)=>console.log(err))
            db.saveUserProgressBodyMeasurements(this.props.uid, calculatorOutputs, this.state.day, this.state.month, this.state.year,timestamp,date, chartDate).then(()=>{
                this.refreshUser();
            })
            .catch((err)=>console.log(err))
            }
        }
        else {
            this.setState({ validate: true,})
        }
    }
    refreshUser(){
        db.getUserInfo(this.props.uid).then((user)=>{
            this.props.storeUser(user)
            navigate('/profile/progress-tracker')
        })
        .catch((err)=>console.log(err))
    }
    handleImageUploader(pic, url){
        this.setState({
            dataUrls: url,
            pictures: pic,
        })
    }
    storeAllImages(){
        Event('Progress Tracker', 'Added a progress photo', '/profile/sync-progress');
        return new Promise((resolve, reject) => {
            let picUrls = this.state.pictures;
            this.setState({imgLoading: true})
            let userImages = [];
            picUrls.forEach((item, key)=>{
                storage.storeProgressImage(this.props.uid, item, item.name).then((url)=>{
                    if(url){userImages.push({url: url});
                        if(userImages.length === picUrls.length){resolve(userImages)}
                    }
                })
                .catch(error=>{reject(error);})
            })
        })
    }
    changeSex({target}){
        this.setState({sex: target.value})
    }
    changeActivityLevel(e){
        this.setState({activityLevel: e.target.value});
    }
    changeGoal(e){
        this.setState({goal: e.target.value});
    }	
    
    changeHeight(e){
        this.setState({height:e.target.value});
    }
    
    changeWeight(e){
        this.setState({weight: e.target.value,})
    }
    changeWeightInPounds(e){
        let lb = e.target.value;
        let kg = lb/2.20462;
        let multiplier = Math.pow(10, 1 || 0);
        let roundedKg = Math.round(kg * multiplier) / multiplier;
        if(lb < 1400){
            this.setState({
                weight: roundedKg,
                pounds: lb,
                bmr: (this.state.height*6.25) + (kg*9.99) - (this.state.age*4.92) + 5,
            })
        }
    }
    changeConvertHeightMetric(feet, inches){
        let feetToCm = feet*30;
        let inchesToCm = inches*2.54;
        let convertedHeight = feetToCm + inchesToCm;
        this.convertHeightToMetric(convertedHeight)
    }
    convertHeightToMetric(convertedHeight){
        this.setState({height: convertedHeight})
    }
    changeWaistImperial({target}){
        this.setState({waistInches: target.value, waistCm: target.value/2.54})
    }
    changeAbdomenImperial({target}){
        this.setState({abdomenInches: target.value, AbdomenCm: target.value/2.54})
    }
    changeThighImperial({target}){
        this.setState({thighInches: target.value, thighCm: target.value/2.54})
    }
    changeWaistMetric({target}){
        this.setState({waistCm: target.value, waistInches: target.value*2.54})
    }
    changeAbdomenMetric({target}){
        this.setState({abdomenCm: target.value, abdomenInches: target.value*2.54})
    }
    changeThighMetric({target}){
        this.setState({thighCm: target.value, thighInches: target.value*2.54})
    }
    changeFeetInput(e){
        let feet = e.target.value;
        this.setState({feet: feet})
        let feetToCm = feet*30;
        let inchesToCm = this.state.inches*2.54;
        let convertedHeight = feetToCm + inchesToCm;
        this.setState({height: convertedHeight})
    }
    changeInchesInput(e){
        let inches = e.target.value;
        this.setState({inches: inches})
        let feetToCm = this.state.feet*30;
        let inchesToCm = inches*2.54;
        let convertedHeight = feetToCm + inchesToCm;
        this.setState({height: convertedHeight})
    }
    changeMeasureMetric(){
        this.setState({metric: true})
        
    }
    changeMeasureImperial(){
        this.setState({metric: false})
        if(this.state.height.length > 0){
            let cm = this.state.height;
            let floor = (cm / 30.48).toFixed(0)
            let remainder = (cm % 30.48).toFixed(0);
            let multiplier = Math.pow(10, 1 || 0);
            let inches = remainder/2.54
            let roundedInches = Math.round(inches * multiplier) / multiplier;
            this.setState({
                feet: floor,
                inches: roundedInches,
            })
        }
        if(this.state.weight.length > 0){
            let kg = this.state.weight;
            let lb = Math.floor(kg * 2.204)
            this.setState({pounds: lb})
        }
    }
    render() {
        let metric = this.state.metric === true;
        let activityInvalid = this.state.activityLevel.length === 0 && this.state.validate === true;
        let weightInvalid = this.state.weight.length === 0 && this.state.validate === true;
        let savingImages = this.state.savingImages === true;
        return (
            <main>
            <div className="grey py-10">
                <PageHeader mainHeading="Sync Macros" subHeading="track your progress"/>
                <div className="max-w-md mx-auto">
                    
                    <div className="flex flex-wrap w-full my-4">
                        <div className="px-2 w-64 mx-auto">
                            {metric?
                            <label className="block text-primary text-center">WEIGHT <i className="text-sm">(kg)</i>
                                <input type="text" value={this.state.weight} className={weightInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeWeight.bind(this)} placeholder="kg"/>
                            </label>	
                            :	
                            <label className="block text-primary text-center">WEIGHT <i className="text-sm">(lb)</i>
                                <input type="text" value={this.state.pounds} className={weightInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeWeightInPounds.bind(this)} placeholder="lb"/>
                            </label>	
                            }	
                            
                        </div>
                        
                    </div>
                    <h3 className="text-primary text-1xl my-2 text-center uppercase">Body Measurements</h3>
                    <div className="my-4">
                    {metric?
                    <div className="sm:flex w-full my-2">
                        
                        <div className="px-2">
                            <label className="block text-center text-xs text-secondary">Waist(cm)
                                <input className="formControl my-2 text-black" value={this.state.waistCm} onChange={this.changeWaistMetric.bind(this)}/>
                            </label>
                        </div>
                        <div className="px-2">
                            <label className="block text-center text-xs text-secondary">
                                Abdomen(cm)
                                <input className="formControl my-2 text-black" value={this.state.abdomenCm} onChange={this.changeAbdomenMetric.bind(this)}/>
                            </label>
                        </div>
                        <div className="px-2">
                            <label className="block text-center text-xs text-secondary">
                                Thigh(cm)
                                <input className="formControl my-2 text-black" value={this.state.thighCm} onChange={this.changeThighMetric.bind(this)}/>
                            </label>
                        </div>
                    </div>
                    :
                    <div className="sm:flex w-full my-2">
                        <div className="px-2">
                            <label className="block text-center text-xs text-secondary">
                                Waist(")
                                <input className="formControl my-2 text-black" value={this.state.waistInches} onChange={this.changeWaistImperial.bind(this)}/>
                            </label>
                        </div>
                        <div className="px-2">
                            <label className="block text-center text-xs text-secondary">
                                Abdomen(")
                                <input className="formControl my-2 text-black" value={this.state.abdomenInches} onChange={this.changeAbdomenImperial.bind(this)}/>
                            </label>
                        </div>
                        <div className="px-2">
                            <label className="block text-center text-xs text-secondary">
                                Thigh(")
                                <input className="formControl my-2 text-black" value={this.state.thighInches} onChange={this.changeThighImperial.bind(this)}/>
                            </label>
                        </div>
                    </div>
                    }
                    </div>
                    <div className="my-4 px-2 flex w-full pb-4">
                            
                        <button onClick={this.changeMeasureMetric.bind(this)} 
                            className={metric?'bg-primary py-2 mx-1 text-xs px-2 rounded-lg text-white w-6/12':'bg-white py-2 mx-1 text-xs px-2 rounded-lg text-primary w-6/12'}
                        >Metric</button>
                    
                        <button onClick={this.changeMeasureImperial.bind(this)} 
                            className={metric?'bg-white py-2 mx-1 text-xs px-2 rounded-lg text-primary w-6/12':'bg-primary py-2 mx-1 text-xs px-2 rounded-lg text-white w-6/12'}
                        >Imperial</button>
                            
                    </div>
                    <div className={activityInvalid?"pt-6 mt-4 border border-red-700 error-shadow ": " rounded-md pt-6 mt-4"}>
                        <h5 className="text-center text-primary">ACTIVITY LEVEL</h5>
                        <ul className="activityInputList md:flex flex-wrap my-4">
                            <li className="w-full md:w-6/12 flex px-2">
                                <div className="radioContainer inline-block mr-1 w-4 h-4">
                                    <input type="radio" value="Sedentary" name="activityLevel" className="radioButton" onChange={this.changeActivityLevel.bind(this)}/>
                                    <span className="radioCheckmark"></span>
                                </div> 
                                <div className="inline-block ml-4">
                                    <label htmlFor="sedentary-activity">Sedentary</label>
                                    <p className="text-xs">No regular exercise this week</p>
                                </div>
                            </li>
                            <li className="w-full md:w-6/12 flex px-2">
                                <div className="radioContainer inline-block mr-1 w-4 h-4">
                                    <input type="radio" value="Lightly Active" name="activityLevel" className="radioButton" onChange={this.changeActivityLevel.bind(this)}  id="light-activity"/>
                                    <span className="radioCheckmark"></span>
                                </div> 
                                <div className="inline-block ml-4">
                                    <label htmlFor="light-activity">Lightly Active</label>
                                    <p className="text-xs">You exercise three times this week</p>
                                </div>
                            </li>
                            <li className="w-full md:w-6/12 flex px-2">
                                <div className="radioContainer inline-block mr-1 w-4 h-4">
                                    <input type="radio"
                                        value="Moderately Active" 
                                        name="activityLevel"
                                        className="radioButton"
                                        onChange={this.changeActivityLevel.bind(this)} 
                                    />
                                    <span className="radioCheckmark"></span>
                                </div>
                                <div className="inline-block ml-4">
                                    <label htmlFor="moderate-activity">Moderately Active</label>
                                    <p className="text-xs">You exercised three to five times this week</p>
                                </div>
                            </li>
                            <li className="w-full md:w-6/12 flex px-2">
                                <div className="radioContainer inline-block mr-1 w-4 h-4">
                                    <input type="radio"value="Very Active" name="activityLevel" className="radioButton" onChange={this.changeActivityLevel.bind(this)}/>
                                    <span className="radioCheckmark"></span>
                                </div> 
                                <div className="inline-block ml-4">
                                    <label htmlFor="high-activity">Very Active</label>
                                    <p className="text-xs">You exercise five times or more thisper week</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ImageUploader withIcon={true}
                        buttonText='Upload Images'
                        onChange={this.handleImageUploader.bind(this)}
                        imgExtension={['.jpg','.png']}	
                        maxFileSize={5242880}
                        withPreview={true}

                    />
                    <div className="my-2">
                        <button onClick={this.submitForm.bind(this)} className="bg-primary text-white py-2 w-full">Sync progress</button>
                    </div>
                    {savingImages&&
                        <p>Saving Images</p>
                    }
                </div>
            </div>
            
            </main>
        )
    }
}
const ConnectedInputs = connect(mapStateToProps,mapDispatchToProps)(InputsConnected);
export default requireAuth(ConnectedInputs);