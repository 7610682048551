import React, { Component } from 'react';
import '../../../Content/css/progressTracker.css';
import UserProgressTrackingProfile from './UserProgressTrackingProfile';
import CurrentProgressStats from './CurrentProgressStats';
import PageHeader from './../../PageComponents/PageHeader.js';
import {Link, navigate} from 'gatsby';

export default class UserProgressTracker extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	noCurrentProgress: 'initial',
	  	hasNewGoals: false,
	  };
	}
	handleStartTrackingForm(){
		navigate('/profile/tell-us-about-yourself')
	}
	handleNewStats(){
		this.setState({hasNewGoals: true})
	}
	render() {
		const {Progress_Tracker} = this.props.user;
		const {hasNewGoals} = this.state;
		const showForm = this.state.noCurrentProgress === 'showForm';
		return (
			<div>
				{Progress_Tracker? 
					<React.Fragment>
						<div>
							<div className="mb-4">
							<PageHeader mainHeading="Progress Tracker" subHeading="All the stats to track your keto progress"/>
							</div>
								<UserProgressTrackingProfile user={this.props.user} uid={this.props.uid} reloadUserData={this.props.reloadUserData}/>
								<div className="ketoCalculatorPageWrapper"></div>
							</div>
							<div className="text-center">
								<Link to='/profile/sync-progress' className="py-2 rounded-lg px-6 bg-primary text-white inline-block mx-auto my-4">Update Progress</Link>
							</div>
							{
								hasNewGoals? 
								null:
								<CurrentProgressStats stats={this.props.user.stats}/>
							}
					</React.Fragment>
				:
					<div>
						<div className={"welcomeMessage " + this.state.noCurrentProgress}>
							<div>
								<PageHeader mainHeading="Progress Tracker" subHeading="All the stats to track your keto progress"/>
							</div>
							<div className="text-center py-4"><button className="py-2 rounded-lg px-6 bg-primary text-white inline-block mx-auto my-4" onClick={this.handleStartTrackingForm.bind(this)}>Get Started</button></div>
						</div>
						{showForm && <div className="ketoCalculatorPageWrapper"></div>}
					</div>
				}
			</div>
		);
	}
}
