import React, { Component } from 'react';
export default class PlanGoalKeeper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fat: 0,
			carbs: 0,
			protein: 0,
		}
	}
	sortTotals(){
		let {dinner, lunch, breakfast, morningSnack, afternoonSnack} = this.props.plan
		const reducer = (accumulator, currentValue) => accumulator + currentValue;
		let carbs = [
			0,
			parseInt(breakfast.meal.carbs),
			parseInt(morningSnack.meal.carbs),
			parseInt(afternoonSnack.meal.carbs),
			parseInt(dinner.meal.carbs),
			parseInt(lunch.meal.carbs),
		]
		let fat = [
			0,
			parseInt(morningSnack.meal.fat),
			parseInt(lunch.meal.fat),
			parseInt(breakfast.meal.fat),
			parseInt(afternoonSnack.meal.fat),
			parseInt(dinner.meal.fat)
		]
		let protein = [
			0,
			parseInt(breakfast.meal.protein),
			parseInt(morningSnack.meal.protein),
			parseInt(lunch.meal.protein),
			parseInt(afternoonSnack.meal.protein),
			parseInt(dinner.meal.protein),
		]
		
		let carbTotals = carbs.filter(Number),
		fatTotals = fat.filter(Number),
		proteinTotals = protein.filter(Number),
		totalFat = 0,
		totalCarbs = 0,
		totalProtein = 0;
		if(fatTotals.length > 0) { totalFat = fatTotals.reduce(reducer)}
		if(carbTotals.length > 0){ totalCarbs = carbTotals.reduce(reducer)}
		if(proteinTotals.length > 0){ totalProtein = proteinTotals.reduce(reducer)}
		this.setState({
			fat: totalFat,
			carbs: totalCarbs,
			protein: totalProtein
		})
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props){
			this.sortTotals();
		}
	}
	render() {
		let {fat, protein, carbs} = this.state;
		
		return (
			<div>
				<div className="text-center totalsContent">
					<div className="py-1 px-2 bg-gray-100">{protein?<p>{protein}g</p>:<p>0 g</p>}</div>
					<div className="py-1 px-2">{carbs?<p>{carbs}g </p>:<p>0 g</p>}</div>
					<div className="py-1 px-2 bg-gray-100">{fat?<p>{fat}g</p>:<p>0 g</p>}</div>
				</div>
			</div>
		);
	}
}
