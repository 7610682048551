import React, {Component} from "react";
import {
  storeUser,
  storeUserId,
  storeRecipes,
  addNotification,
  setStarAdmin,
  storeFirebase
} from "../redux/actions/";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import firebase from 'gatsby-plugin-firebase';
import Journal from '../components/User/Journal/JournalPage.js';
import ShoppingList from '../components/User/ShoppingList/ShoppingList.js';
import FavoriteRecipes from '../components/User/Recipes/FavoriteRecipes.js';
import ProgressTracker from '../components/User/ProgressTracker/ProgressTracker.js';
import MealPlans from '../components/User/MealPlans/MealPlansPage.js';
import Dashboard from '../components/User/Profile/ProfilePage.js';
import SyncProgress from '../components/SyncProgress/SyncProgress';
import {db} from '../firebase';
import {connect} from "react-redux";
import ProductRecipes from '../components/User/MealPlans/ProductRecipes';
import TellUsAboutYourself from '../components/KetoduxCalculator/TellUsAboutYourselfPage.js'

function mapDispatchToProps(dispatch) {
  return {
    storeUser: user => dispatch(storeUser(user)),
    storeRecipes: recipes => dispatch(storeRecipes(recipes)),
    storeUserId: id => dispatch(storeUserId(id)),
    addNotification: notification => dispatch(addNotification(notification)),
    setStarAdmin: admin => dispatch(setStarAdmin(admin)),
    storeFirebase: firebase => dispatch(storeFirebase(firebase))
  };
}
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: '',
      recipes: [],
    }
  }
  componentDidMount() {
    let allRecipes = this.props.pageContext.recipes.pages;
    const products = ProductRecipes.products;
    const index = allRecipes.length;
    if(products){
      for(let i=0; products.length > i; i++){
        products[i].index = index + 1 + i;
        products[i].value = index + 1 + i;
        allRecipes.push(products[i]);
      }
    }
    this.setState({
      recipes: allRecipes,
    })
    firebase.auth().onAuthStateChanged((authUser)=>{
      this.setState({authUser})
      if(!authUser){
        navigate('/login')
      }
      else {
        db.getUserInfo(authUser.uid)
        .then((user)=>{
          this.props.storeUser(user)
        })
      }
    })
  }
  render() {
    const {authUser} = this.state;
    return (
      <React.Fragment>
      {authUser&&
      <Router basepath="/profile">
          <Dashboard path="/" default/>
          <Journal path="/daily-journal" recipePages={this.state.recipes}/>
          <FavoriteRecipes path="/favorite-recipes" recipePages={this.state.recipes}/>
          <ShoppingList path="/shopping-list"/>
          <MealPlans recipes={this.state.recipes} content={this.props.pageContext.content} path="/meal-plans"/>
          <ProgressTracker path="/progress-tracker"/>
          <SyncProgress path="/sync-progress"/>
          <TellUsAboutYourself path="/tell-us-about-yourself"/>
      </Router>
      }
      </React.Fragment>
    )
  }
}

const ConnectedProfile = connect(null, mapDispatchToProps)(Profile);
export default ConnectedProfile;

