import React, { Component } from 'react';

export default class JournalFormNav extends Component {
	constructor(props){
		super(props);
		this.state = {
			width: window.innerWidth,
			mobileJournalNav: false,
			navClass: 'journalNav',
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount(){
		this.setState({
			width: window.innerWidth,
		})
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions);
	}
	handleJournalMobileNav(){
		if(this.state.mobileJournalNav === true){
			this.setState({
				mobileJournalNav: false,
			})
		}
		else {
			this.setState({
				mobileJournalNav: true,
			})
		}
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  if(window.innerWidth > 767){
	  	this.setState({
	  		navClass: 'journalNav',
	  		width: window.innerWidth, height: window.innerHeight 
	  	})
	  }
	  else {
	  	this.setState({
	  		navClass: 'journalNav mobileJournalNav',
	  		width: window.innerWidth, height: window.innerHeight 
	  	})
	  }
	}
	handleJournalSection(e){
		let buttons = document.getElementsByName('tabbutton');
		for(let i=0; i < buttons.length; i++){
			buttons[i].setAttribute('active', false)
		}
		let button = e.target;
		let value = button.getAttribute('data-value');
		this.props.handleJournalSection(value);
		button.setAttribute('active', true);
		this.props.handleContinueEdit();
	}
	
	render() {
		let largeScreen = this.state.width > 767;
		return (
			<div>
				<div>
					{largeScreen?
					<ul className={this.state.navClass} data-menu-open={this.state.mobileJournalNav}>
						<li><button name="tabbutton" data-value="morning" onClick={this.handleJournalSection.bind(this)} active="true">Morning</button></li>
						<li><button name="tabbutton" data-value="exercise" onClick={this.handleJournalSection.bind(this)}>Exercise</button></li>
						<li><button name="tabbutton" data-value="meals" onClick={this.handleJournalSection.bind(this)}>Meals</button></li>
						<li><button name="tabbutton" data-value="snacks" onClick={this.handleJournalSection.bind(this)}>Snacks</button></li>
						<li><button name="tabbutton" data-value="drinks" onClick={this.handleJournalSection.bind(this)}>Drinks</button></li>
					</ul>
					:
					<ul className={this.state.navClass} data-menu-open={this.state.mobileJournalNav}>
						<li>
							<button className="flaticon-sunset" name="tabbutton" data-value="morning" onClick={this.handleJournalSection.bind(this)} active="true">
							
							</button>
						</li>
						<li>
							<button className="flaticon-trainers" name="tabbutton" data-value="exercise" onClick={this.handleJournalSection.bind(this)}>
							
							</button>
						</li>
						<li>
							<button className="flaticon-food" name="tabbutton" data-value="meals" onClick={this.handleJournalSection.bind(this)}>
							
							</button>
						</li>
						<li>
							<button className="flaticon-cupcake-dessert" name="tabbutton" data-value="snacks" onClick={this.handleJournalSection.bind(this)}>
							
							</button>
						</li>
						<li>
							<button className="flaticon-vegetables-juice" name="tabbutton" data-value="drinks" onClick={this.handleJournalSection.bind(this)}>
							
							</button>
						</li>
					</ul>
					}
				</div>
			</div>
		);
	}
}
