import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import { SRLWrapper } from "simple-react-lightbox";
import LightBox from "simple-react-lightbox";
const lightbox = {
	overlayColor: "rgba(0,0,0,0.9)",
	captionColor: "#0094c9",
	captionFontSize: "0",
	captionFontWeight: "300",
	captionFontStyle: "capitalize",
	buttonsBackgroundColor: "transparent",
	buttonsIconColor: "rgba(255,255,255, 0.9)",
	autoplaySpeed: 1500,
	transitionSpeed: 900,
};
export default class ProgressTrackerChart extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	stats: this.props.stats,
	  	elements: [],
	  	chartWidth: 400,
	  	chartheight: 300,
	  	style: {},
	  	previewImages:[],
	  	ticks: 5,
	  	minimum: 0,
	  	maximum: 0,
		  yLabel: "KG",
		chartData: [],
		dates: [],
		gallery: [],
		stage: 'weight',
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props !== prevProps){
			this.setState({stats: this.props.stats})
			this.handleChartData();
		}
	}
	
	componentDidMount(){
		this.setState({
			stats: this.props.stats
		})
		this.handleChartData();
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	handleChartDynamics(elements){
		let max = 0,
		min = 0;
		for(let i = 0; elements.length > i; i++){
			if(max < (parseInt(elements[i].y) + 50) || min < (parseInt(elements[i].y) - 50)){
				max = (parseInt(elements[i].y) + 50);
				min = (parseInt(elements[i].y) - 50);
			}
		}
		this.setState({minimum: min,maximum: max})
	}
	handleChartData(){
		let elements = [];
		let dates = [];
		Object.keys(this.state.stats).map((yearName, i) => (
			Object.keys(this.state.stats[yearName]).map((monthName, i) => (
				Object.keys(this.state.stats[yearName][monthName]).map((dayName, i) => (	
					elements.push({
						y: parseFloat(this.state.stats[yearName][monthName][dayName].weight + 'kg'), 
						x: new Date(Date.UTC(yearName,(parseInt(monthName)-1),dayName)).toLocaleDateString("en-UK")
					})
				))
			))
		))
		Object.keys(this.state.stats).map((yearName, i) => (
			Object.keys(this.state.stats[yearName]).map((monthName, i) => (
				Object.keys(this.state.stats[yearName][monthName]).map((dayName, i) => (	
					dates.push(new Date(Date.UTC(yearName,(parseInt(monthName)-1),dayName)).toLocaleDateString("en-UK"))
				))
			))
		))
		let images = [];
		Object.keys(this.state.stats).map((yearName, i) => (
			Object.keys(this.state.stats[yearName]).map((monthName, i) => (
				Object.keys(this.state.stats[yearName][monthName]).map((dayName, i) => (
					images.push({
						images: this.state.stats[yearName][monthName][dayName].images,
						date: `${dayName}/${monthName}/${yearName}`
					})	
				
				))
			))
		))
		const imageMap = images.map((item)=>{
			if(item.images){
				return item
			}
			return null	
		})
		let maps = imageMap.filter(Boolean)
		this.setState({
			chartData: elements,
			dates: dates,
			gallery: maps,
		})
	}
	changeStage({target}){
		this.setState({stage: target.getAttribute('data-stage')});
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
		let wrapper = document.getElementById('progressTrackerChartWrapper')
		if(window.innerWidth > 400){
			this.setState({
				chartWidth: wrapper.offsetWidth -30,
				yLabel: "KG",
				chartHeight: 400,
			})
		}
		else {
			this.setState({
				chartWidth: wrapper.offsetWidth,
				yLabel: false,
				chartHeight: 300,
			})
		}
		this.setState({
			width: window.innerWidth, 
			ticks: window.innerWidth/130, 
			height: window.innerHeight 
		}) 
	}
	handlePointClick(e, point){
		if(point.images){
			this.setState({
				previewImages: point.images,
				tooltipWeight: point.y,
				tooltipDate: point.x,
			})	
		}
		else{
			this.setState({
				previewImages: [],
				tooltipWeight: point.y,
				tooltipDate: point.x,
			})
		}
	}

	render() {
		let hasPhotos = this.state.gallery.length > 0;
		const data = {
			labels: this.state.dates,
			datasets: [
			  {
				label: 'Weight Loss Progress',
				fill: true,
				lineTension: 0.5,
				backgroundColor: 'rgba(241,91,41,0.4)',
				borderColor: 'rgba(241,91,41,1.0)',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(241,91,41,1.0)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(241,91,41,1.0)',
				pointHoverBorderColor: 'rgba(77,89,113,1.0)',
				pointHoverBorderWidth: 2,
				pointRadius: 5,
				pointHitRadius: 5,
				data: this.state.chartData,
			  }
			]
		  };
        let previewImages = this.state.previewImages.map((item, key)=>
        	<img src={item.url} alt=""/>
		)

		let {tooltipWeight, tooltipDate} = this.state;
		let options = {
			title: {text: "This is a test"},
			scales: {
				xAxes: [{
					title: "time",
					type: 'time',
					gridLines: {
						lineWidth: 0
					},
					time: {
						unit: "day",
						unitStepSize: 50,
						displayFormats: {
							millisecond: 'MMM DD',
							second: 'MMM DD',
							minute: 'MMM DD',
							hour: 'MMM DD',
							day: 'MMM DD',
							week: 'MMM DD',
							month: 'MMM DD',
							quarter: 'MMM DD',
							year: 'MMM DD',
						}
					}
				}]
			}
		}
		let gallery = this.state.gallery.map((item, key)=>
			<div className="px-2 md:w-3/12 my-4 text-center" key={key}>
				{item.images.map((item, key)=>{
					return(
						<a href={item.url} data-attribute="SRL" key={key}>
							<img src={item.url} alt="" className="block border border-gray-200 rounded-lg shadow-sm overflow-hidden" />
						</a>
					)
				})}
				<p>{item.date}</p>
			</div>
		)
		let photosActive = this.state.stage === 'photos';
		let weightActive = this.state.stage === 'weight';
		//let measurementsActive = this.state.stage === 'measurements';
		return (
			<>
			<div className="mb-4 mt-8">
				<button className={weightActive?"text-sm rounded-lg py-2 px-4 bg-primary text-white border border-primary mr-2":"text-sm rounded-lg py-2 px-4 bg-white text-primary border border-gray-200 mr-2" }
					onClick={this.changeStage.bind(this)} data-stage="weight">Weight Loss Progress</button>
				<button className={photosActive?"text-sm rounded-lg py-2 px-4 bg-primary text-white border border-primary mr-2":"text-sm rounded-lg py-2 px-4 bg-white text-primary border border-gray-200 mr-2"}
					onClick={this.changeStage.bind(this)} data-stage="photos">Photos</button>
				{/* <button 
					className={measurementsActive?"text-sm rounded-lg py-2 px-4 bg-primary text-white border border-primary mr-2":"text-sm rounded-lg py-2 px-4 bg-white text-primary border border-gray-200 mr-2"}
					onClick={this.changeStage.bind(this)} data-stage="measurements">Body Measurements</button> */}
			</div>
			{weightActive&&
				<div className="shadow-lg px-4 bg-white rounded-lg">
					<Line data={data} options={options}/>
					<div className="chartTooltip text-center flex">
						<div className="tooltipImages">{previewImages}</div>
						{tooltipWeight&& <div className="text-xs">Recorded Weight: <strong className="text-secondary font-medium">{this.state.tooltipWeight}kg</strong></div> }
						{tooltipDate&& <div className="text-xs">Recorded Date:<strong className="text-secondary font-medium"> {this.state.tooltipDate}</strong></div> }
					</div>
				</div>
			}
			{photosActive&&
				<div className="shadow-lg px-4 bg-white rounded-lg relative z-50 py-4">
					{hasPhotos?
					<LightBox>
						<SRLWrapper options={lightbox}>
							<div className="sm:flex flex-wrap w-full">{gallery}</div>	
						</SRLWrapper>
					</LightBox>
					:
					<div className="flex h-40 py-20 w-full text-center mx-auto items-center">
						<div className="w-full">
							<h2 className="text-secondary font-medium text-2xl">No Photos found</h2>
							<p>You can add photos of yourself to track your progress</p>
						</div>	
					</div>	
					}
				</div>
			}
			</>
		);
	}
}
