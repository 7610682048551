import React, { Component } from 'react';
import {db} from '../../../firebase/';
import {Link} from 'gatsby';
import '../../../Content/css/recipes.css'
import { connect } from "react-redux";
import { storeUser, storeUserId, storeRecipes } from "../../../redux/actions/";
let gravURL = process.env.REACT_APP_GRAV_URL;
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
    
	};
}
class MyFavoriteRecipes extends Component {
	constructor(props){
		super(props);
		this.state = {
			favoritesRecipes: [],
			myRecipes: [],
			myFavorites: [],
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props !== prevProps){
			this.sortFavorites()
		}
	}
	reloadUserData(){
		db.getUserInfo(this.props.uid)
		.then((userData)=>{
			this.props.storeUser(userData)	
		})
	}
	handleFavorite(e){
		let button = e.target;
		let title = button.getAttribute('data-title')
		db.unFavoriteRecipe(this.props.uid, title)
		.then(()=>{
			this.reloadUserData();
		})
	}
	componentDidMount(){
		this.sortFavorites();
	}
	sortFavorites(){
		if(this.props.user.Favorite_Recipes){
			let favorites = Object.keys(this.props.favorites).map((item)=>{
				if(this.props.favorites[item].urlId){
					return this.props.favorites[item].urlId;
				}
				return null;
			}),
			recipes = this.props.allRecipes; 
			if(recipes.length > 0 && favorites.length > 0){
				let favs = favorites.map(id => recipes.find(o => o.route === id)).filter(Boolean)
				this.setState({
					myFavorites: favs,
				})
			}
		}
	}
	render() {
		let favoritesList = this.state.myFavorites.map((item, key)=>
			<div className="lg:w-3/12 sm:w-6/12 md:sm-4/12 xs:w-6/12 w-6/12 px-2 favoriteRecipeTile" key={key}>
				<div>
					<Link to={item.link.link} className="block bg-gray-200"> 
						<img src={gravURL + item.thumbnail} alt={item.header.title} width="600px" height="600px"/>
					</Link>
					<h6>{item.header.title}</h6>
					<button className="button darkButton" onClick={this.handleFavorite.bind(this)} data-title={item.title}>Remove</button>
				</div>
			</div>
		);
		let hasFavorites = this.state.myFavorites.length > 0
		return (
			<div className="flex flex-wrap">
				{hasFavorites?
					<>{favoritesList}</>
					:
					<div className="text-center max-w-md mx-auto my-10">
					<p>No Favorites were found.<br/>You can see all recipes here: <Link to="/recipes">Recipes</Link></p>
					</div>
				}

			</div>
				
		);
		
	}
}

const FavoriteRecipes = connect(
	null,
	mapDispatchToProps
  )(MyFavoriteRecipes);
  
export default FavoriteRecipes;

