import React from 'react';
let gravURL = process.env.REACT_APP_GRAV_URL;
const MealPlansList = (props) => {
let mealPlans = props.mealPlans.map((item, key)=>
			<MealPlanTile 
				user={props.user}
				name={item.name}
				pdfUrl={gravURL + item.pdf}
				imgUrl={gravURL + item.img}
				unlocked={item.unlocked}
				key={key}
			/>	
		);
		return (
			<section>
				<div className="pageGrid">
					<div className="md:flex flex-wrap">{mealPlans}</div>
				</div>
			</section>
		);
}
export default MealPlansList;

const MealPlanTile = (props) => {
	let unlocked = props.unlocked === '0' || props.user === true;
		return (
			<>
				{unlocked?
				<div className="w-6/12 p-5 text-center freeMealPlanTile">
					<h2 className="subHeading">
						{props.name}
					</h2>
					<img src={props.imgUrl} alt={props.name}/>
					<a href={props.pdfUrl} download={props.name} className="button darkButton">Download</a>
				</div>
				:null}
			</>
	);
}



