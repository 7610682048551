const images = `/uploads/images/meal-plans/products`;
const Products = {
    products:[
        {
            carbs: "9.4",
            value: "Alpha Lipid™ Lifeline™",
            recipe: {
                totalCarbs: "9.4",
                totalFat: "0.05",
                totalProtein: "2.6",
                method: `<p>Support your immunity with 1600mg of Colostrum* containing 300mg of Immunoglobulins.<br/>
            Improve your gut health & digestive comfort with 1 billion probiotics per dose.<br/>
            Contains essential vitamins and minerals.<br/>
            1,000mg of calcium for healthy teeth and bones.<br/>
            Assists the body’s natural repair process.<br/>
            Best taken as a pre-breakfast cold drink or include it with your morning smoothie.<br/>
            Defence:Colostrum’s natural antibodies build immunity through gut health.<br/>
            Energy:Probiotics and proteins aid digestion, helping you feel more energised and balanced.<br/>
            Focus:When you have the right balance of nutrients and a healthy digestive system to absorb them, your body and mind are naturally healthier and more focussed.<br/>
            Alpha Lipid™ Lifeline™ breakfast drink combines colostrum and probiotics for powerful immune and digestive system support to help you feel balanced, healthy and strong.<br/></p>`
            },
            content: "",
            cropped: `${images}/cropped/lifeline.jpg`,
            croppedImage: `${images}/cropped/lifeline.jpg`,
            croppedImg: `${images}/cropped/lifeline.jpg`,
            croppedSquare: `${images}/lifeline.jpg`,
            fat: "0.05",
            id: "",
            label: "Alpha Lipid™ Lifeline™",
            link: {link: ""},
            mediumSquare: `${images}/lifeline.jpg`,
            picture: `${images}/lifeline.jpg`,
            protein: "2.6",
            thumbnail: `${images}/lifeline.jpg`,
            title: "Alpha Lipid™ Lifeline™",
            name: "Alpha Lipid™ Lifeline™",
            totalCarbs: "9.4",
            totalFat: "0.05",
            totalProtein: "2.6",
            searchString: "Alpha Lipid Lifeline"
        },
        {
            carbs: "0.8",
            value: "Alpha Lipid™ Ultra Diet 2™",
            recipe: {
                totalCarbs: "0.8",
                totalFat: "0.12",
                totalProtein: "10",
                method: `<p>Low carb, high satisfaction!<br/>
                Great tasting protein & vitamin shake.<br/>
                Provides 40 grams of protein per day – up to 66% of daily requirement.<br/>
                22 essential vitamins & minerals.<br/>
                Supports balanced blood sugar, helping to reduce cravings.<br/>
                Feel full without lots of calories.<br/>
                Protein:Packed full of protein to fill and fuel your body naturally.<br/>
                Ease:Simple to use with our weight management plan, making a healthy lifestyle easier.<br/>
                Control:Supports blood sugar balance so your body works for you!<br/>
                </p>`
            },
            content: "",
            cropped: `${images}/cropped/ud2.jpg`,
            croppedImage: `${images}/cropped/ud2.jpg`,
            croppedImg: `${images}/cropped/ud2.jpg`,
            croppedSquare: `${images}/ud2.jpg`,
            fat: "0.05",
            id: "",
            label: "Alpha Lipid™ Ultra Diet 2™",
            link: {link: ""},
            mediumSquare: `${images}/ud2.jpg`,
            picture: `${images}/ud2.jpg`,
            protein: "2.6",
            thumbnail: `${images}/ud2.jpg`,
            title: "Alpha Lipid™ Ultra Diet 2™",
            name: "Alpha Lipid™ Ultra Diet 2™",
            totalCarbs: "0.8",
            totalFat: "0.12",
            totalProtein: "10",
            searchString: "Alpha Lipid™ Ultra Diet 2™, UDII"
        },
        {
            carbs: "0.8",
            value: "Alpha Lipid™ SDII™",
            recipe: {
                totalCarbs: "0.8",
                totalFat: "0.12",
                totalProtein: "10",
                method: `<p>Low carb, high satisfaction!<br/>
                Great tasting protein & vitamin shake.<br/>
                Provides 40 grams of protein per day – up to 66% of daily requirement.<br/>
                22 essential vitamins & minerals.<br/>
                Supports balanced blood sugar, helping to reduce cravings.<br/>
                Feel full without lots of calories.<br/>
                Protein:Packed full of protein to fill and fuel your body naturally.<br/>
                Ease:Simple to use with our weight management plan, making a healthy lifestyle easier.<br/>
                Control:Supports blood sugar balance so your body works for you!<br/>
                </p>`
            },
            content: "",
            cropped: `${images}/cropped/sd2.jpg`,
            croppedImage: `${images}/cropped/sd2.jpg`,
            croppedImg: `${images}/cropped/sd2.jpg`,
            croppedSquare: `${images}/sd2.jpg`,
            fat: "0.05",
            id: "",
            label: "Alpha Lipid™ SDII™",
            link: {link: ""},
            mediumSquare: `${images}/sd2.jpg`,
            picture: `${images}/sd2.jpg`,
            protein: "2.6",
            thumbnail: `${images}/sd2.jpg`,
            title: "Alpha Lipid™ SDII™",
            name: "Alpha Lipid™ SDII™",
            totalCarbs: "0.8",
            totalFat: "0.12",
            totalProtein: "10",
            searchString: "Alpha Lipid™ SDII™, SD2"
        },
        {
            carbs: "0.99",
            value: "FibreMax",
            recipe: {
                totalCarbs: "0.99",
                totalFat: "0.03",
                totalProtein: "0.5",
                method: `<p>Your detoxing superhero.<br/>
                One serve provides 40% of your recommended daily fibre*.<br/>
                100% natural, supports healthy gut & digestive comfort.<br/>
                Blend of soluble and insoluble plant fibres.<br/>
                Insoluble fibre cleans your intestines.<br/>
                Soluble fibre makes you feel fuller longer.<br/>
                This exclusive combination assists toxins to pass more quickly through the stomach & intestines.<br/>
                Regularity:Cleans and detoxifies the digestive tract plus regulates glucose.<br/>
                Energy:Helps prevent digestive sluggishness, giving you more energy and stamina.<br/>
                Goodness:Natural fibre, vitamins, minerals and proteins.</p>`
            },
            content: "",
            cropped: `${images}/cropped/fibremax.jpg`,
            croppedImage: `${images}/cropped/fibremax.jpg`,
            croppedImg: `${images}/cropped/fibremax.jpg`,
            croppedSquare: `${images}/fibremax.jpg`,
            fat: "0.05",
            id: "",
            label: "FibreMax",
            link: {link: ""},
            mediumSquare: `${images}/fibremax.jpg`,
            picture: `${images}/fibremax.jpg`,
            protein: "2.6",
            thumbnail: `${images}/fibremax.jpg`,
            title: "FibreMax",
            name: "FibreMax",
            totalCarbs: "0.99",
            totalFat: "0.03",
            totalProtein: "0.5",
            searchString: "FibreMax"
        },
        {
            carbs: "4.3",
            value: "ShapeUp Berries",
            recipe: {
                totalCarbs: "4.3",
                totalFat: "2.2",
                totalProtein: "20",
                method: `<p>Nutritious as well as delicious.<br/>
                It’s quick, convenient and easy – making it a healthy, filling option instead of unhealthy snacks or meals.<br/>
                This super shake is a great source of protein and low in carbohydrates. Delicious Berries & Cream or indulgent Chocolate Fudge.<br/>
                22 Essential vitamins & minerals just a shake away.<br/>
                Packed full of protein & antioxidants.<br/>
                The healthy answer to “what shall I eat?”<br/>
                Fuel:Low in carbohydrates, high in protein for fullness and energy.<br/>
                Indulgence:Two delicious flavours - Berries & Cream and Chocolate Fudge.<br/>
                Transformation:Part of the New ImageTM weight management plan, ShapeUp helps get you in shape and stay there.</p>`
            },
            content: "",
            cropped: `${images}/cropped/shb.jpg`,
            croppedImage: `${images}/cropped/shb.jpg`,
            croppedImg: `${images}/cropped/shb.jpg`,
            croppedSquare: `${images}/shb.jpg`,
            fat: "0.05",
            id: "",
            label: "ShapeUp Berries",
            link: {link: ""},
            mediumSquare: `${images}/shb.jpg`,
            name: "ShapeUp Berries",
            title: "ShapeUp Berries",
            picture: `${images}/shb.jpg`,
            protein: "2.6",
            thumbnail: `${images}/shb.jpg`,
            totalCarbs: "4.3",
            totalFat: "2.2",
            totalProtein: "20",
            searchString: "ShapeUp Berries"
        },
        {
            carbs: "3.9",
            value: "ShapeUp Chocolate",
            recipe: {
                totalCarbs: "3.9",
                totalFat: "2",
                totalProtein: "20.5",
                method: `<p>Nutritious as well as delicious.<br/>
                It’s quick, convenient and easy – making it a healthy, filling option instead of unhealthy snacks or meals.<br/>
                This super shake is a great source of protein and low in carbohydrates. Delicious Berries & Cream or indulgent Chocolate Fudge.<br/>
                22 Essential vitamins & minerals just a shake away.<br/>
                Packed full of protein & antioxidants.<br/>
                The healthy answer to “what shall I eat?”<br/>
                Fuel:Low in carbohydrates, high in protein for fullness and energy.<br/>
                Indulgence:Two delicious flavours - Berries & Cream and Chocolate Fudge.<br/>
                Transformation:Part of the New ImageTM weight management plan, ShapeUp helps get you in shape and stay there.</p>`
            },
            content: "",
            cropped: `${images}/cropped/shc.jpg`,
            croppedImage: `${images}/cropped/shc.jpg`,
            croppedImg: `${images}/cropped/shc.jpg`,
            croppedSquare: `${images}/shc.jpg`,
            fat: "0.05",
            id: "",
            label: "ShapeUp Chocolate",
            link: {link: ""},
            mediumSquare: `${images}/shc.jpg`,
            name: "ShapeUp Chocolate",
            title: "ShapeUp Chocolate",
            picture: `${images}/shc.jpg`,
            protein: "2.6",
            thumbnail: `${images}/shc.jpg`,
            totalCarbs: "3.9",
            totalFat: "2",
            totalProtein: "20.5",
            searchString: "ShapeUp Chocolate"
        },
    ]
}
export default Products