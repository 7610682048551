import React from 'react';
export default class MealPlanSelectorButton extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			daysWithMeals: [],
		}
	}

	handleEditMealPlan(){
		 this.props.editWeek(this.props.week)
	}
	handleSortMealsInPlan(){
		let plan = this.props.plan.plan;
		let daysWithMeals = [];

		for(let i = 0; plan.length > i; i++){
			if(plan[i].breakfast.meal.title){
				if(plan[i].breakfast.meal.title.length > 0){
					daysWithMeals.push(plan[i].breakfast.meal.title)
				}
			}
			if(plan[i].lunch.meal.title){
				if(plan[i].lunch.meal.title.length > 0){
					daysWithMeals.push(plan[i].lunch.meal.title)
				}
			}
			if(plan[i].dinner.meal.title){
				if(plan[i].dinner.meal.title.length > 0){
					daysWithMeals.push(plan[i].dinner.meal.title)
				}
			}
		}
		this.setState({
			daysWithMeals: daysWithMeals,
		})
	}
	componentDidMount(){
		this.handleSortMealsInPlan()

	}
	render() {	
		return (
			<div>
				<div className="mealSelectorPlanContent">
					<h2>Meal Plan</h2>
					<h5>Week {this.props.week}</h5>
					<button onClick={this.handleEditMealPlan.bind(this)}
						className="rounded-lg py-2 px-4 text-gray-700 border-gray-700 border bg-white"
					>
						Edit Meal Plan
					</button>
				</div>
			</div>
		);
	}
}
