import React, { Component } from 'react';
import Html from 'react-html-parser';
import {Link} from 'gatsby'
import ReactToPrint from 'react-to-print'
export default class UserShoppingList extends Component {
	constructor(props){
		super(props);
		this._isMounted = false;
		this.state = {
			shoppingList: [],
			bakery: [],
      baking: [],
      canned: [],
      dairy: [],
      frozen: [],
      fruitandveg: [],
      meat: [],
      staples: [],
		}
	}
	componentDidMount(){	
		this.setState({
			shoppingList: this.props.shoppingList,
		})
		this.sortCategories(this.props.shoppingList)

	}
	sortCategories(list){
		let bakery=list.filter((x)=>x.category === 'bakery');
  	let baking=list.filter((x)=>x.category === 'baking');
  	let canned=list.filter((x)=>x.category === 'canned goods');
  	let dairy=list.filter((x)=>x.category === 'dairy');
  	let frozen=list.filter((x)=>x.category === 'frozen');
  	let fruitandveg=list.filter((x)=>x.category === 'fruitandveg');
  	let meat=list.filter((x)=>x.category === 'meat');
  	let staples=list.filter((x)=>x.category === 'staples');
		this.setState({
			bakery,
      baking,
      canned,
      dairy,
      frozen,
      fruitandveg,
      meat,
      staples,
		})
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps !== this.props){
			this.sortCategories(this.props.shoppingList)
			this.setState({
				shoppingList: this.props.shoppingList,
			})
		}
	}
	
	handleRemoveItem(e){
		let item = e.target.getAttribute('data-item');
		this.props.handleRemoveItem(item);
	}
	handleTickItem(e){
		let item = e.target.getAttribute('data-item');
		this.props.handleTickItem(item);
	}
	render() {
		let hasList = 
			this.state.meat.length > 0 ||
			this.state.bakery.length > 0 ||
			this.state.canned.length > 0 ||
			this.state.staples.length > 0 ||
			this.state.baking.length > 0 ||
			this.state.frozen.length > 0 ||
			this.state.dairy.length > 0;


		const {fruitandveg, meat, bakery, canned, staples, baking, frozen, dairy,gravContent} = this.state
		return (
			<div className={hasList?"max-w-2xl mx-auto shadow-lg mb-10 rounded-lg p-5 bg-white": ""}>
				{hasList&&
				<div className="text-right mt-3 mb-3">
					<div>
						<ReactToPrint
				      trigger={() => 
								<button className="bg-primary text-white rounded-lg py-2 px-4 inline-block mr-4">
									<span className="flaticon-printer"></span> Print Shopping List
								</button>
							}
				    	content={() => this.componentRef}
				  	/> 
						<button className="rounded-lg py-2 px-4 bg-secondary text-white" onClick={this.props.resetList}>Reset List</button>
			      </div>
		        </div>
		        }
				<ShoppingListSection
					name="Fruit and Vegetables"
					items={this.state.fruitandveg}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
				<ShoppingListSection
					name="Meat"
					items={this.state.meat}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
				<ShoppingListSection
					name="Bakery"
					items={this.state.bakery}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
				<ShoppingListSection
					name="Canned Goods"
					items={this.state.canned}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
				<ShoppingListSection
					name="Staples"
					items={this.state.staples}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
				<ShoppingListSection
					name="Baking Goods"
					items={this.state.baking}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
				<ShoppingListSection
					name="Frozen Goods"
					items={this.state.frozen}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
				<ShoppingListSection
					name="Dairy"
					items={this.state.dairy}
					handleRemoveItem={this.handleRemoveItem.bind(this)}
					handleTickItem={this.handleTickItem.bind(this)}
					recipeContent={gravContent}
				/>
					
				
		    <div style={{display: 'none'}}>
					<PrintShoppingList
						recipe={this.props.recipe} 
						method={this.props.method}
						fruitandveg={fruitandveg}					
						meat={meat}
						bakery={bakery}
						canned={canned}
						staples={staples}
						baking={baking}
						frozen={frozen}
						dairy={dairy}
						ref={el => (this.componentRef = el)}
							
					/> 
				</div>
			</div>
		);
	}
}

export class ShoppingListSection extends React.Component {
	render() {
		let items = this.props.items.map((item, key) =>
			<div className="listItem" key={key}>
			<div key={key} className={item.ticked?"sm:flex w-full items-center": "sm:flex w-full items-center"}>
				<div className="w-8/12">
					<div className="sm:flex flex-wrap ingredientRow">	
						<div className="w-full">
							<span className={item.ticked?'line-through':''}>{item.ingredient}</span>
						</div>
						
						<div className="w-full">
							<RecipeReference
								recipes={item.recipes}
								item={item}
							/>
						</div>
					</div>
				</div>
				<div className="ml-auto w-24">
					<div className="flex items-center text-center">
						<div className="w-6/12 text-2xl">
							<button  
								data-item={item.key} 
								className="flaticon-check-1 cursor-pointer text-gray-500" 
								onClick={this.props.handleTickItem}>
							</button>
						</div>
						<div className="w-6/12 text-2xl cursor-pointer text-gray-500">
							<button  
								data-item={item.key} 
								className="flaticon-minus" 
								onClick={this.props.handleRemoveItem}>
							</button>
						</div>
					</div>
				</div>
			</div>
			</div>
		);
		let hasItems = this.props.items.length > 0;
		return (
			<div className="shoppingListSection" data-hasitems={hasItems}>
				
				{hasItems &&
					<div>
						<div>
							<h3 className="subTitleHeading">{this.props.name}</h3>
							<hr/>
							{items}
						</div>
					</div>
					
				}
			</div>	
				
			
		);

	}
}
//
export class PrintShoppingList extends React.Component {
	render(){
		let hasfruitandveg = this.props.fruitandveg.length > 0;
		let hasmeat = this.props.meat.length > 0;
		let hasbakery = this.props.bakery.length > 0;
		let hascanned = this.props.canned.length > 0;
		let hasstaples = this.props.staples.length > 0;
		let hasbaking = this.props.baking.length > 0;
		let hasfrozen = this.props.frozen.length > 0;
		let hasdairy = this.props.dairy.length > 0;
		let fruitItems = this.props.fruitandveg.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		let meatItems = this.props.meat.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		let bakeryItems = this.props.bakery.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		let stapleItems = this.props.staples.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		let cannedItems = this.props.canned.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		let frozenItems = this.props.frozen.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		let dairyItems = this.props.dairy.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		let bakingItems = this.props.baking.map((item, key)=>
			<tr key={key}>
				<td>{item.ingredient}</td>
				<td className="text-right">{item.value} {item.measurement}</td>
			</tr>

		)
		//
		return(
			<div className="printRecipePage" style={{padding: '50px'}}>
				<h1 className="mb-3">Shopping List</h1>
				{hasfruitandveg&&
					<div className="printRecipeSection">
						<h4>Fruit & Vegetables</h4>
						<table className="table">
							<tbody>
								{fruitItems}
							</tbody>
						</table>
					</div>
				}
				{hasmeat&&
					<div className="printRecipeSection">
						<h4>Meat</h4>
						<table className="table">
							<tbody>
								{meatItems}
							</tbody>
						</table>
					</div>
				}
				{hasbakery&&
				<div className="printRecipeSection">
					<h4>Bakery</h4>
					<table className="table">
						<tbody>
							{bakeryItems}
						</tbody>
					</table>
				</div>
				}
				{hasstaples&&
					<div className="printRecipeSection">
						<h4>Staples</h4>
						<table className="table">
							<tbody>
								{stapleItems}
							</tbody>
						</table>
					</div>
				}
				{hasbaking&&
					<div className="printRecipeSection">
						<h4>Baking Goods</h4>
						<table className="table">
							<tbody>
								{bakingItems}
							</tbody>
						</table>
					</div>	
				}
				{hascanned&&
					<div className="printRecipeSection">
						<h4>Canned</h4>
						<table className="table">
							<tbody>
								{cannedItems}
							</tbody>
						</table>
					</div>
				}
				{hasfrozen&&
					<div className="printRecipeSection">
						<h4>Frozen Goods</h4>
						<table className="table">
							<tbody>
								{frozenItems}
							</tbody>
						</table>
					</div>
				}
				{hasdairy&&
					<div className="printRecipeSection">
						<h4>Dairy</h4>
						<table className="table">
							<tbody>
								{dairyItems}
							</tbody>
						</table>
					</div>
				}
			</div>
		)
	}
}

export class RecipeReference extends React.Component {
	
	render() {
		let hasMeasurement = this.props.item.measurement !== 'value';
		let refs = this.props.recipes.map((item,key)=>
			<div className="text-xs" key={key}>
				<span className="text-secondary">
					{Html(this.props.item.value)} 
					{hasMeasurement&&
						<>{this.props.item.measurement}</>
					}
				</span>
				&ensp;
				<Link to={`${item.url}`} className="text-blue-500">({item.name})</Link>
			</div>
		)
		return (
			<div>
				{refs}
			</div>
		);
	}
}



