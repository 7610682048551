import React, { Component } from 'react';
import AddMealToDayButton from './AddMealToPlanButton'
import HtmlParser from 'react-html-parser';
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class MealPlanDay extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
		dayPlan: this.props.dayPlan,
		day: this.props.day,
	  	modalActive: false,
	  	croppedImage: '',
	  	smallMobileImage: '',
	  	croppedMobileImage: '',
	  	hasTitle: false,
	  };
	}
	componentDidMount(){
		this.handleMealImage();
	}
	handleMealImage(){
		let recipeImages = this.props.recipeImages;
		if(recipeImages){
			let mealTitle = this.props.dayPlan.meal.title;
			for(let i = 0; recipeImages.length > i; i++){
				if(mealTitle === recipeImages[i].title){
					this.setState({
						croppedImage: recipeImages[i].croppedImg,
						croppedMobileImage: recipeImages[i].croppedImg,
						smallMobileImage: recipeImages[i].croppedWide,
						hasTitle: true,
					})
				}
				else {
					if(this.props.dayPlan.meal.recipe){
						if(this.props.dayPlan.meal.recipe.method === 'Prepared meal' || this.props.dayPlan.meal.recipe.method === 'Custom recipe'){
							this.setState({hasTitle: true})
						}
					}
				}
			}	

		}
	}
	componentDidUpdate(nextProps){
		if(this.props !== nextProps){
			this.handleMealImage();
		}
	}

	render() {
		if(this.state.dayPlan){
			let mobileDevice = this.props.deviceLayout === 'mobileLayout' || this.props.deviceLayout === 'smallMobileLayout';
			let {meal} = this.state.dayPlan;
			let {croppedImage} = this.state;
			let deviceDesktop = this.props.deviceLayout === 'desktopLayout';
			let deviceMobile = this.props.deviceLayout === 'mobileLayout';
			let deviceSmallMobile = this.props.deviceLayout === 'smallMobileLayout';
			let isMobile = this.props.deviceLayout !== 'desktopLayout';
			let mobileImageAvailable = deviceDesktop !== true && this.state.croppedImage.length > 0;
			let title = this.state.hasTitle === true;
			return(
				<div className="mealTile border border-gray-200">
						<span>
							{mobileDevice && title &&
								<h2>{this.props.meal.title}</h2>
							}
							{croppedImage &&
								<div>
									{deviceDesktop &&
										<img src={gravURL + this.state.croppedImage} alt={this.props.dayPlan.meal.title}/>
									}
								</div>
							}
							{mobileImageAvailable ?
								<span>
									{deviceSmallMobile &&
										<img src={gravURL + this.state.smallMobileImage} alt={this.props.dayPlan.meal.title}/>
									}
									{deviceMobile&&
										<img src={gravURL + this.state.croppedMobileImage} alt={this.state.dayPlan.meal.title}/>
									}
								</span>
							:
								<span className="mobilePlaceholderBlock"></span>
							}
						</span>
					{meal?
						<div className="mealContent">
							{title?
								<h6>{HtmlParser(this.state.dayPlan.meal.title)}</h6>
								:
								<h6 data-placeholder="true">Add Meal</h6>
							}
							<AddMealToDayButton handleAddMeal={this.props.handleAddMeal} 
								dayLabel={this.props.dayLabel}
								day={this.props.day}
								meal={this.props.meal}
								label="Edit"
								isMobile={isMobile}
							/>
						</div>
						:
						<div className="mealContent">
							<AddMealToDayButton handleAddMeal={this.props.handleAddMeal} 
								dayLabel={this.props.dayLabel}
								day={this.props.day}
								meal={this.props.meal}
								label="Add"
								isMobile={isMobile}
							/>
						</div>
					}
				</div>	
			);
		}
		else{ 
			return(<div></div>)
		}	
		}
}
