import React, {Component} from 'react';
import {db} from '../../../firebase';
import '../../../Content/css/profile.css';
import JournalEntries from './JournalEntries';
import '../../../Content/css/journal.css';
import {connect} from "react-redux";
import {storeUser, addNotification} from "../../../redux/actions/";
function mapDispatchToProps(dispatch) {
  return {
    storeUser: user => dispatch(storeUser(user)),
    addNotification: notification => dispatch(addNotification(notification))
  };
}
class ConnectedJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: {},
      uid: this.props.uid,
      loadingText: 'Loading',
      timeKeeping: false,
      style: {},
      recipesMacroTable: this.props.recipes.pages
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    // get user info and preferences from DB
    this.setState({
      style: {
        minHeight: window.innerHeight,
        position: 'relative',
        overflow: 'hidden',
        width: '100%'
      }
    })
  }
  reloadUserData(notification) {
    this.setState({loadingText: 'Saving', loading: true})
    db
      .getUserInfo(this.props.uid)
      .then((user) => {
        this.setState({activeMealPlan: user.Active_Meal_Plan, loading: false})
        this.props.storeUser(user)
        this.props.addNotification('Your journal has been saved')
      })
  }

  render() {
    return (
      <main>
        <div className="profileWrapper w-full relative">
          <div style={this.state.style}>
            <div className="pageGrid clearfix" id="profilePageContainerGrid">
              <section>
                <JournalEntries
                  timeKeeping={this.state.timeKeeping}
                  journal={this.props.user.Journal}
                  uid={this.props.uid}
                  mealPlans={this.state.user.Active_Meal_Plan}
                  reloadUserData={this.reloadUserData.bind(this)}
                  recipesMacroTable={this.props.recipes}
                  user={this.props.user}
                  userRecipes={this.state.userRecipes}/>
              </section>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const Journal = connect(null, mapDispatchToProps)(ConnectedJournal);

export default Journal;
