import React from 'react';
import { db } from '../../../firebase';
import {vouchers} from './../../Authentication/vouchers';
import { storeUser, storeUserId, storeRecipes } from "../../../redux/actions/";
import { connect } from "react-redux";
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
    
	};
}
class ConnectedUserVerification extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			uid: '',
			code: '',
			verifyCodeMessage: '',
			open: false,
			verifyError: false,
			codes: vouchers,
			usedCodes: [],
			verified: false,
		}
	}
	componentDidMount(){
		this.setState({
			user: this.props.user,
			uid: this.props.uid,
		})
		db.getUsedVoucherCodes()
		.then((data)=>{
			if(data){
				this.setState({
					usedCodes: data
				})
			}
		})
		.catch((e)=>{
			console.log(e)
		})
		
	}
	componentDidUpdate(nextProps){
		if(this.props.user !== nextProps.user){
			this.setState({
				user: this.props.user,
				uid: this.props.uid,
			})
		}
	}
	handleKeyUp(e){
		if (e.keyCode === 13) {
			this.handleUpdateUserCode();
		}
	}
	handleUserCodeInput(e){
		let code = e.target.value;
		this.setState({
			code: code,
		})
		if( e.target.value.length === 0 && this.state.verifyError === true){
			this.setState({
				verifyCodeMessage: '',
				verifyError: false,
			})
		}
	}
	reloadUser(){
		setTimeout(()=>{
		db.getUserInfo(this.props.uid).then((user)=>{
			if(user){
				this.props.storeUser(user);
			}
		})
		},500)
	}
	handleRejectCode(){
		this.setState({
			verifyCodeMessage: 'This code is not valid',
			verifyError: true,
			
		})
	}
	handleUpdateUserCode(){
		let {codes, code, usedCodes} = this.state;
		let codeCheck = codes.filter((item) =>{
			return  code === item.code	
		})
		let usedCheck = [];
		if(codeCheck){
			usedCheck = usedCodes.filter((item) =>{
				return  code === item.code	
			})
		}
		if(codeCheck.length > 0 && usedCheck.length === 0){
			if(codeCheck[0].code === this.state.code){
				db.getUsedVoucherCodes()
				.then((data)=>{
					if(data){
						let codes = data;
						let validatedCode = {code: this.state.code}
						codes.push(validatedCode);
						db.storeUsedCodes(codes)
						this.setState({
							verfiyError: false,
							verifyCodeMessage: 'Premium Activated!',
							verified: true,
						})
						// writes validation to database
						setTimeout(()=>{
							db.updateUserCode(this.props.uid, true)
							.then(()=>{this.reloadUser()})
						}, 200)
					}
					else {
						let codes = [];
						let validatedCode = {code: this.state.code}
						codes.push(validatedCode);
						db.storeUsedCodes(codes)
						this.setState({
							verfiyError: false,
							verifyCodeMessage: 'Premium Activated!',
							verified: true,
						})
						// writes validation to database
						setTimeout(()=>{
							db.updateUserCode(this.props.uid, true)
							.then(()=>{this.reloadUser()})
						}, 200)
					}
				})
				.catch((e)=>{
					console.log(e)
				})
			}
			else {
				this.handleRejectCode();
				
			}
		}
		else {
			this.handleRejectCode();
			
		}
	}
	handleActivationModal(){
		if(this.state.open === false){
			this.setState({open: true})
		}
		else {
			this.setState({open: false})
		}
	}
	render() {
		let {verifyError, verifyCodeMessage} = this.state;
		let isError = verifyError === true;
		let isInvalid = this.state.verifyCodeMessage.length > 0 && isError === true;
		let hasMessage = verifyCodeMessage.length > 0
		let open = this.state.open === true;
		let validated = this.state.verified === true;
		if(this.state.user.verified){
			return (
				<div><p>Account: <strong className="orangeText">Verified</strong></p></div>
			);
		}
		else{
			return (
				<div>
					<p><strong>Activate your account</strong>&emsp;
					<button className="link" onClick={this.handleActivationModal.bind(this)}>
						Activate
					</button>
					</p>
					
					{open&&
			          <div className="popupModalWapper">
			            <button className="popupModalWapperFade" onClick={this.handleActivationModal.bind(this)}>
							<span className="hidden">Activate Premium</span>
						</button>
			            <div className="passwordChangeModal popUpModal">
			            	<h3 className="text-secondary text-1xl font-medium text-center mb-3">Activate your account</h3>
			              	<div>
								{validated?
								<input type="text"
									value={this.state.code}
									onChange={this.handleUserCodeInput.bind(this)}
									className="is-valid formControl text-black font-medium"
									placeholder="Code"
									onKeyUp={this.handleKeyUp.bind(this)}
								/>
								:
								<input type="text"
									value={this.state.code}
									onChange={this.handleUserCodeInput.bind(this)}
									className={isInvalid?"formControl is-invalid text-black":"formControl text-black font-medium" }
									placeholder="Code"
									onKeyUp={this.handleKeyUp.bind(this)}

								/>
								}
								<div className="text-center">
									<button className="bg-primary rounded-lg py-2 w-full px-3 text-white my-4" onClick={this.handleUpdateUserCode.bind(this)}>
										Update
									</button>
								</div>
								<div className="h-10">
									{hasMessage&&
									<div className={validated? "text-center bg-green-600 text-white font-medium p-2":"text-center bg-red-600 text-white font-medium p-2"}>
										{this.state.verifyCodeMessage}
									</div>
									
									}
								</div>
							</div>
			            </div>
			          </div>
			        }
				</div>
			);
		}
		
	}
}
const UserVerification = connect(
	null,
	mapDispatchToProps
  )(ConnectedUserVerification);
  
export default UserVerification;
