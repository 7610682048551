import React, { Component } from 'react';


export default class SleepHourInputs extends Component {
	handleHoursSlept(e){
		this.props.handleHours(e.target.value)
	}
	componentDidMount(){
		let hours = this.props.hours;
		let inputs = document.getElementsByName('sleepHours');
		for(let i=0; i < inputs.length; i++){
			let hoursNumber = inputs[i].value;
			let inputHours = parseInt(hoursNumber)
			if(inputHours === hours){
				inputs[i].setAttribute("checked", 'checked')
			}
			if(hoursNumber === hours){
				inputs[i].setAttribute("checked", 'checked')
			}
		}
		
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props !== prevProps){
			let hours = this.props.hours;
			let inputs = document.getElementsByName('sleepHours');
			for(let i=0; i < inputs.length; i++){
				let hoursNumber = inputs[i].value;
				let inputHours = parseInt(hoursNumber)
				if(inputHours === hours){
					inputs[i].setAttribute("checked", 'checked')
				}
				if(hoursNumber === hours){
					inputs[i].setAttribute("checked", 'checked')
				}
			}
		}
	}
	render() {
		
		return (
			<div className="circleNumberInputs">
				<div className="inlineLabelWrapper">
					Number of hours
				</div>
				<div className="inlineInputsWrapper">
					<div className="circleNumberInput">
						<input type="radio" name="sleepHours" onClick={this.handleHoursSlept.bind(this)} value="4"/>
						<span className="circleInput">
							4
						</span>
					</div>
					<div className="circleNumberInput">
						<input type="radio" name="sleepHours" onClick={this.handleHoursSlept.bind(this)} value="5"/>
						<span className="circleInput">
							5
						</span>
					</div>
					<div className="circleNumberInput">
						<input type="radio" name="sleepHours" onClick={this.handleHoursSlept.bind(this)} value="6"/>
						<span className="circleInput">
							6
						</span>
					</div>
					<div className="circleNumberInput">
						<input type="radio" name="sleepHours" onClick={this.handleHoursSlept.bind(this)} value="7"/>
						<span className="circleInput">
							7
						</span>
					</div>
					<div className="circleNumberInput">
						<input type="radio" name="sleepHours" onClick={this.handleHoursSlept.bind(this)} value="8"/>
						<span className="circleInput">
							8
						</span>
					</div>
				</div>
			</div>
		);
	}
}
