import React, { Component } from 'react'
import {Link, navigate} from 'gatsby';
export default class Challenges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            code: '',
            initStage: true
        }
    }
    handleModal(){
        let status = this.state.open;
        this.setState({
            open: !status,
        })
    }
    handleSubmit(){
        if(this.state.code.toLowerCase() === 'star'){
            navigate('/challenges/stars-of-alsdii');
        }
    }
    handleCode({target}){
        this.setState({code: target.value})
    }
    render() {
        let open = this.state.open === true;
        let initStage = this.state.initStage === true;
        return (
            <>
            <div>
                {this.props.user.challenge?
                <button className="font-bold" onClick={this.handleModal.bind(this)}>Challenges: <span className="font-bold underline cursor-pointer"> {this.props.user.challenge}</span></button>
                :
                <button className="font-bold" onClick={this.handleModal.bind(this)}>Challenges: <span className="ml-2 font-normal cursor-pointer rounded-lg py-2 px-6 bg-white text-primary">Get Started</span></button>
                }
            </div>
            <div>
            {open&&
                <div className="popupModalWapper">
                    <button className="popupModalWapperFade" onClick={this.handleModal.bind(this)}>
                        <span className="hidden">Close</span>
                    </button>
                    {initStage?
                    <div className="passwordChangeModal popUpModal text-secondary">
                        <h3 className="text-secondary text-2xl font-medium text-center mb-3">Challenges</h3>
                        <p className="text-secondary mb-4 text-sm">
                            Challenges are held by local New Image™ offices to track the progress of all contestants.
                        </p>  
                        <p className="text-sm">Please <Link to="/contact">Contact</Link> your local office.  
                            If a challenge is currently running the office will be able to provide you a code to enter&ensp; 
                        </p>
                        <label className="block w-full text-center mt-2">
                            <span className="font-medium">Challenge Code</span>
                        <input type="text"
                            value={this.state.code}
                            onChange={this.handleCode.bind(this)}
                            className="formControl mt-2"
                        />
                        </label>
                        <button className="rounded-lg w-full bg-primary text-white py-2 px-3 my-4" onClick={this.handleSubmit.bind(this)}>Submit</button>
                    </div>
                    :
                    <div className="passwordChangeModal popUpModal">
                        
                        <button className="rounded-lg w-full bg-primary text-white py-2 px-3 my-4" onClick={this.handleSubmit.bind(this)}>Submit</button>
                    </div>
                    }   
                </div>
            }
            </div>
            </>
        )
    }
}
