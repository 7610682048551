import React from 'react';
const RecipeMacroTable = (props) => {
	const {totalCarbs, totalFat, totalProtein} = props.macros;
	return (
		<div>
			{props.macros?
			<table className="text-center w-full rounded-md shadow-md max-w-lg bg-white my-4">
				<tbody>
					<tr>
						<th className="px-2 py-2">Carbs</th>
						<th className="px-2 py-2">Fat</th>
						<th className="px-2 py-2">Protein</th>
					</tr>
					<tr>
						<td className="px-2 py-2 font-medium">{totalCarbs}</td>
						<td className="px-2 py-2 font-medium">{totalFat}</td>
						<td className="px-2 py-2 font-medium">{totalProtein}</td>
					</tr>
				</tbody>
			</table>
			:
			<p className="font-bold">Macros are not available</p>
			}
		</div>
	);
}
export default RecipeMacroTable;