import React, { Component } from 'react';
import SelectSearch from 'react-select-search';
import '../../../../Content/css/searchSelect.css';
import MainMeal from './MainMeal';
import AddMealToJournalOption from './AddMealToJournalOption';
import UserRecipeCreator from './UserRecipeCreator';
import {Event} from '../../../Analytics/';
export default class SnackerInput extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	meals: this.props.meals,
	  	tempMeals: this.props.meals,
	  	recipesMacroTable: [],
	  	width: 0,
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	componentDidMount(){
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions.bind(this));
	}
	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	handleRemoveBreakfast(){
		let meals = {
			breakfast: {
				value: '',
				carbs: 0,
				fat: 0,
				protein: 0,
				name: '',
			},
			lunch: this.props.meals.lunch,
			dinner: this.props.meals.dinner,	
		}
		this.props.handleMeal(meals);
	}
	handleAddPlannedBreakfast(breakfast){
		let meals ={
			breakfast: {
				value: breakfast.label,
				carbs: breakfast.carbs,
				fat: breakfast.fat,
				protein: breakfast.protein,
				name: breakfast.label,
			},
			lunch: this.props.meals.lunch,
			dinner: this.props.meals.dinner,
		}
		this.props.handleMeal(meals);	
	}
	handleAddPlannedLunch(lunch){
		let meals ={
			lunch: {
				value: lunch.label,
				carbs: lunch.carbs,
				fat: lunch.fat,
				protein: lunch.protein,
				name: lunch.label,
			},
			breakfast: this.props.meals.breakfast,
			dinner: this.props.meals.dinner,
		}
		this.props.handleMeal(meals);
	}
	handleAddPlannedDinner(dinner){
		let meals ={
			dinner: {
				value: dinner.label,
				carbs: dinner.carbs,
				fat: dinner.fat,
				protein: dinner.protein,
				name: dinner.label,
			},
			breakfast: this.props.meals.breakfast,
			lunch: this.props.meals.lunch,
		}
		this.props.handleMeal(meals);
	}
	handleRemoveLunch(){
		let meals ={
			lunch: {
				value: '',
				carbs: 0,
				fat: 0,
				protein: 0,
				name: '',
			},
			breakfast: this.props.meals.breakfast,
			dinner: this.props.meals.dinner,
		}
		this.props.handleMeal(meals);
	}
	handleRemoveDinner(){
		let meals = {
			dinner: {
				value: '',
				carbs: 0,
				fat: 0,
				protein: 0,
				name: '',
			},
			lunch: this.props.meals.lunch,
			breakfast: this.props.meals.breakfast,
		}
		this.props.handleMeal(meals);
	}
	handleBreakfastSearch(breakfast){
		const mealData = this.getMealData(breakfast, 'Breakfast');
		console.log(mealData, 'returned meal data')
		this.setState({
			tempMeals: {
				breakfast: mealData,
				dinner: this.state.tempMeals.dinner,
				lunch: this.state.tempMeals.lunch,
			}
		})
		this.props.handleMeal(this.state.tempMeals);
	}
	getMealData(meal, mealType){
		Event('Journal', 'Added Meal To Journal', `${mealType}: ${meal.name}`)
		const mealData = {
			value: meal.value, 
			carbs: meal.carbs, 
			fat: meal.fat, 
			protein: meal.protein, 
			name: meal.name, 
		}
		return mealData;
	}
	handleBreakfastMeal(meal, serving){
		const mealData = this.getMealData(meal, 'Breakfast');
		let meals = {
			breakfast: mealData,
			lunch: this.props.meals.lunch,
			dinner: this.props.meals.dinner,
		}
		this.props.handleMeal(meals);
		this.setState({
			tempMeals: {
				breakfast:{
					value: '',
					fat: 0,
					carbs: 0,
					protein: 0,
					name: '',
				},
				dinner: this.props.meals.dinner,
				lunch: this.props.meals.lunch,
			}
		})
	}
	handleLunchSearch(lunch){
		const mealData = this.getMealData(lunch, 'Lunch');
		this.setState({
			tempMeals: {
				lunch: mealData,
				dinner: this.state.tempMeals.dinner,
				breakfast: this.state.tempMeals.breakfast,
			}
		})
		this.props.handleMeal(this.state.tempMeals);
	}
	handleLunchMeal(meal){
		const mealData = this.getMealData(meal, 'Lunch');
		let meals = {
			dinner: this.props.meals.dinner,
			lunch: mealData,
			breakfast: this.props.meals.breakfast,
		}
		this.setState({
			tempMeals: {
				lunch:{
					value: '',
					fat: 0,
					carbs: 0,
					protein: 0,
					name: '',
				},
				breakfast: this.props.meals.breakfast,
				dinner: this.props.meals.dinner,
			}
		})
		this.props.handleMeal(meals);
	}
	handleDinnerSearch(dinner){
		const mealData = this.getMealData(dinner, 'Dinner');
		this.setState({
			tempMeals: {
				dinner: mealData,
				lunch: this.state.tempMeals.lunch,
				breakfast: this.state.tempMeals.breakfast,
			}
		})
		this.props.handleMeal(this.state.tempMeals);
	}
	handleDinnerMeal(meal){
		const mealData = this.getMealData(meal, 'Dinner');
		let meals = {
			dinner: mealData,
			lunch: this.props.meals.lunch,
			breakfast: this.props.meals.breakfast,
		}
		this.setState({
			tempMeals: {
				dinner:{
					value: '',
					fat: 0,
					carbs: 0,
					protein: 0,
					name: '',
				},
				breakfast: this.props.meals.breakfast,
				lunch: this.props.meals.lunch,
			}
		})
		this.props.handleMeal(meals);
	}
	render() {
		const {breakfast, lunch, dinner} = this.props.meals;
		const breakfastChosen = this.props.meals.breakfast.value.length === 0;
		const lunchChosen = this.props.meals.lunch.value.length === 0;
		const dinnerChosen = this.props.meals.dinner.value.length === 0;
		const hasMealPlans = this.props.mealPlans;
		const desktopSize = this.state.width > 767;
		let mealsAvailable = breakfastChosen === false || lunchChosen === false || dinnerChosen === false;
		
		
		return (
			<div>
				<div className="mainMealInput">
					<div className="row">
						<div className="col-sm-12">														
							{desktopSize? null: <h4 className="mb-4">Main Meals</h4>}
							{desktopSize?
							<table className="table mainMealsMacroTable">
								<tbody>
								{mealsAvailable&&
									<tr>
										<th className="text-left"><h4>Main Meals</h4></th>
										<th className="text-center"><h6>Fat:</h6></th>
										<th className="text-center"><h6>Carbs:</h6></th>
										<th className="text-center"><h6>Protein:</h6></th>
										<th className="text-right"><h6>Remove</h6></th>
									</tr>
								}
								{breakfast &&
									<MainMeal handleRemoveMeal={this.handleRemoveBreakfast.bind(this)} meals={this.props.meals.breakfast} label="Breakfast"/>
								}
								{lunch &&
									<MainMeal  handleRemoveMeal={this.handleRemoveLunch.bind(this)} meals={this.props.meals.lunch} label="Lunch"/>
								}
								{dinner &&
									<MainMeal handleRemoveMeal={this.handleRemoveDinner.bind(this)} meals={this.props.meals.dinner} label="Dinner"/>
								}
								</tbody>
							</table>
							:
							<table className="table mainMealsMacroTable">
								{breakfast &&
									<MainMeal  handleRemoveMeal={this.handleRemoveBreakfast.bind(this)} meals={this.props.meals.breakfast} label="Breakfast"/>
								}
								{lunch &&
									<MainMeal handleRemoveMeal={this.handleRemoveLunch.bind(this)}meals={this.props.meals.lunch}label="Lunch"/>
								}
								{dinner &&
									<MainMeal  handleRemoveMeal={this.handleRemoveDinner.bind(this)} meals={this.props.meals.dinner} label="Dinner"/>
								}
							</table>
							}
								
						</div>
						
					</div>
					{breakfastChosen &&
						<React.Fragment>
							<div className="flex flex-wrap w-full">
								<div className="mainMealWrapper">
									<h4 className="mainMealLabel">Breakfast</h4>
								</div>
								<div className="text-right">
									{hasMealPlans &&
										<AddMealToJournalOption 
											options={this.props.mealPlans[this.props.trueWeekDay].breakfast.meal}
											handleAddMeal={this.handleAddPlannedBreakfast.bind(this)}
										/>
									}
								</div>
							</div>
							<div className="flex w-full items-center">
								<SelectSearch 
									options={this.props.recipesMacroTable}
									value={this.state.tempMeals.breakfast.value}
									onChange={this.handleBreakfastSearch.bind(this)}
									name="breakfastMeal" 
									placeholder="Search Recipes"
								/>
								<div className="text-right ml-auto">
									<UserRecipeCreator 
										light={true}
										userRecipes={this.props.userRecipes} 
										uid={this.props.uid} 
										handleAddPlannedMeal={this.handleAddPlannedBreakfast.bind(this)}
									/>
								</div>
							</div>
						</React.Fragment>
					}
				</div>
				<div className="mainMealInput">
					
					{lunchChosen &&
						<div className="flex flex-wrap w-full">
							<div className="mainMealWrapper">
								<h4 className="mainMealLabel">Lunch</h4>
							</div>
							<div className="text-right">
								{hasMealPlans &&
									<AddMealToJournalOption 
										options={this.props.mealPlans[this.props.trueWeekDay].lunch.meal}
										handleAddMeal={this.handleAddPlannedLunch.bind(this)}
									/>
								}

							</div>
							<div className="ml-auto"></div>
						</div>
					}
					{lunchChosen &&
						<div className="flex items-center w-full">
							<SelectSearch 
								options={this.props.recipesMacroTable}
								value={this.state.tempMeals.lunch.value}
								onChange={this.handleLunchSearch.bind(this)}
								name="lunchMeal"
								placeholder="Search Recipes" 
							/>
							<UserRecipeCreator 
								light={true}
								userRecipes={this.props.userRecipes} 
								uid={this.props.uid} 
								handleAddPlannedMeal={this.handleAddPlannedLunch.bind(this)}
							/>
						</div>
					}
				</div>
				<div className="mainMealInput">
					{dinnerChosen &&
						<React.Fragment>
							<div className="flex flex-wrap w-full">
								<div className="mainMealWrapper">
									<h4 className="mainMealLabel">Dinner</h4>
								</div>
								<div className="text-right">
									{hasMealPlans &&
										<AddMealToJournalOption 
											options={this.props.mealPlans[this.props.trueWeekDay].dinner.meal}
											handleAddMeal={this.handleAddPlannedDinner.bind(this)}
										/>
									}
								</div>
							</div>
							<div className="w-full flex items-center">
								<SelectSearch 
									options={this.props.recipesMacroTable}
									value={this.state.tempMeals.dinner.value}
									onChange={this.handleDinnerSearch.bind(this)}
									name="dinnerMeal"
									placeholder="Search Recipes" 
								/>
								<UserRecipeCreator 
									light={true}
									userRecipes={this.props.userRecipes} 
									uid={this.props.uid} 
									handleAddPlannedMeal={this.handleAddPlannedDinner.bind(this)}
								/>
							</div>
						</React.Fragment>
					}
				</div>
			</div>
		);
	}
}
