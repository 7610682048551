import React, { Component } from 'react';
import '../../../Content/css/profile.css';
import UserMealPlans from '../../User/MealPlans/UserMealPlans';
export default class Profile extends Component {
	constructor(props) {
	  super(props);
	  this._isMounted = false
	  this.state = {
	  	recipesMacroTable: this.props.recipes,
	  	style: {},
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	componentDidMount(){
		window.addEventListener('resize', this.updateWindowDimensions.bind(this));
		this.updateWindowDimensions();
	}
	updateWindowDimensions() {
	  	this.setState({
			style:{ 
				minHeight: window.innerHeight - 100, 
				width: window.innerWidth,
			}})
	}	
	render() {
		return (
			<div className="profileWrapper userMealPlans">
				<div>
					<UserMealPlans uid={this.props.uid}
						recipes={this.props.recipes}
						style={this.state.style}
						content={this.props.content}
						storeUser={this.props.storeUser}
					/>
				</div>
			</div>
		);
		
	}
}

