import React, { Component } from 'react';

export default class MacroTable extends Component {
	constructor(props){
		super(props);
		this.state = {
			beverage: this.props.beverage,
			servingAmount: 100,
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	}
	componentDidMount(){
		this.updateWindowDimensions();
	 	window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	static getDerivedStateFromProps(props, state) {
		//
		return null
    }
	handleServingAmount(e){
		if(e.target.value >= 0){
			this.setState({
				servingAmount: e.target.value,
			})	
		}
		
	}
	handleBeverage(){
		this.props.handleBeverage(this.props.beverage, this.state.servingAmount)
	}
	render() {
		let roundedFat = ((this.props.beverage.fat/100)*this.state.servingAmount);
		let roundedCarbs = ((this.props.beverage.carbs/100)*this.state.servingAmount);
		let roundedProtein = ((this.props.beverage.protein/100)*this.state.servingAmount);
		let roundedCaffeine = ((this.props.beverage.caffeine/100)*this.state.servingAmount);
		let fat = roundedFat.toFixed(2);
		let carbs = roundedCarbs.toFixed(2);
		let protein = roundedProtein.toFixed(2);
		let caffeine = roundedCaffeine.toFixed(2);
		let desktopLayout = this.state.width > 767;

		const {name} = this.props.beverage;
		return (
			<>
				{desktopLayout?
					<table className="table beverageMacrosTable">
					{name?
						<tbody>
							<td>
								<h6 className="mealName">{this.props.beverage.value}</h6>
							</td>
							<td>
								<h6>Fat:</h6>
								{fat}g
							</td>
							<td>
								<h6>Carbs:</h6> 
								{carbs}g
							</td>
							<td>
								<h6>Protein:</h6>
								{protein}g
							</td>
							<td>
								<h6>Caffeine:</h6>
								{caffeine}mg
							</td>
							<td>
								<h6>Serving Amount</h6>
								<input 
									type="text" 
									onChange={this.handleServingAmount.bind(this)} 
									value={this.state.servingAmount}
									className="servingAmountInput text-right"
								/>ml
							</td>
							<td>
								<button className="macroTableSnackAddButton" onClick={this.handleBeverage.bind(this)}>
									<span className="flaticon-add-1"></span>
									<span className="hidden">Add snack</span>
								</button>
							</td>
						</tbody>
					:null}
					</table>
					:
					<>
						{name &&
							<>
							<div>
								
									<h6 className="mealName">{this.props.beverage.value}</h6>
								
								
							</div>
							<div className="mealNameContainer">
								<div className="mealName">
									<h6 className="mealInputInlineLabel">Serving Amount</h6>
									<input 
										type="text" 
										onChange={this.handleServingAmount.bind(this)} 
										value={this.state.servingAmount}
										className="servingAmountInput"
									/>ml
								</div>
								<div className="snackRemovalContainer">
									<button className="macroTableSnackAddButton" onClick={this.handleBeverage.bind(this)}>
										<span className="flaticon-add-1"></span>
										<span className="hidden">remove snack</span>
									</button>
								</div>
							</div>
							<table className="table beverageMacrosTable">
								<tbody>
									<tr>
										<td>
											<h6>Fat:</h6>
											{fat}g
										</td>
										<td>
											<h6>Carbs:</h6> 
											{carbs}g
										</td>
										<td>
											<h6>Protein:</h6>
											{protein}g
										</td>
										<td>
											<h6>Caffeine:</h6>
											{caffeine}mg
										</td>
										<td>
											
										</td>
									</tr>
									
								</tbody>
							</table>

							<hr/>
							</>	
						}
					</>	
					
			  	}		
			</>
		);
	}
}
