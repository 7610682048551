import React, { Component } from 'react';
import '../../../Content/css/profile.css';
import ProfileDashboard from './ProfileDashboard';
import { connect } from "react-redux";
const mapStateToProps = (state, props) => {
	return { user: state.user, uid: state.uid, ownProps: props};
};
const ProfileConnected = ({ user, uid, ownProps }) => (
  <ProfileComponent user={user} uid={uid} own={ownProps}/>
);  
class ProfileComponent extends Component {
	_isMounted = false;
	constructor(props) {
	  super(props);
	  this.state = {
	  	user: {},
	  	uid: this.props.uid,
	  	windowWidth: 0,
	  };
	}
	componentDidMount(){
		this._isMounted = true;
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions.bind(this));
	}
	componentWillUnmount() {
    	this._isMounted = false;
    	window.removeEventListener('resize', this.updateWindowDimensions);
  	}
	updateWindowDimensions() {
	  	this.setState({windowWidth: window.innerWidth,})
	}
	render() {
		let large = this.state.windowWidth > 1000;
		return (
			<main>
				<div className="profileWrapper">		
					<ProfileDashboard user={this.props.user} uid={this.props.uid} large={large}/>
				</div>
			</main>
		);
	}
}

const Profile = connect(mapStateToProps)(ProfileConnected);
export default Profile;