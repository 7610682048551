import React, {Component} from 'react';
import {Link} from 'gatsby';
import MyFavoriteRecipes from './MyFavoriteRecipes';
import '../../../Content/css/profile.css';
import PageHeader from '../../PageComponents/PageHeader';
import {connect} from "react-redux";
const mapStateToProps = (state, props) => {
  return {user: state.user, uid: state.uid, recipes: props.recipePages};
};
const FavoritesConnected = ({user, uid, recipes}) => (
	<FavoriteRecipes user={user} uid={uid} recipes={recipes}/>
);
class FavoriteRecipes extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: {},
      uid: this.props.uid,
      favorites: this.props.user.Favorite_Recipes,
      gravContent: this.props.recipes
    };
  }


  render() {
    let {favorites} = this.state; 
    return (
      <main>
        <div className="profileWrapper">
          <div className="profilePageContainer">
            <div className="pageGrid clearfix">
              {favorites
                ? <PageHeader mainHeading="Favorite Recipes" subHeading="your favorites"/>
                : <React.Fragment> 
                      <PageHeader mainHeading="Favorite Recipes" subHeading="your favorites"/>
                      <div className="text-center mt-3">
                        <Link to='/recipes' className="button">Recipes Page</Link>
                    </div>
                  </React.Fragment>
              }
              <MyFavoriteRecipes
                favorites={this.props.user.Favorite_Recipes}
                allRecipes={this.props.recipes}
                uid={this.props.uid}
                user={this.props.user}
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const Favorites = connect(mapStateToProps)(FavoritesConnected);
export default Favorites;