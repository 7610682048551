import React, { Component } from 'react';
import { db } from '../../../firebase/';
export default class SnackPreferenceSwitch extends Component {
	handlePreference(e) {
		let preferSnacks = e.target.value;
		if(preferSnacks === "false"){
			db.handleSnackPreference(this.props.uid, false).then(()=>{
				this.props.reloadUserData();
				
			})
		}
		if(preferSnacks === "true"){
			db.handleSnackPreference(this.props.uid, true).then(()=>{
				this.props.reloadUserData();
			})
		}
	}
	handlePreferenceButton(e){
		let value = e.target.value;
		if(value === 'true'){
			db.handleSnackPreference(this.props.uid, false).then(()=>{
				this.props.reloadUserData();
			})
		}
		else {
			db.handleSnackPreference(this.props.uid, true).then(()=>{
				this.props.reloadUserData();
			})
		}	
	} 
	render() {
		const {prefersnacks} = this.props; 
		return (
			<div>
				<button 
					onClick={this.handlePreferenceButton.bind(this)} 
					value={prefersnacks}
					className={this.props.isMobile?"px-5": "rounded-lg py-2 px-4 bg-white text-secondary text-xs shadow-md border border-gray-300"}
					data-preference={prefersnacks}
				>
					{prefersnacks? 'Hide': 'Show'} Snacks
				</button>
			</div>
		);
		
		
	}
}
