import React from 'react';
import FullRecipePeek from './FullRecipePeek';

const MealAddPeek = (props) => {
	const {title} = props.meal;
	return (
		<div className="mb-4 mt-4">
			{title&&
				<FullRecipePeek uid={props.uid} 
					meal={props.meal} 
					gravUrl={props.gravUrl}
					handleAddMealToPlan={props.handleAddMealToPlan}
					requireSaveButton={props.requireSaveButton}
				/>
			}
		</div>
	);
}
export default MealAddPeek;