import React, { Component } from 'react';
export default class MacroTable extends Component {
	constructor(props){
		super(props);
		this.state = {
			meal: this.props.meal,
			servingAmount: 100,
			productServing: 1,
			snackQuantity: 1,
		}
	}
	componentDidMount(){
		this.setState({meal: this.props.meal,})
		if(this.props.meal.avg > 0){
			this.setState({servingAmount: this.props.meal.avg})
		}
	}
	
	handleServingWithAvg(e){
		let quantityInput = e.target;
		this.setState({snackQuanity: quantityInput.value})
	}
	handleServingAmount(e){
		if(e.target.value >= 0){
			this.setState({servingAmount: e.target.value,})	
		}	
	}
	handleQuantity(e){

		if(e.target.value > 0){
			this.setState({servingAmount: e.target.value})
		}
		else {
			this.setState({servingAmount: ''})
		}
		
	}
	handleProductServing(e){

		if(e.target.value > 0){
			this.setState({productServing: e.target.value})
		}
		else {
			this.setState({productServing: ''})
		}
		
	}
	handleSnackerMeal(){
		if(this.props.meal.product){
			this.props.handleProductServing(this.props.meal, this.state.productServing);
		}
		else {
			this.props.handleSnackerMeal(this.props.meal, this.state.servingAmount)
		}
	}
	handleSnackerMealWithAvg(e){

		let servingAmount = this.state.servingAmount * this.props.meal.avg;
		this.props.handleSnackerMeal(this.props.meal, servingAmount)
	}
	handleFoodSize(e){
		let option = e.target.value;
		this.setState({servingAmount: this.state.servingAmount * option});
	}
	handleSnackerMealWithSize(){
		this.props.handleSnackerMeal(this.props.meal, this.state.servingAmount)
	}
	render() {
		let roundedFat = ((this.props.meal.fat/100)*this.state.servingAmount);
		let roundedCarbs = ((this.props.meal.carbs/100)*this.state.servingAmount);
		let roundedProtein = ((this.props.meal.protein/100)*this.state.servingAmount);
		let fat = roundedFat.toFixed(2);
		let carbs = roundedCarbs.toFixed(2);
		let protein = roundedProtein.toFixed(2);
		let allowServingInput = this.props.serving;
		let quantityAvailable = this.props.meal.small > 0 || this.props.meal.avg > 0;
		let quantityRequired = this.props.meal.small === "" && this.props.meal.avg === "";
		const {name, large, avg, product} = this.props.meal;
		
		if(this.props.desktopLayout === true){
			return (
				<table className="table mealMacrosTable mb-4">
					{name?
						<tbody>
							<tr>
								<td className="py-2"><h6 className="mealName">{this.props.meal.value}</h6></td>
								<td className="py-2 text-center">{fat} g</td>
								<td className="py-2 text-center">{carbs} g</td>
								<td className="py-2 text-center">{protein} g</td>
								{large && 
									<td className="text-center">
										<select onBlur={this.handleFoodSize.bind(this)} className="sizeSelect">
											<option>Select Size</option>
											<option value={this.props.meal.large}>small</option>
											<option value={this.props.meal.medium}>medium</option>
											<option value={this.props.meal.small}>large</option>
										</select>
									</td>
								}
								{product?
								<>
									<td className="text-center py-2">
										<span className="text-xs mr-2">serves:</span>
										<input
											type="text"
											onChange={this.handleProductServing.bind(this)}
											value={this.state.productServing}
											className="servingAmountInput text-center"
										/>
									</td>
								</>
								:
								<>
									{allowServingInput &&
									<td className="text-center py-2">
										{
											quantityAvailable ?
												<input id="valueInput"
													type="text"
													onChange={this.handleQuantity.bind(this)}
													value={this.state.servingAmount}
													className="quantityInput"
												/>
												:
											<> 
												<input id="valueInput"
												type="text"
												onChange={this.handleServingAmount.bind(this)}
												value={this.state.servingAmount}
												className="servingAmountInput"
												/> {this.props.servingValueType}
											</>
										}
									</td>
									}
								</>
								
								}
								<td className="text-right">
									{avg &&
										<button className="macroTableSnackAddButton" data-size={this.props.meal.avg} onClick={this.handleSnackerMealWithAvg.bind(this)}>
											<span className="flaticon-add-1"></span>
											<span className="hidden">add 1</span>
										</button>
									}
									{large &&
										<button className="macroTableSnackAddButton" onClick={this.handleSnackerMealWithSize.bind(this)}>
											<span className="flaticon-add-1"></span>
											<span className="hidden">add 1</span>
										</button>
									}
									{quantityRequired &&
										<button className="macroTableSnackAddButton" onClick={this.handleSnackerMeal.bind(this)}>
											<span className="flaticon-add-1"></span>
											<span className="hidden">add 1</span>
										</button>
									}
									{product&&
										<button className="macroTableSnackAddButton" onClick={this.handleSnackerMeal.bind(this)}>
											<span className="flaticon-add-1"></span>
											<span className="hidden">add 1</span>
										</button>
									}
								</td>
							</tr>
							
						</tbody>
					:null}
				</table>
			);	
		}
		else {
			return (
				<div>
					{name?	
					<div className="mobileMacroInputContainer">
						<div className="mealNameContainer"><h6 className="mealName">{this.props.meal.value}</h6></div>
						<div className="inputsContainer">
							{large && 
								<div className="text-center sizeSelector">
									<select onBlur={this.handleFoodSize.bind(this)} className="sizeSelect">
										<option>Select Size</option>
										<option value={this.props.meal.large}>small</option>
										<option value={this.props.meal.medium}>medium</option>
										<option value={this.props.meal.small}>large</option>
									</select>
								</div>
							}
							{product?
								<>
									<td className="text-center py-2">
										<span className="text-xs mr-2">serves:</span>
										<input
											type="text"
											onChange={this.handleProductServing.bind(this)}
											value={this.state.productServing}
											className="servingAmountInput text-center"
										/>
									</td>
								</>
								:
								<>
									{allowServingInput &&
									<td className="text-center py-2">
										{
											quantityAvailable ?
												<input id="valueInput"
													type="text"
													onChange={this.handleQuantity.bind(this)}
													value={this.state.servingAmount}
													className="quantityInput"
												/>
												:
											<> 
												<input id="valueInput"
												type="text"
												onChange={this.handleServingAmount.bind(this)}
												value={this.state.servingAmount}
												className="servingAmountInput"
												/> {this.props.servingValueType}
											</>
										}
									</td>
									}
								</>
								
								}
						</div>
						{avg &&
							<button className="macroTableSnackAddButton" data-size={this.props.meal.avg} onClick={this.handleSnackerMealWithAvg.bind(this)}>
								<span className="flaticon-add-1"></span>
								<span className="hidden">add 1</span>
							</button>
						}
						{large &&
							<button className="macroTableSnackAddButton" onClick={this.handleSnackerMealWithSize.bind(this)}>
								<span className="flaticon-add-1"></span>
								<span className="hidden">add 1</span>
							</button>
						}
						{quantityRequired &&
							<button className="macroTableSnackAddButton" onClick={this.handleSnackerMeal.bind(this)}>
								<span className="flaticon-add-1"></span>
								<span className="hidden">add 1</span>
							</button>
						}
						{product&&
							<button className="macroTableSnackAddButton" onClick={this.handleSnackerMeal.bind(this)}>
								<span className="flaticon-add-1"></span>
								<span className="hidden">add 1</span>
							</button>
						}
						
				</div>
							
					:null}
				</div>
			);
		}
		
	}
}
