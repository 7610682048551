import React from 'react';
export default class CaffeinatedDrinkInputs extends React.Component {
	handleCaffeinatedDrinkInput(e){
		let input = e.target.value;
		this.props.handleDrinks(input)
		let inputs = document.getElementsByName('drinks');
		for(let i=0; i < inputs.length; i++){
			let drinksNumber = inputs[i].value;
			let inputdrinks = parseInt(drinksNumber)
			if(inputdrinks < input){
				inputs[i].setAttribute("data-below", true)
			}
			if(inputdrinks > input){
				inputs[i].setAttribute("data-below", false)
			}
		}
	}
	componentDidMount(){
		let drinks = this.props.drinks;
		let inputs = document.getElementsByName('drinks');
		for(let i=0; i < inputs.length; i++){
			let drinksNumber = inputs[i].value;
			let inputDrinks = parseInt(drinksNumber)
			if(inputDrinks === drinks){
				inputs[i].setAttribute("checked", 'checked')
			}
			if(drinksNumber === drinks){
				inputs[i].setAttribute("checked", 'checked')
			}
			if(inputDrinks < drinks){
				inputs[i].setAttribute("data-below", true)
			}
			if(inputDrinks > drinks){
				inputs[i].setAttribute("data-below", false)
			}
		}
	}

	render() {
		return (
			<div className="caffeinatedDrinkInputsWrapper drinkInputs">
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="drinks" 
						onChange={this.handleCaffeinatedDrinkInput.bind(this)} 
						value={1}
					/>
					<span data-selected="false" className="circleInput">1</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="drinks" 
						onChange={this.handleCaffeinatedDrinkInput.bind(this)} 
						value={2}
					/>
					<span data-selected="false" className="circleInput">2</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="drinks" 
						onChange={this.handleCaffeinatedDrinkInput.bind(this)} 
						value={3}
					/>
					<span data-selected="false" className="circleInput">3</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="drinks" 
						onChange={this.handleCaffeinatedDrinkInput.bind(this)} 
						value={4}
					/>
					<span data-selected="false" className="circleInput">4</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="drinks" 
						onChange={this.handleCaffeinatedDrinkInput.bind(this)} 
						value={5}
					/>
					<span data-selected="false" className="circleInput">5</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="drinks" 
						onChange={this.handleCaffeinatedDrinkInput.bind(this)} 
						value={6}
					/>
					<span data-selected="false" className="circleInput">6</span>
				</div>
			</div>
		);
	}
}
