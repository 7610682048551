import React, { Component } from 'react'
import {calculateKetoMacros} from './Calculator';
import { connect } from 'react-redux'
import { storeUser, storeUserId, storeRecipes, storeCalculator } from "../../redux/actions/";
import '../../Content/css/ketodux.css'
function mapDispatchToProps(dispatch) {
	return {
        storeUser: user => dispatch(storeUser(user)),
        storeRecipes: recipes => dispatch(storeRecipes(recipes)),
        storeUserId: id => dispatch(storeUserId(id)),
        storeCalculator: stats => dispatch(storeCalculator(stats))
    
	};
}
class Inputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
			age: '',
			sex: '',
            weight: '',
            pounds: '',
            cm: '',
            feet: '',
            inches: '',
            height: '',
            goal: '',
            HeightFormat: 'cm',
            activityLevel: '',
            metric: true,
            suggestedCalories: '',
            value: {min: -100, max: 100},
            deficitX: -100,
			surplusX: 50,
			validate: false,
        }
	}
	componentDidMount() {
		if(this.props.uid.length > 0){
			if(this.props.user.stats){
				let userStats = this.props.user.stats;
				let goalInputs = document.getElementsByName('goal');
				let sexInputs = document.getElementsByName('sex');
				let activityInputs = document.getElementsByName('activityLevel');
				if(sexInputs){
					for(let i = 0; sexInputs.length > i; i++){
						if(sexInputs[i].value === userStats.sex){
							sexInputs[i].checked = true;
						}
					}
				}
				if(goalInputs){
					for(let i = 0; goalInputs.length > i; i++){
						if(goalInputs[i].value === userStats.weightManagementGoal){
							goalInputs[i].checked = true
						}
					}
				}
				if(activityInputs){
					for(let i = 0; activityInputs.length > i; i++){
						if(activityInputs[i].value === userStats.activityLevel){
							activityInputs[i].checked = true
						}
					}
				}
				this.setState({
					age: userStats.age,
					sex: userStats.sex,
					height: userStats.height,
					weight: userStats.weight,
					goal: userStats.weightManagementGoal,
					activityLevel: userStats.activityLevel
				})
				setTimeout(()=>{
					let stats = calculateKetoMacros(
						userStats.sex,
						userStats.age,
						userStats.height,
						userStats.weight,
						userStats.weightManagementGoal,
						userStats.activityLevel,
						this.state.surplusX,
						this.state.deficitX
					);
					let calculatorOutputs = {...stats, ...this.state}
					this.props.storeCalculator(calculatorOutputs)
				},100)
			}
		}
	}
	
    submitForm(){
		let ageInvalid = this.state.age.length === 0;
		let sexValid = this.state.sex.length === 0;
		let activityValid = this.state.activityLevel.length === 0;
		let heightInvalid = this.state.height.length === 0;
		let weightInvalid = this.state.weight.length === 0;
		let goalValid = this.state.goal.length === 0; 
		let validForm = !ageInvalid && !sexValid && !activityValid && !heightInvalid && !weightInvalid && !goalValid
		let {sex, age, height, weight, goal, activityLevel, surplusX, deficitX} = this.state;
		if(validForm){
			let stats = calculateKetoMacros(sex, age, height, weight, goal, activityLevel, surplusX, deficitX);
			let calculatorOutputs = {...stats, ...this.state}
			this.props.storeCalculator(calculatorOutputs)
		}
		else {
			this.setState({
				validate: true,
			})
		}
		
    }
    changeAge({target}){
        this.setState({age: target.value})
    }
    changeSex({target}){
        this.setState({sex: target.value})
    }
    changeActivityLevel(e){
		this.setState({activityLevel: e.target.value});
	}
	changeGoal(e){
		this.setState({goal: e.target.value});
	}	
	changeHeight(e){
		this.setState({height:e.target.value});
	}
	changeWeight(e){
		this.setState({weight: e.target.value,})
	}
	changeWeightInPounds(e){
		let lb = e.target.value;
		let kg = lb/2.20462;
		let multiplier = Math.pow(10, 1 || 0);
	    let roundedKg = Math.round(kg * multiplier) / multiplier;
		if(lb < 1400){
			this.setState({
				weight: roundedKg,
				pounds: lb,
				bmr: (this.state.height*6.25) + (kg*9.99) - (this.state.age*4.92) + 5,
			})
		}
		
	}
	changeConvertHeightMetric(feet, inches){
		let feetToCm = feet*30;
		let inchesToCm = inches*2.54;
		let convertedHeight = feetToCm + inchesToCm;
		this.convertHeightToMetric(convertedHeight)
	}
	convertHeightToMetric(convertedHeight){
		this.setState({height: convertedHeight})
	}
	changeFeetInput(e){
		let feet = e.target.value;
		this.setState({feet: feet})
		let feetToCm = feet*30;
		let inchesToCm = this.state.inches*2.54;
		let convertedHeight = feetToCm + inchesToCm;
		this.setState({height: convertedHeight})
	}
	changeInchesInput(e){
		let inches = e.target.value;
		this.setState({inches: inches})
		let feetToCm = this.state.feet*30;
		let inchesToCm = inches*2.54;
		let convertedHeight = feetToCm + inchesToCm;
		this.setState({height: convertedHeight})
	}
	changeMeasureMetric(){
		this.setState({metric: true})
		
	}
	changeMeasureImperial(){
		this.setState({metric: false})
		if(this.state.height.length > 0){
			let cm = this.state.height;
			let floor = (cm / 30.48).toFixed(0)
			let remainder = (cm % 30.48).toFixed(0);
			let multiplier = Math.pow(10, 1 || 0);
			let inches = remainder/2.54
			let roundedInches = Math.round(inches * multiplier) / multiplier;
			this.setState({
				feet: floor,
				inches: roundedInches,
			})
		}
		if(this.state.weight.length > 0){
			let kg = this.state.weight;
			let lb = Math.floor(kg * 2.204)
			this.setState({pounds: lb})
		}
		
	}
    render() {
		let metric = this.state.metric === true;
		let validate = this.state.validate === true;
		let ageInvalid = this.state.age.length === 0 && this.state.validate === true;
		let sexInvalid = this.state.sex.length === 0 && this.state.validate === true;
		let activityInvalid = this.state.activityLevel.length === 0 && this.state.validate === true;
		let heightInvalid = this.state.height.length === 0 && this.state.validate === true;
		let weightInvalid = this.state.weight.length === 0 && this.state.validate === true;
		let goalInvalid = this.state.goal.length === 0 && this.state.validate === true; 
        return (
            <div className="max-w-md mx-auto">
				<div className="w-full flex">
					<div className="px-2 inputWrapper w-6/12">
						<div className="inputSpacer">
							<label className="inputHeading text-center text-primary block" htmlFor="age">AGE</label>
							<div className="inputRadioWrapper">
								<input type="text" value={this.state.age} keyboardtype = 'numeric'name="age" className={ageInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeAge.bind(this)}/>
							</div>
						</div>
					</div>
				
					<div className="px-2 inputWrapper w-6/12">
						<h5 className="inputHeading text-center text-primary">SEX</h5>
						<div className={sexInvalid?"flex flex-wrap w-full items-center my-2 border-red-700 border error-shadow py-1":"flex flex-wrap w-full items-center my-2 py-1"}>
							<div className="inputRadioWrapper px-2 clearFix">
								<div className="radioContainer inline-block mr-2">
									<input type="radio" value="Male"  name="sex"  className="radioButton" onChange={this.changeSex.bind(this)}/>
									<span className="radioCheckmark"></span>
								</div> 
								<label htmlFor="male-sex">Male</label>
							</div>
							<div className="inputRadioWrapper px-2 clearFix">
								<div className="radioContainer inline-block mr-2">
									<input type="radio" value="Female"  name="sex"  className="radioButton" onChange={this.changeSex.bind(this)}/>
									<span className="radioCheckmark"></span>
								</div> 
								<label htmlFor="sex-female">Female</label>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-wrap w-full my-2">
					<div className="px-2 w-6/12">
						{metric?
						<label className="block text-primary text-center">WEIGHT <i>(kg)</i>
							<input type="text" value={this.state.weight} className={weightInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeWeight.bind(this)} placeholder="kg"/>
						</label>	
						:	
						<label className="block text-primary text-center">WEIGHT <i>(lb)</i>
							<input type="text" value={this.state.pounds} className={weightInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeWeightInPounds.bind(this)} placeholder="lb"/>
						</label>	
						}	
						
					</div>
					{metric?
					<div className="px-2 w-6/12">
						<label className="block text-center text-primary">HEIGHT <i>(cm)</i>
							<input type="text" value={this.state.height} className={heightInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeHeight.bind(this)} placeholder="cm" id="height-cm"/>
						</label>
					</div>
					:
					<div className="px-2 w-6/12">
						<h5 className="inputHeading text-center text-primary">HEIGHT <i>(ft)</i> </h5>
						<div className="flex">
							<div className="px-2 w-6/12">
								<input  type="text" keyboardtype = 'numeric' className={heightInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeFeetInput.bind(this)} value={this.state.feet} placeholder="ft"/>
							</div>
							<div className="px-2 w-6/12">
								<input type="text" keyboardtype = 'numeric' className={heightInvalid?'formControl is-invalid text-black':'text-black formControl'} onChange={this.changeInchesInput.bind(this)} value={this.state.inches} placeholder="in"/>
							</div>
						</div>
					</div>
					}
				</div>
				<div className="my-4 px-2 flex w-full pb-4">
						
					<button onClick={this.changeMeasureMetric.bind(this)} 
						className={metric?'bg-primary py-2 mx-1 text-xs px-2 rounded-lg text-white w-6/12':'bg-white py-2 mx-1 text-xs px-2 rounded-lg text-primary w-6/12'}
					>Metric</button>
				
					<button onClick={this.changeMeasureImperial.bind(this)} 
						className={metric?'bg-white py-2 mx-1 text-xs px-2 rounded-lg text-primary w-6/12':'bg-primary py-2 mx-1 text-xs px-2 rounded-lg text-white w-6/12'}
					>Imperial</button>
						
				</div>
				<div className={activityInvalid?"pt-6 mt-4 border border-red-700 error-shadow ": " rounded-md pt-6 mt-4"}>
					<h5 className="text-center text-primary">ACTIVITY LEVEL</h5>
					<ul className="activityInputList md:flex flex-wrap my-4">
						<li className="w-full md:w-6/12 flex px-2">
							<div className="radioContainer inline-block mr-1 w-4 h-4">
								<input type="radio" value="Sedentary" name="activityLevel" className="radioButton" onChange={this.changeActivityLevel.bind(this)}/>
								<span className="radioCheckmark"></span>
							</div> 
							<div className="inline-block ml-4">
							<label htmlFor="sedentary-activity">Sedentary</label>
							<p className="text-xs">No regular exercise</p>
							</div>
						</li>
						<li className="w-full md:w-6/12 flex px-2">
							<div className="radioContainer inline-block mr-1 w-4 h-4">
								<input type="radio" value="Lightly Active" name="activityLevel" className="radioButton" onChange={this.changeActivityLevel.bind(this)}  id="light-activity"/>
								<span className="radioCheckmark"></span>
							</div> 
							<div className="inline-block ml-4">
								<label htmlFor="light-activity">Lightly Active</label>
								<p className="text-xs">You exercise three times per week</p>
							</div>
						</li>
						<li className="w-full md:w-6/12 flex px-2">
							<div className="radioContainer inline-block mr-1 w-4 h-4">
								<input  
									type="radio"
									value="Moderately Active" 
									name="activityLevel"
									className="radioButton"
									onChange={this.changeActivityLevel.bind(this)} 
								/>
								<span className="radioCheckmark"></span>
							</div>
							<div className="inline-block ml-4">
								<label htmlFor="moderate-activity">Moderately Active</label>
								<p className="text-xs">You exercise three to five times per week</p>
							</div>
						</li>
						<li className="w-full md:w-6/12 flex px-2">
							<div className="radioContainer inline-block mr-1 w-4 h-4">
								<input type="radio"value="Very Active" name="activityLevel" className="radioButton" onChange={this.changeActivityLevel.bind(this)}/>
								<span className="radioCheckmark"></span>
							</div> 
							<div className="inline-block ml-4">
								<label htmlFor="high-activity">Very Active</label>
								<p className="text-xs">You exercise five times or more per week</p>
							</div>
						</li>
					</ul>
				</div>
				<div className="my-4">
					<h5 className="text-primary text-center mb-2">GOAL</h5>
					<div className={goalInvalid?"flex w-full items-center py-4 border border-red-700 error-shadow":"flex w-full items-center py-4"}>
						<div className="inputRadioWrapper px-4 ">
							<div className="">
								<div className="radioContainer relative w-4 h-4">
									<input type="radio" value="Lose Weight" name="goal" className="radioButton "  onChange={this.changeGoal.bind(this)}/>
								<span className="radioCheckmark"></span>
								</div>
							</div>
							
						</div>
						<div className="inline-block ml-1"><label htmlFor="lose-weight-goal">Lose Weight</label></div>
						<div className="inputRadioWrapper px-4 ">
							<div className="block">
								<div className="radioContainer w-4 h-4">
									<input type="radio" value="Maintain" name="goal" className="radioButton w-4 h-4 bg-indigo-400" onChange={this.changeGoal.bind(this)}/>
									<span className="radioCheckmark"></span>
								</div> 
								
							</div> 
							
						</div>
						<div className="inline-block ml-1"><label htmlFor="maintain-goal">Maintain</label></div>
						<div className="inputRadioWrapper px-4 ">
							<div className="inputRadioWrapper px-2 clearFix">
								<div className="radioContainer w-4 h-4">
									<input type="radio" value="Gain Muscle" name="goal" className="radioButton w-4 h-4 bg-indigo-400" onChange={this.changeGoal.bind(this)}/>
									<span className="radioCheckmark"></span>
								</div> 
							</div> 
							
						</div>
						<div className="inline-block ml-1"><label htmlFor="gain-muscle-goal">Gain Muscle</label></div>
					</div>
					{validate&&
						<div className="p-2 bg-red-600 text-white font-bold text-center my-4">Please complete all fields first</div>
					}
					<button onClick={this.submitForm.bind(this)} className="rounded-lg bg-primary text-white py-2 px-6 mx-auto w-full">
						Calculate
					</button>
            	</div>
            </div>
        )
    }
}
const ConnectedInputs = connect(null,mapDispatchToProps)(Inputs);

export default ConnectedInputs;