export const MetTable = [
  {
    "value": "01003",
    "met": 14,
    "name": "bicycling, mountain, uphill, vigorous"
  },
  {
    "value": "01009",
    "met": 8.5,
    "name": "bicycling, mountain, general"
  },
  {
    "value": "01010",
    "met": 4,
    "name": "bicycling, <10 mph, leisure, to work or for pleasure (Taylor Code 115)"
  },
  {
    "value": "01011",
    "met": 6.8,
    "name": "bicycling, to/from work, self selected pace"
  },
  {
    "value": "01013",
    "met": 5.8,
    "name": "bicycling, on dirt or farm road, moderate pace"
  },
  {
    "value": "01018",
    "met": 3.5,
    "name": "bicycling, leisure, 5.5 mph"
  },
  {
    "value": "01019",
    "met": 5.8,
    "name": "bicycling, leisure, 9.4 mph"
  },
  {
    "value": "01020",
    "met": 6.8,
    "name": "bicycling, 10-11.9 mph, leisure, slow, light effort"
  },
  {
    "value": "01030",
    "met": 8,
    "name": "bicycling, 12-13.9 mph, leisure, moderate effort"
  },
  {
    "value": "01040",
    "met": 10,
    "name": "bicycling, 14-15.9 mph, racing or leisure, fast, vigorous effort"
  },
  {
    "value": "01050",
    "met": 12,
    "name": "bicycling, 16-19 mph, racing/not drafting or > 19 mph drafting, very fast, racing general"
  },
  {
    "value": "01060",
    "met": 15.8,
    "name": "bicycling, > 20 mph, racing, not drafting"
  },
  {
    "value": "01065",
    "met": 8.5,
    "name": "bicycling, 12 mph, seated, hands on brake hoods or bar drops, 80 rpm"
  },
  {
    "value": "01066",
    "met": 9,
    "name": "bicycling, 12 mph, standing, hands on brake hoods, 60 rpm"
  },
  {
    "value": "01070",
    "met": 5,
    "name": "unicycling"
  },
  {
    "value": "02008",
    "met": 5,
    "name": "army type obstacle course exercise, boot camp training program"
  },
  {
    "value": "02010",
    "met": 7,
    "name": "bicycling, stationary, general"
  },
  {
    "value": "02011",
    "met": 3.5,
    "name": "bicycling, stationary, 30-50 watts, very light to light effort"
  },
  {
    "value": "02012",
    "met": 6.8,
    "name": "bicycling, stationary, 90-100 watts, moderate to vigorous effort"
  },
  {
    "value": "02013",
    "met": 8.8,
    "name": "bicycling, stationary, 101-160 watts, vigorous effort"
  },
  {
    "value": "02014",
    "met": 11,
    "name": "bicycling, stationary, 161-200 watts, vigorous effort"
  },
  {
    "value": "02015",
    "met": 14,
    "name": "bicycling, stationary, 201-270 watts, very vigorous effort"
  },
  {
    "value": "02017",
    "met": 4.8,
    "name": "bicycling, stationary, 51-89 watts, light-to-moderate effort"
  },
  {
    "value": "02019",
    "met": 8.5,
    "name": "bicycling, stationary, RPM/Spin bike class"
  },
  {
    "value": "02020",
    "met": 8,
    "name": "calisthenics (e.g., push ups, sit ups, pull-ups, jumping jacks), vigorous effort"
  },
  {
    "value": "02022",
    "met": 3.8,
    "name": "calisthenics (e.g., push ups, sit ups, pull-ups, lunges), moderate effort"
  },
  {
    "value": "02024",
    "met": 2.8,
    "name": "calisthenics (e.g., situps, abdominal crunches), light effort"
  },
  {
    "value": "02030",
    "met": 3.5,
    "name": "calisthenics, light or moderate effort, general (e.g., back exercises), going up & down from floor (Taylor Code 150)"
  },
  {
    "value": "02035",
    "met": 4.3,
    "name": "circuit training, moderate effort"
  },
  {
    "value": "02040",
    "met": 8,
    "name": "circuit training, including kettlebells, some aerobic movement with minimal rest, general, vigorous intensity"
  },
  {
    "value": "02045",
    "met": 3.5,
    "name": "Curves  exercise routines in women"
  },
  {
    "value": "02048",
    "met": 5,
    "name": "Elliptical trainer, moderate effort"
  },
  {
    "value": "02050",
    "met": 6,
    "name": "resistance training (weight lifting, free weight, nautilus or universal), power lifting or body building, vigorous effort (Taylor Code 210)"
  },
  {
    "value": "02052",
    "met": 5,
    "name": "resistance (weight) training, squats , slow or explosive effort"
  },
  {
    "value": "02054",
    "met": 3.5,
    "name": "resistance (weight) training, multiple exercises, 8-15 repetitions at varied resistance"
  },
  {
    "value": "02060",
    "met": 5.5,
    "name": "health club exercise, general (Taylor Code 160)"
  },
  {
    "value": "02061",
    "met": 5,
    "name": "health club exercise classes, general, gym/weight training combined in one visit"
  },
  {
    "value": "02062",
    "met": 7.8,
    "name": "health club exercise, conditioning classes"
  },
  {
    "value": "02065",
    "met": 9,
    "name": "stair-treadmill ergometer, general"
  },
  {
    "value": "02068",
    "met": 12.3,
    "name": "rope skipping, general"
  },
  {
    "value": "02070",
    "met": 6,
    "name": "rowing, stationary ergometer, general, vigorous effort"
  },
  {
    "value": "02071",
    "met": 4.8,
    "name": "rowing, stationary, general, moderate effort"
  },
  {
    "value": "02072",
    "met": 7,
    "name": "rowing, stationary, 100 watts, moderate effort"
  },
  {
    "value": "02073",
    "met": 8.5,
    "name": "rowing, stationary, 150 watts, vigorous effort"
  },
  {
    "value": "02074",
    "met": 12,
    "name": "rowing, stationary, 200 watts, very vigorous effort"
  },
  {
    "value": "02080",
    "met": 6.8,
    "name": "ski machine, general"
  },
  {
    "value": "02085",
    "met": 11,
    "name": "slide board exercise, general"
  },
  {
    "value": "02090",
    "met": 6,
    "name": "slimnastics, jazzercise"
  },
  {
    "value": "02101",
    "met": 2.3,
    "name": "stretching, mild"
  },
  {
    "value": "02105",
    "met": 3,
    "name": "pilates, general"
  },
  {
    "value": "02112",
    "met": 2.8,
    "name": "therapeutic exercise ball, Fitball exercise"
  },
  {
    "value": "02115",
    "met": 2.8,
    "name": "upper body exercise, arm ergometer"
  },
  {
    "value": "02117",
    "met": 4.3,
    "name": "upper body exercise, stationary bicycle - Airdyne (arms only) 40 rpm, moderate"
  },
  {
    "value": "02120",
    "met": 5.3,
    "name": "water aerobics, water calisthenics, water exercise"
  },
  {
    "value": "02135",
    "met": 1.3,
    "name": "whirlpool, sitting"
  },
  {
    "value": "02150",
    "met": 2.5,
    "name": "yoga, Hatha"
  },
  {
    "value": "02160",
    "met": 4,
    "name": "yoga, Power"
  },
  {
    "value": "02170",
    "met": 2,
    "name": "yoga, Nadisodhana"
  },
  {
    "value": "02180",
    "met": 3.3,
    "name": "yoga, Surya Namaskar"
  },
  {
    "value": "02200",
    "met": 5.3,
    "name": "native New Zealander physical activities (e.g., Haka Powhiri, Moteatea, Waita Tira, Whakawatea, etc.), general, moderate effort"
  },
  {
    "value": "02205",
    "met": 6.8,
    "name": "native New Zealander physical activities (e.g., Haka, Taiahab), general, vigorous effort"
  },
  {
    "value": "03010",
    "met": 5,
    "name": "ballet, modern, or jazz, general, rehearsal or class"
  },
  {
    "value": "03012",
    "met": 6.8,
    "name": "ballet, modern, or jazz, performance, vigorous effort"
  },
  {
    "value": "03014",
    "met": 4.8,
    "name": "tap"
  },
  {
    "value": "03015",
    "met": 7.3,
    "name": "aerobic, general"
  },
  {
    "value": "03016",
    "met": 7.5,
    "name": "aerobic, step, with 6 - 8 inch step"
  },
  {
    "value": "03017",
    "met": 9.5,
    "name": "aerobic, step, with 10 - 12 inch step"
  },
  {
    "value": "03018",
    "met": 5.5,
    "name": "aerobic, step, with 4-inch step"
  },
  {
    "value": "03019",
    "met": 8.5,
    "name": "bench step class, general"
  },
  {
    "value": "03020",
    "met": 5,
    "name": "aerobic, low impact"
  },
  {
    "value": "03021",
    "met": 7.3,
    "name": "aerobic, high impact"
  },
  {
    "value": "03022",
    "met": 10,
    "name": "aerobic dance wearing 10-15 lb weights"
  },
  {
    "value": "03025",
    "met": 4.5,
    "name": "ethnic or cultural dancing (e.g., Greek, Middle Eastern, hula, salsa, merengue, bamba y plena, flamenco, belly, and swing)"
  },
  {
    "value": "03030",
    "met": 5.5,
    "name": "ballroom, fast (Taylor Code 125)"
  },
  {
    "value": "03031",
    "met": 7.8,
    "name": "general dancing (e.g., disco, folk, Irish step dancing, line dancing, polka, contra, country)"
  },
  {
    "value": "03038",
    "met": 11.3,
    "name": "ballroom dancing, competitive, general"
  },
  {
    "value": "03040",
    "met": 3,
    "name": "ballroom, slow (e.g., waltz, foxtrot, slow dancing, samba, tango, 19 th century dance, mambo, cha cha)"
  },
  {
    "value": "03050",
    "met": 5.5,
    "name": "Anishinaabe Jingle Dancing"
  },
  {
    "value": "03060",
    "met": 3.5,
    "name": "Caribbean dance (Abakua, Beguine, Bellair, Bongo, Brukin's, Caribbean Quadrills, Dinki Mini, Gere, Gumbay, Ibo, Jonkonnu, Kumina, Oreisha, Jambu)"
  },
  {
    "value": 11791,
    "met": 2,
    "name": "walking on job, less than 2.0 mph, very slow speed, in office or lab area"
  },
  {
    "value": 11792,
    "met": 3.5,
    "name": "walking on job, 3.0 mph, in office, moderate speed, not carrying anything"
  },
  {
    "value": 11793,
    "met": 4.3,
    "name": "walking on job, 3.5 mph, in office, brisk speed, not carrying anything"
  },
  {
    "value": 11795,
    "met": 3.5,
    "name": "walking on job, 2.5 mph, slow speed and carrying light objects less than 25 lbs"
  },
  {
    "value": 11796,
    "met": 3,
    "name": "walking, gathering things at work, ready to leave"
  },
  {
    "value": 11797,
    "met": 3.8,
    "name": "walking, 2.5 mph, slow speed, carrying heavy objects more than 25 lbs"
  },
  {
    "value": 11800,
    "met": 4.5,
    "name": "walking, 3.0 mph, moderately and carrying light objects less than 25 lbs"
  },
  {
    "value": 11805,
    "met": 3.5,
    "name": "walking, pushing a wheelchair"
  },
  {
    "value": 11810,
    "met": 4.8,
    "name": "walking, 3.5 mph, briskly and carrying objects less than 25 lbs"
  },
  {
    "value": 11820,
    "met": 5,
    "name": "walking or walk downstairs or standing, carrying objects about 25 to 49 lbs"
  },
  {
    "value": 11830,
    "met": 6.5,
    "name": "walking or walk downstairs or standing, carrying objects about 50 to 74 lbs"
  },
  {
    "value": 11840,
    "met": 7.5,
    "name": "walking or walk downstairs or standing, carrying objects about 75 to 99 lbs"
  },
  {
    "value": 11850,
    "met": 8.5,
    "name": "walking or walk downstairs or standing, carrying objects about 100 lbs or more"
  },
  {
    "value": 11870,
    "met": 3,
    "name": "working in scene shop, theater actor, backstage employee"
  },
  {
    "value": 12010,
    "met": 6,
    "name": "jog/walk combination (jogging component of less than 10 minutes) (Taylor Code 180)"
  },
  {
    "value": 12020,
    "met": 7,
    "name": "jogging, general"
  },
  {
    "value": 12025,
    "met": 8,
    "name": "jogging, in place"
  },
  {
    "value": 12027,
    "met": 4.5,
    "name": "jogging, on a mini-tramp"
  },
  {
    "value": 12029,
    "met": 6,
    "name": "Running, 4 mph (13 min/mile)"
  },
  {
    "value": 12030,
    "met": 8.3,
    "name": "running, 5 mph (12 min/mile)"
  },
  {
    "value": 12040,
    "met": 9,
    "name": "running, 5.2 mph (11.5 min/mile)"
  },
  {
    "value": 12050,
    "met": 9.8,
    "name": "running, 6 mph (10 min/mile)"
  },
  {
    "value": 12060,
    "met": 10.5,
    "name": "running, 6.7 mph (9 min/mile)"
  },
  {
    "value": 12070,
    "met": 11,
    "name": "running, 7 mph (8.5 min/mile)"
  },
  {
    "value": 12080,
    "met": 11.5,
    "name": "running, 7.5 mph (8 min/mile)"
  },
  {
    "value": 12090,
    "met": 11.8,
    "name": "running, 8 mph (7.5 min/mile)"
  },
  {
    "value": 12100,
    "met": 12.3,
    "name": "running, 8.6 mph (7 min/mile)"
  },
  {
    "value": 12110,
    "met": 12.8,
    "name": "running, 9 mph (6.5 min/mile)"
  },
  {
    "value": 12120,
    "met": 14.5,
    "name": "running, 10 mph (6 min/mile)"
  },
  {
    "value": 12130,
    "met": 16,
    "name": "running, 11 mph (5.5 min/mile)"
  },
  {
    "value": 12132,
    "met": 19,
    "name": "running, 12 mph (5 min/mile)"
  },
  {
    "value": 12134,
    "met": 19.8,
    "name": "running, 13 mph (4.6 min/mile)"
  },
  {
    "value": 12135,
    "met": 23,
    "name": "running, 14 mph (4.3 min/mile)"
  },
  {
    "value": 12140,
    "met": 9,
    "name": "running, cross country"
  },
  {
    "value": 12150,
    "met": 8,
    "name": "running, (Taylor code 200)"
  },
  {
    "value": 12170,
    "met": 15,
    "name": "running, stairs, up"
  },
  {
    "value": 12180,
    "met": 10,
    "name": "running, on a track, team practice"
  },
  {
    "value": 12190,
    "met": 8,
    "name": "running, training, pushing a wheelchair or baby carrier"
  },
  {
    "value": 12200,
    "met": 13.3,
    "name": "running, marathon"
  },
  {
    "value": 13000,
    "met": 2.3,
    "name": "getting ready for bed, general, standing"
  },
  {
    "value": 13009,
    "met": 1.8,
    "name": "sitting on toilet, eliminating while standing or squating"
  },
  {
    "value": 13010,
    "met": 1.5,
    "name": "bathing, sitting"
  },
  {
    "value": 13020,
    "met": 2.5,
    "name": "dressing, undressing, standing or sitting"
  },
  {
    "value": 13030,
    "met": 1.5,
    "name": "eating, sitting"
  },
  {
    "value": 13035,
    "met": 2,
    "name": "talking and eating or eating only, standing"
  },
  {
    "value": 13036,
    "met": 1.5,
    "name": "taking medication, sitting or standing"
  },
  {
    "value": 13040,
    "met": 2,
    "name": "grooming, washing hands, shaving, brushing teeth, putting on make-up, sitting or standing"
  },
  {
    "value": 13045,
    "met": 2.5,
    "name": "hairstyling, standing"
  },
  {
    "value": 13046,
    "met": 1.3,
    "name": "having hair or nails done by someone else, sitting"
  },
  {
    "value": 13050,
    "met": 2,
    "name": "showering, toweling off, standing"
  },
  {
    "value": 14010,
    "met": 2.8,
    "name": "active, vigorous effort"
  },
  {
    "value": 14020,
    "met": 1.8,
    "name": "general, moderate effort"
  },
  {
    "value": 14030,
    "met": 1.3,
    "name": "passive, light effort, kissing, hugging"
  },
  {
    "value": 15000,
    "met": 5.5,
    "name": "Alaska Native Games, Eskimo Olympics, general"
  },
  {
    "value": 15010,
    "met": 4.3,
    "name": "archery, non-hunting"
  },
  {
    "value": 15020,
    "met": 7,
    "name": "badminton, competitive (Taylor Code 450)"
  },
  {
    "value": 15030,
    "met": 5.5,
    "name": "badminton, social singles and doubles, general"
  },
  {
    "value": 15040,
    "met": 8,
    "name": "basketball, game (Taylor Code 490)"
  },
  {
    "value": 15050,
    "met": 6,
    "name": "basketball, non-game, general (Taylor Code 480)"
  },
  {
    "value": 15055,
    "met": 6.5,
    "name": "basketball, general"
  },
  {
    "value": 15060,
    "met": 7,
    "name": "basketball, officiating (Taylor Code 500)"
  },
  {
    "value": 15070,
    "met": 4.5,
    "name": "basketball, shooting baskets"
  },
  {
    "value": 15072,
    "met": 9.3,
    "name": "basketball, drills, practice"
  },
  {
    "value": 15075,
    "met": 7.8,
    "name": "basketball, wheelchair"
  },
  {
    "value": 15080,
    "met": 2.5,
    "name": "billiards"
  },
  {
    "value": 15090,
    "met": 3,
    "name": "bowling (Taylor Code 390)"
  },
  {
    "value": 15092,
    "met": 3.8,
    "name": "bowling, indoor, bowling alley"
  },
  {
    "value": 15100,
    "met": 12.8,
    "name": "boxing, in ring, general"
  },
  {
    "value": 15110,
    "met": 5.5,
    "name": "boxing, punching bag"
  },
  {
    "value": 15120,
    "met": 7.8,
    "name": "boxing, sparring"
  },
  {
    "value": 15130,
    "met": 7,
    "name": "broomball"
  },
  {
    "value": 15135,
    "met": 5.8,
    "name": "children?s games, adults playing (e.g., hopscotch, 4-square, dodgeball, playground apparatus, t-ball, tetherball, marbles, arcade games), moderate effort"
  },
  {
    "value": 15138,
    "met": 6,
    "name": "cheerleading, gymnastic moves, competitive"
  },
  {
    "value": 15140,
    "met": 4,
    "name": "coaching, football, soccer, basketball, baseball, swimming, etc."
  },
  {
    "value": 15142,
    "met": 8,
    "name": "coaching, actively playing sport with players"
  },
  {
    "value": 15150,
    "met": 4.8,
    "name": "cricket, batting, bowling, fielding"
  },
  {
    "value": 15160,
    "met": 3.3,
    "name": "croquet"
  },
  {
    "value": 15170,
    "met": 4,
    "name": "curling"
  },
  {
    "value": 15180,
    "met": 2.5,
    "name": "darts, wall or lawn"
  },
  {
    "value": 15190,
    "met": 6,
    "name": "drag racing, pushing or driving a car"
  },
  {
    "value": 15192,
    "met": 8.5,
    "name": "auto racing, open wheel"
  },
  {
    "value": 15200,
    "met": 6,
    "name": "fencing"
  },
  {
    "value": 15210,
    "met": 8,
    "name": "football, competitive"
  },
  {
    "value": 15230,
    "met": 8,
    "name": "football, touch, flag, general (Taylor Code 510)"
  },
  {
    "value": 15232,
    "met": 4,
    "name": "football, touch, flag, light effort"
  },
  {
    "value": 15235,
    "met": 2.5,
    "name": "football or baseball, playing catch"
  },
  {
    "value": 15240,
    "met": 3,
    "name": "frisbee playing, general"
  },
  {
    "value": 15250,
    "met": 8,
    "name": "frisbee, ultimate"
  },
  {
    "value": 15255,
    "met": 4.8,
    "name": "golf, general"
  },
  {
    "value": 15265,
    "met": 4.3,
    "name": "golf, walking, carrying clubs"
  },
  {
    "value": 15270,
    "met": 3,
    "name": "golf, miniature, driving range"
  },
  {
    "value": 15285,
    "met": 5.3,
    "name": "golf, walking, pulling clubs"
  },
  {
    "value": 15290,
    "met": 3.5,
    "name": "golf, using power cart (Taylor Code 070)"
  },
  {
    "value": 15300,
    "met": 3.8,
    "name": "gymnastics, general"
  },
  {
    "value": 15310,
    "met": 4,
    "name": "hacky sack"
  },
  {
    "value": 15320,
    "met": 12,
    "name": "handball, general (Taylor Code 520)"
  },
  {
    "value": 15330,
    "met": 8,
    "name": "handball, team"
  },
  {
    "value": 15335,
    "met": 4,
    "name": "high ropes course, multiple elements"
  },
  {
    "value": 15340,
    "met": 3.5,
    "name": "hang gliding"
  },
  {
    "value": 15350,
    "met": 7.8,
    "name": "hockey, field"
  },
  {
    "value": 15360,
    "met": 8,
    "name": "hockey, ice, general"
  },
  {
    "value": 15362,
    "met": 10,
    "name": "hockey, ice, competitive"
  },
  {
    "value": 15370,
    "met": 5.5,
    "name": "horseback riding, general"
  },
  {
    "value": 15375,
    "met": 4.3,
    "name": "horse chores, feeding, watering, cleaning stalls, implied walking and lifting loads"
  },
  {
    "value": 15380,
    "met": 4.5,
    "name": "saddling, cleaning, grooming, harnessing and unharnessing horse"
  },
  {
    "value": 15390,
    "met": 5.8,
    "name": "horseback riding, trotting"
  },
  {
    "value": 15395,
    "met": 7.3,
    "name": "horseback riding, canter or gallop"
  },
  {
    "value": 15400,
    "met": 3.8,
    "name": "horseback riding,walking"
  },
  {
    "value": 15402,
    "met": 9,
    "name": "horseback riding, jumping"
  },
  {
    "value": 15408,
    "met": 1.8,
    "name": "horse cart, driving, standing or sitting"
  },
  {
    "value": 15410,
    "met": 3,
    "name": "horseshoe pitching, quoits"
  },
  {
    "value": 15420,
    "met": 12,
    "name": "jai alai"
  },
  {
    "value": 15425,
    "met": 5.3,
    "name": "martial arts, different types, slower pace, novice performers, practice"
  },
  {
    "value": 15430,
    "met": 10.3,
    "name": "martial arts, different types, moderate pace (e.g., judo, jujitsu, karate, kick boxing, tae kwan do, tai-bo, Muay Thai boxing)"
  },
  {
    "value": 15440,
    "met": 4,
    "name": "juggling"
  },
  {
    "value": 15450,
    "met": 7,
    "name": "kickball"
  },
  {
    "value": 15460,
    "met": 8,
    "name": "lacrosse"
  },
  {
    "value": 15465,
    "met": 3.3,
    "name": "lawn bowling, bocce ball, outdoor"
  },
  {
    "value": 15470,
    "met": 4,
    "name": "moto-cross, off-road motor sports, all-terrain vehicle, general"
  },
  {
    "value": 15480,
    "met": 9,
    "name": "orienteering"
  },
  {
    "value": 15490,
    "met": 10,
    "name": "paddleball, competitive"
  },
  {
    "value": 15500,
    "met": 6,
    "name": "paddleball, casual, general (Taylor Code 460)"
  },
  {
    "value": 15510,
    "met": 8,
    "name": "polo, on horseback"
  },
  {
    "value": 15520,
    "met": 10,
    "name": "racquetball, competitive"
  },
  {
    "value": 15530,
    "met": 7,
    "name": "racquetball, general (Taylor Code 470)"
  },
  {
    "value": 15533,
    "met": 8,
    "name": "rock or mountain climbing (Taylor Code 470) (Formerly code = 17120)"
  },
  {
    "value": 15535,
    "met": 7.5,
    "name": "rock climbing, ascending rock, high difficulty"
  },
  {
    "value": 15537,
    "met": 5.8,
    "name": "rock climbing, ascending or traversing rock, low-to-moderate difficulty"
  },
  {
    "value": 15540,
    "met": 5,
    "name": "rock climbing, rappelling"
  },
  {
    "value": 15542,
    "met": 4,
    "name": "rodeo sports, general, light effort"
  },
  {
    "value": 15544,
    "met": 5.5,
    "name": "rodeo sports, general, moderate effort"
  },
  {
    "value": 15546,
    "met": 7,
    "name": "rodeo sports, general, vigorous effort"
  },
  {
    "value": 15550,
    "met": 12.3,
    "name": "rope jumping, fast pace, 120-160 skips/min"
  },
  {
    "value": 15551,
    "met": 11.8,
    "name": "rope jumping, moderate pace, 100-120 skips/min, general,"
  },
  {
    "value": 15552,
    "met": 8.8,
    "name": "rope jumping, slow pace, < 100 skips/min, 2 foot skip, rhythm bounce"
  },
  {
    "value": 15560,
    "met": 8.3,
    "name": "rugby, union, team, competitive"
  },
  {
    "value": 15562,
    "met": 6.3,
    "name": "rugby, touch, non-competitive"
  },
  {
    "value": 15570,
    "met": 3,
    "name": "shuffleboard"
  },
  {
    "value": 15580,
    "met": 5,
    "name": "skateboarding, general, moderate effort"
  },
  {
    "value": 15582,
    "met": 6,
    "name": "skateboarding, competitive, vigorous effort"
  },
  {
    "value": 15590,
    "met": 7,
    "name": "skating, roller (Taylor Code 360)"
  },
  {
    "value": 15591,
    "met": 7.5,
    "name": "rollerblading, in-line skating, 14.4 km/h (9.0 mph), recreational pace"
  },
  {
    "value": 15592,
    "met": 9.8,
    "name": "rollerblading, in-line skating, 17.7 km/h (11.0 mph), moderate pace, exercise training"
  },
  {
    "value": 15593,
    "met": 12.3,
    "name": "rollerblading, in-line skating, 21.0 to 21.7 km/h (13.0 to 13.6 mph), fast pace, exercise training"
  },
  {
    "value": 15594,
    "met": 14,
    "name": "rollerblading, in-line skating, 24.0 km/h (15.0 mph), maximal effort"
  },
  {
    "value": 15600,
    "met": 3.5,
    "name": "skydiving, base jumping, bungee jumping"
  },
  {
    "value": 15605,
    "met": 10,
    "name": "soccer, competitive"
  },
  {
    "value": 15610,
    "met": 7,
    "name": "soccer, casual, general (Taylor Code 540)"
  },
  {
    "value": 15620,
    "met": 5,
    "name": "softball or baseball, fast or slow pitch, general (Taylor Code 440)"
  },
  {
    "value": 15625,
    "met": 4,
    "name": "softball, practice"
  },
  {
    "value": 15630,
    "met": 4,
    "name": "softball, officiating"
  },
  {
    "value": 15640,
    "met": 6,
    "name": "softball,pitching"
  },
  {
    "value": 15645,
    "met": 3.3,
    "name": "sports spectator, very excited, emotional, physically moving"
  },
  {
    "value": 15650,
    "met": 12,
    "name": "squash (Taylor Code 530)"
  },
  {
    "value": 15652,
    "met": 7.3,
    "name": "squash, general"
  },
  {
    "value": 15660,
    "met": 4,
    "name": "table tennis, ping pong (Taylor Code 410)"
  },
  {
    "value": 15670,
    "met": 3,
    "name": "tai chi, qi gong, general"
  },
  {
    "value": 15672,
    "met": 1.5,
    "name": "tai chi, qi gong, sitting, light effort"
  },
  {
    "value": 15675,
    "met": 7.3,
    "name": "tennis, general"
  },
  {
    "value": 15680,
    "met": 6,
    "name": "tennis, doubles (Taylor Code 430)"
  },
  {
    "value": 15685,
    "met": 4.5,
    "name": "tennis, doubles"
  },
  {
    "value": 15690,
    "met": 8,
    "name": "tennis, singles (Taylor Code 420)"
  },
  {
    "value": 15695,
    "met": 5,
    "name": "tennis, hitting balls, non-game play, moderate effort"
  },
  {
    "value": 15700,
    "met": 3.5,
    "name": "trampoline, recreational"
  },
  {
    "value": 15702,
    "met": 4.5,
    "name": "trampoline, competitive"
  },
  {
    "value": 15710,
    "met": 4,
    "name": "volleyball (Taylor Code 400)"
  },
  {
    "value": 15711,
    "met": 6,
    "name": "volleyball, competitive, in gymnasium"
  },
  {
    "value": 15720,
    "met": 3,
    "name": "volleyball, non-competitive, 6 - 9 member team, general"
  },
  {
    "value": 15725,
    "met": 8,
    "name": "volleyball, beach, in sand"
  },
  {
    "value": 15730,
    "met": 6,
    "name": "wrestling (one match = 5 minutes)"
  },
  {
    "value": 15731,
    "met": 7,
    "name": "wallyball, general"
  },
  {
    "value": 15732,
    "met": 4,
    "name": "track and field (e.g., shot, discus, hammer throw)"
  },
  {
    "value": 15733,
    "met": 6,
    "name": "track and field (e.g., high jump, long jump, triple jump, javelin, pole vault)"
  },
  {
    "value": 15734,
    "met": 10,
    "name": "track and field (e.g., steeplechase, hurdles)"
  },
  {
    "value": 18090,
    "met": 3,
    "name": "diving, springboard or platform"
  },
  {
    "value": 18100,
    "met": 5,
    "name": "kayaking, moderate effort"
  },
  {
    "value": 18110,
    "met": 4,
    "name": "paddle boat"
  },
  {
    "value": 18120,
    "met": 3,
    "name": "sailing, boat and board sailing, windsurfing, ice sailing, general (Taylor Code 235)"
  },
  {
    "value": 18130,
    "met": 4.5,
    "name": "sailing, in competition"
  },
  {
    "value": 18140,
    "met": 3.3,
    "name": "sailing, Sunfish/Laser/Hobby Cat, Keel boats, ocean sailing, yachting, leisure"
  },
  {
    "value": 18150,
    "met": 6,
    "name": "skiing, water or wakeboarding (Taylor Code 220)"
  },
  {
    "value": 18160,
    "met": 7,
    "name": "jet skiing, driving, in water"
  },
  {
    "value": 18180,
    "met": 15.8,
    "name": "skindiving, fast"
  },
  {
    "value": 18190,
    "met": 11.8,
    "name": "skindiving, moderate"
  },
  {
    "value": 18200,
    "met": 7,
    "name": "skindiving, scuba diving, general (Taylor Code 310)"
  },
  {
    "value": 18210,
    "met": 5,
    "name": "snorkeling (Taylor Code 310)"
  },
  {
    "value": 18220,
    "met": 3,
    "name": "surfing, body or board, general"
  },
  {
    "value": 18222,
    "met": 5,
    "name": "surfing, body or board, competitive"
  },
  {
    "value": 18225,
    "met": 6,
    "name": "paddle boarding, standing"
  },
  {
    "value": 18230,
    "met": 9.8,
    "name": "swimming laps, freestyle, fast, vigorous effort"
  },
  {
    "value": 18240,
    "met": 5.8,
    "name": "swimming laps, freestyle, front crawl, slow, light or moderate effort"
  },
  {
    "value": 18250,
    "met": 9.5,
    "name": "swimming, backstroke, general, training or competition"
  },
  {
    "value": 18255,
    "met": 4.8,
    "name": "swimming, backstroke, recreational"
  },
  {
    "value": 18260,
    "met": 10.3,
    "name": "swimming, breaststroke, general, training or competition"
  },
  {
    "value": 18265,
    "met": 5.3,
    "name": "swimming, breaststroke, recreational"
  },
  {
    "value": 18270,
    "met": 13.8,
    "name": "swimming, butterfly, general"
  },
  {
    "value": 18280,
    "met": 10,
    "name": "swimming, crawl, fast speed, ~75 yards/minute, vigorous effort"
  },
  {
    "value": 18290,
    "met": 8.3,
    "name": "swimming, crawl, medium speed, ~50 yards/minute, vigorous effort"
  },
  {
    "value": 18300,
    "met": 6,
    "name": "swimming, lake, ocean, river (Taylor Codes 280, 295)"
  },
  {
    "value": 18310,
    "met": 6,
    "name": "swimming, leisurely, not lap swimming, general"
  },
  {
    "value": 18320,
    "met": 7,
    "name": "swimming, sidestroke, general"
  },
  {
    "value": 18330,
    "met": 8,
    "name": "swimming, synchronized"
  },
  {
    "value": 18340,
    "met": 9.8,
    "name": "swimming, treading water, fast, vigorous effort"
  },
  {
    "value": 18350,
    "met": 3.5,
    "name": "swimming, treading water, moderate effort, general"
  },
  {
    "value": 18352,
    "met": 2.3,
    "name": "tubing, floating on a river, general"
  },
  {
    "value": 18355,
    "met": 5.5,
    "name": "water aerobics, water calisthenics"
  },
  {
    "value": 18360,
    "met": 10,
    "name": "water polo"
  },
  {
    "value": 18365,
    "met": 3,
    "name": "water volleyball"
  },
  {
    "value": 18366,
    "met": 9.8,
    "name": "water jogging"
  },
  {
    "value": 18367,
    "met": 2.5,
    "name": "water walking, light effort, slow pace"
  },
  {
    "value": 18368,
    "met": 4.5,
    "name": "water walking, moderate effort, moderate pace"
  },
  {
    "value": 18369,
    "met": 6.8,
    "name": "water walking, vigorous effort, brisk pace"
  },
  {
    "value": 18370,
    "met": 5,
    "name": "whitewater rafting, kayaking, or canoeing"
  },
  {
    "value": 18380,
    "met": 5,
    "name": "windsurfing, not pumping for speed"
  },
  {
    "value": 18385,
    "met": 11,
    "name": "windsurfing or kitesurfing, crossing trial"
  },
  {
    "value": 18390,
    "met": 13.5,
    "name": "windsurfing, competition, pumping for speed"
  },
  {
    "value": 19018,
    "met": 14,
    "name": "skating, ice dancing"
  },
  {
    "value": 19020,
    "met": 5.5,
    "name": "skating, ice, 9 mph or less"
  },
  {
    "value": 19030,
    "met": 7,
    "name": "skating, ice, general (Taylor Code 360)"
  },
  {
    "value": 19040,
    "met": 9,
    "name": "skating, ice, rapidly, more than 9 mph, not competitive"
  },
  {
    "value": 19050,
    "met": 13.3,
    "name": "skating, speed, competitive"
  },
  {
    "value": 19060,
    "met": 7,
    "name": "ski jumping, climb up carrying skis"
  },
  {
    "value": 19075,
    "met": 7,
    "name": "skiing, general"
  },
  {
    "value": 19080,
    "met": 6.8,
    "name": "skiing, cross country, 2.5 mph, slow or light effort, ski walking"
  },
  {
    "value": 19090,
    "met": 9,
    "name": "skiing, cross country, 4.0-4.9 mph, moderate speed and effort, general"
  },
  {
    "value": 19100,
    "met": 12.5,
    "name": "skiing, cross country, 5.0-7.9 mph, brisk speed, vigorous effort"
  },
  {
    "value": 19110,
    "met": 15,
    "name": "skiing, cross country, >8.0 mph, elite skier, racing"
  },
  {
    "value": 19130,
    "met": 15.5,
    "name": "skiing, cross country, hard snow, uphill, maximum, snow mountaineering"
  },
  {
    "value": 19135,
    "met": 13.3,
    "name": "skiing, cross-country, skating"
  },
  {
    "value": 19140,
    "met": 13.5,
    "name": "skiing, cross-country, biathlon, skating technique"
  },
  {
    "value": 19150,
    "met": 4.3,
    "name": "skiing, downhill, alpine or snowboarding, light effort, active time only"
  },
  {
    "value": 19160,
    "met": 5.3,
    "name": "skiing, downhill, alpine or snowboarding, moderate effort, general, active time only"
  },
  {
    "value": 19170,
    "met": 8,
    "name": "skiing, downhill, vigorous effort, racing"
  },
  {
    "value": 19175,
    "met": 12.5,
    "name": "skiing, roller, elite racers"
  },
  {
    "value": 19180,
    "met": 7,
    "name": "sledding, tobogganing, bobsledding, luge (Taylor Code 370)"
  },
  {
    "value": 19190,
    "met": 5.3,
    "name": "snow shoeing, moderate effort"
  },
  {
    "value": 19192,
    "met": 10,
    "name": "snow shoeing, vigorous effort"
  },
  {
    "value": 19200,
    "met": 3.5,
    "name": "snowmobiling, driving, moderate"
  },
  {
    "value": 19202,
    "met": 2,
    "name": "snowmobiling, passenger"
  },
  {
    "value": 19252,
    "met": 5.3,
    "name": "snow shoveling, by hand, moderate effort"
  },
  {
    "value": 19254,
    "met": 7.5,
    "name": "snow shoveling, by hand, vigorous effort"
  },
  {
    "value": 19260,
    "met": 2.5,
    "name": "snow blower, walking and pushing"
  },
  {
    "value": 20000,
    "met": 1.3,
    "name": "sitting in church, in service, attending a ceremony, sitting quietly"
  },
  {
    "value": 20001,
    "met": 2,
    "name": "sitting, playing an instrument at church"
  },
  {
    "value": 20005,
    "met": 1.8,
    "name": "sitting in church, talking or singing, attending a ceremony, sitting, active participation"
  },
  {
    "value": 20010,
    "met": 1.3,
    "name": "sitting, reading religious materials at home"
  },
  {
    "value": 20015,
    "met": 1.3,
    "name": "standing quietly in church, attending a ceremony"
  },
  {
    "value": 20020,
    "met": 2,
    "name": "standing, singing in church, attending a ceremony, standing, active participation"
  },
  {
    "value": 20025,
    "met": 1.3,
    "name": "kneeling in church or at home, praying"
  },
  {
    "value": 20030,
    "met": 1.8,
    "name": "standing, talking in church"
  },
  {
    "value": 20035,
    "met": 2,
    "name": "walking in church"
  },
  {
    "value": 20036,
    "met": 2,
    "name": "walking, less than 2.0 mph, very slow"
  },
  {
    "value": 20037,
    "met": 3.5,
    "name": "walking, 3.0 mph, moderate speed, not carrying anything"
  },
  {
    "value": 20038,
    "met": 4.3,
    "name": "walking, 3.5 mph, brisk speed, not carrying anything"
  },
  {
    "value": 20039,
    "met": 2,
    "name": "walk/stand combination for religious purposes, usher"
  },
  {
    "value": 20040,
    "met": 5,
    "name": "praise with dance or run, spiritual dancing in church"
  },
  {
    "value": 20045,
    "met": 2.5,
    "name": "serving food at church"
  },
  {
    "value": 20046,
    "met": 2,
    "name": "preparing food at church"
  },
  {
    "value": 20047,
    "met": 3.3,
    "name": "washing dishes, cleaning kitchen at church"
  },
  {
    "value": 20050,
    "met": 1.5,
    "name": "eating at church"
  },
  {
    "value": 20055,
    "met": 2,
    "name": "eating/talking at church or standing eating, American Indian Feast days"
  },
  {
    "value": 20060,
    "met": 3.3,
    "name": "cleaning church"
  },
  {
    "value": 20061,
    "met": 4,
    "name": "general yard work at church"
  },
  {
    "value": 20065,
    "met": 3.5,
    "name": "standing, moderate effort (e.g., lifting heavy objects, assembling at fast rate)"
  },
  {
    "value": 20095,
    "met": 4.5,
    "name": "Standing, moderate-to-heavy effort, manual labor, lifting ò 50 lbs, heavy maintenance"
  },
  {
    "value": 20100,
    "met": 1.3,
    "name": "typing, electric, manual, or computer"
  },
  {
    "value": 21000,
    "met": 1.5,
    "name": "sitting, meeting, general, and/or with talking involved"
  },
  {
    "value": 21005,
    "met": 1.5,
    "name": "sitting, light office work, in general"
  },
  {
    "value": 21010,
    "met": 2.5,
    "name": "sitting, moderate work"
  },
  {
    "value": 21015,
    "met": 2.3,
    "name": "standing, light work (filing, talking, assembling)"
  },
  {
    "value": 21016,
    "met": 2,
    "name": "sitting, child care, only active periods"
  },
  {
    "value": 21017,
    "met": 3,
    "name": "standing, child care, only active periods"
  },
  {
    "value": 21018,
    "met": 3.5,
    "name": "walk/run play with children, moderate, only active periods"
  },
  {
    "value": 21019,
    "met": 5.8,
    "name": "walk/run play with children, vigorous, only active periods"
  },
  {
    "value": 21020,
    "met": 3,
    "name": "standing, light/moderate work (e.g., pack boxes, assemble/repair, set up chairs/furniture)"
  },
  {
    "value": 21025,
    "met": 3.5,
    "name": "standing, moderate (lifting 50 lbs., assembling at fast rate)"
  },
  {
    "value": 21030,
    "met": 4.5,
    "name": "standing, moderate/heavy work"
  },
  {
    "value": 21035,
    "met": 1.3,
    "name": "typing, electric, manual, or computer"
  },
  {
    "value": 21040,
    "met": 2,
    "name": "walking, less than 2.0 mph, very slow"
  },
  {
    "value": 21045,
    "met": 3.5,
    "name": "walking, 3.0 mph, moderate speed, not carrying anything"
  },
  {
    "value": 21050,
    "met": 4.3,
    "name": "walking, 3.5 mph, brisk speed, not carrying anything"
  },
  {
    "value": 21055,
    "met": 3.5,
    "name": "walking, 2.5 mph slowly and carrying objects less than 25 lbs"
  },
  {
    "value": 21060,
    "met": 4.5,
    "name": "walking, 3.0 mph moderately and carrying objects less than 25 lbs, pushing something"
  },
  {
    "value": 21065,
    "met": 4.8,
    "name": "walking, 3.5 mph, briskly and carrying objects less than 25 lbs"
  },
  {
    "value": 21070,
    "met": 3,
    "name": "walk/stand combination, for volunteer purposes"
  }
]