import React, { Component } from 'react';
import MealPlanDay from './MealPlanDay';
import PlanGoalKeeper from './PlanGoalKeeper';
import PlanMobileGoalKeeper from './PlanMobileGoalKeeper'
let weekday = require('weekday');
let Newdate = new Date();
let dayOfWeek = Newdate.getDay()+1;

export default class DailyMeals extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	plan: this.props.plan,
	  	stats: this.props.user.stats,
	  	status: false,
	  	carbs: [],
	  	currentDay: weekday(dayOfWeek),
	  	highlight: 'notCurrent',

	  };
	}
	componentDidMount(){
		this.setState({
			plan: this.props.plan,
		})
		if(this.state.plan[this.props.day].day === this.state.currentDay){
			this.setState({
				highlight: 'currentDay',
			})
		}
	}
	componentDidUpdate(nextProps){
		if(this.props !== nextProps){
			this.setState({plan: nextProps.plan,})
		}
	}

	handleDayBlock(){
		const status = this.state.status;
		this.setState({status: !status})
	}
	render() {
		const {prefersnacks} = this.props;
		let mobileDevice = this.props.deviceLayout === 'mobileLayout' || this.props.deviceLayout === 'smallMobileLayout';
		let activeDay = (this.props.day === this.props.activeDay) && mobileDevice === true
		
		return (
			
			<div className={"dayBlockButton " + this.state.status} data-active={activeDay}>
				<div className={"mealEditorHeader border border-gray-200 " + this.state.highlight}>
					<h5>{this.state.plan[this.props.day].day}</h5>
				</div>	
				<MealPlanDay  dayPlan={this.state.plan[this.props.day].breakfast}
					meal={this.state.plan[this.props.day].breakfast.label}
					dayLabel={this.state.plan[this.props.day].day}
					day={this.props.day}
					currentDay={this.state.currentDay}
					handleAddMeal={this.props.handleAddMeal}
					gravUrl={this.props.gravUrl}
					recipes={this.props.recipes}
					recipeImages={this.props.recipes}
					deviceLayout={this.props.deviceLayout}
				/>

				{prefersnacks?
				<MealPlanDay  dayPlan={this.state.plan[this.props.day].morningSnack}
					meal={this.state.plan[this.props.day].morningSnack.label}
					dayLabel={this.state.plan[this.props.day].day}
					day={this.props.day}
					currentDay={this.state.currentDay}
					handleAddMeal={this.props.handleAddMeal}
					gravUrl={this.props.gravUrl}
					recipes={this.props.recipes}
					recipeImages={this.props.recipes}
					deviceLayout={this.props.deviceLayout}
				/>
				:null
				}
				<MealPlanDay  dayPlan={this.state.plan[this.props.day].lunch}
					meal={this.state.plan[this.props.day].lunch.label}
					dayLabel={this.state.plan[this.props.day].day}
					day={this.props.day}
					currentDay={this.state.currentDay}
					handleAddMeal={this.props.handleAddMeal}
					gravUrl={this.props.gravUrl}
					recipes={this.props.recipes}
					recipeImages={this.props.recipes}
					deviceLayout={this.props.deviceLayout}
				/>
				{prefersnacks?
				<MealPlanDay  dayPlan={this.state.plan[this.props.day].afternoonSnack}
					meal={this.state.plan[this.props.day].afternoonSnack.label}
					dayLabel={this.state.plan[this.props.day].day}
					day={this.props.day}
					currentDay={this.state.currentDay}
					handleAddMeal={this.props.handleAddMeal}
					gravUrl={this.props.gravUrl}
					recipes={this.props.recipes}
					recipeImages={this.props.recipes}
					deviceLayout={this.props.deviceLayout}
				/>:null
				}
				<MealPlanDay  dayPlan={this.state.plan[this.props.day].dinner}
					meal={this.state.plan[this.props.day].dinner.label}
					dayLabel={this.state.plan[this.props.day].day}
					day={this.props.day}
					currentDay={this.state.currentDay}
					handleAddMeal={this.props.handleAddMeal}
					gravUrl={this.props.gravUrl}
					recipes={this.props.recipes}
					recipeImages={this.props.recipes}
					deviceLayout={this.props.deviceLayout}
				/>
				{prefersnacks?
				<MealPlanDay dayPlan={this.state.plan[this.props.day].eveningSnack}
					meal={this.state.plan[this.props.day].eveningSnack.label}
					dayLabel={this.state.plan[this.props.day].day}
					day={this.props.day}
					currentDay={this.state.currentDay}
					handleAddMeal={this.props.handleAddMeal}
					gravUrl={this.props.gravUrl}
					recipes={this.props.recipes}
					recipeImages={this.props.recipes}
					deviceLayout={this.props.deviceLayout} 
				/>:null}
				{mobileDevice?
					
					<div className="mealEditorMobileFooter">
						<PlanMobileGoalKeeper user={this.props.user}
							fat={this.state.plan[this.props.day].totalFat}
							carbs={this.state.plan[this.props.day].totalCarbs}
							protein={this.state.plan[this.props.day].totalProtein}
							plan={this.state.plan[this.props.day]}
						/>
					</div>
				:
					<div className="mealEditorFooter">
						<div className="goalContent">
							<PlanGoalKeeper user={this.props.user}
								plan={this.state.plan[this.props.day]}
								fat={this.state.plan[this.props.day].totalFat}
								carbs={this.state.plan[this.props.day].totalCarbs}
								protein={this.state.plan[this.props.day].totalProtein}
							/>
						</div>	
						
					</div>
				}
			</div>

		);
	}
}
