import React from 'react';
import HtmlParser from 'react-html-parser';
import '../../../Content/css/Slider.css'
import Slider from "react-slick";
export default class FullRecipePeek extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			viewIngredients: false,
			size: 'small',
	  		showDots: true,
	  		slideCount: 0,
			slidesToScroll: 2,
		}
		this.handleAddMealToPlan = this.handleAddMealToPlan.bind(this)
	}
	handleAddMealToPlan(e){
		let key = e.target.getAttribute('data-key');
		this.props.handleAddMealToPlan(this.props.favorites[key])
	}
	handleViewIngredients(){
		this.setState({viewIngredients: true,})
	}
	componentDidMount(){
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions.bind(this));
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
		if(window.innerWidth <= 767){
		  	this.setState({
		  		size: 'small',
		  		showDots: false,
		  		slideCount: 2,
				slidesToScroll: 2,
		  	})
		}
		if(window.innerWidth <= 400){
		  	this.setState({
		  		size: 'small',
		  		showDots: false,
		  		slideCount: 1,
				slidesToScroll: 1,
				speed: 200,
		  	})
		}
		if(window.innerWidth > 768){
		  	this.setState({
		  		size: 'large',
		  		showDots: true,
		  		slideCount: 3,
				slidesToScroll: 3,
				speed: 200,
		  	})
		}
		if(window.innerWidth > 1400){
		  	this.setState({
		  		size: 'large',
		  		showDots: true,
		  		slideCount: 3,
				slidesToScroll: 3,
				speed: 400,
		  	})
		}

	}
	render() {
		const slides = this.props.favorites.map((item, key)=>
			
			<div className="recipeFavoriteTile">
				<div data-key={key} key={key}>
					<div className="recipeFavSelector">
						<button onClick={this.handleAddMealToPlan.bind(this)} data-key={key}>
							<img src={this.props.gravUrl + item.mediumSquare} alt={item.header.title}/>
						</button>
					</div>
					<h4>{HtmlParser(item.title)}</h4>
				</div>
			</div>
			
		);	
		const sliderValid = this.props.favorites.length > 3;
		return (
			<>
			{sliderValid&&
				<div style={{margin: '40px 0'}}>
				<h4 className="text-center font-medium text-1xl text-secondary">Add from your favorites</h4>
					<Slider dots={this.state.showDots}
						infinite={true}
						speed={800}
						variableWidth={false}
						useTransform={false}
						arrows={true}
						autoplaySpeed={6000}
						autoplay={true}
						fade={false}
						slidesToShow={this.state.slideCount}
							slidesToScroll={this.state.slidesToScroll}
						pauseOnHover={true}
					>	
						{slides}
					</Slider>
				</div>
				
				}
			</>
		);
	}
}
