import React, { Component } from 'react';
import ProgressTrackerChart from './ProgressTrackerChart'
export default class UserProgressTrackingProfile extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	activityLevelOptions: [
			{ value: 'Sedentary', label: 'Sedentary' },
			{ value: 'Lightly Active', label: 'Lightly Active' },
			{ value: 'Moderately Active', label: 'Moderately Active' },
			{ value: 'Very Active', label: 'Very Active' },
		],
	  	mode: '',
	  	day: new Date().getDate(),
	  	month: new Date().getMonth()+1,
	  	year: new Date().getFullYear(),
	  	timeAgo: '',
			...this.props.user.stats,
			user: this.props.user,
		
	  };
	}
	componentDidMount(){
		this.setState({
			...this.props.user.stats,	
 		  mode: 'init',
 		  user: this.props.user,
 		}) 
		this.handleTimeAgo();
	}
	handleTimeAgo(){
		// work out how long ago it was last updated
	}

	handleUpdateWeight(e){
		let value = e.target.value;
		this.setState({
			weight: value,
			mode: 'edit',
			timeAgo: 'today'
		})
	}
	handleUpdateHeight(e){
		let value = e.target.value;
		this.setState({
			height: value,
			mode: 'edit',
			timeAgo: 'today'
		})
	}
	handleUpdateSex(e){
		let value = e.target.value;
		this.setState({
			sex: value,
			mode: 'edit',
			timeAgo: 'today'
		})
	}
	handleUpdateAge(e){
		let value = e.target.value;
		this.setState({
			age: value,
			mode: 'edit',
			timeAgo: 'today'
		})
	}
	handleUpdateActivityLevel(e){
		this.setState({
			activityLevel: e.value,
			mode: 'edit',
			timeAgo: 'today'
		})
	}
	handleShowEditField(e){
		let button = e.target;
		button.classList.add('hidden');
		let wrapper = button.parentElement;
		wrapper.className = 'editFieldWrapperActive';
	}
	handleSaveProfile(){
		if(this.state.editMode === 'editMode'){
			
		}
	}
	handleSave(){
		this.setState({mode: 'init'})
		let fieldWrappers = document.getElementsByClassName('editFieldWrapperActive');
		while (fieldWrappers.length) {
		    fieldWrappers[0].className = "editFieldWrapper";
		}
		let editButtons = document.getElementsByClassName('editFieldButton hidden');
		while (editButtons.length) {
		    editButtons[0].className = "editFieldButton";
		}
	}
	render() {	
		const {stats} = this.props.user;
		return (
			<>
				{stats?
					<div>
						<div className="progressTrackerChartWrapper" id="progressTrackerChartWrapper">
							<ProgressTrackerChart stats={this.props.user.Progress_Tracker} journalEntries={this.state.user.Journal}/>
						</div>
					</div>
				:null}
			</>
		);
	}
}
