import React from 'react';

export default class WaterCountInputs extends React.Component {
	waterInutOnChange(e){
		let glasses = e.target.value;
		this.props.handleGlasses(glasses);
		let inputs = document.getElementsByName('water');
		for(let i=0; i < inputs.length; i++){
			let glassesNumber = inputs[i].value;
			let inputglasses = parseInt(glassesNumber)
			if(inputglasses < glasses){
				inputs[i].setAttribute("data-below", true)
			}
			if(inputglasses > glasses){
				inputs[i].setAttribute("data-below", false)
			}
		}
	}
	componentDidMount(){
		let glasses = this.props.glasses;
		let inputs = document.getElementsByName('water');
		for(let i=0; i < inputs.length; i++){
			let glassesNumber = inputs[i].value;
			let inputglasses = parseInt(glassesNumber)
			if(inputglasses === glasses){
				inputs[i].setAttribute("checked", 'checked')
			}
			if(glassesNumber === glasses){
				inputs[i].setAttribute("checked", 'checked')
			}
			if(inputglasses < glasses){
				inputs[i].setAttribute("data-below", true)
			}
			if(inputglasses > glasses){
				inputs[i].setAttribute("data-below", false)
			}
		}
	}
	render() {
		return (
			<div className="waterInputs drinkInputs">
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="1"
					/>
					<span data-selected="false" className="circleInput">1</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="2"
					/>
					<span data-selected="false" className="circleInput">2</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="3"
					/>
					<span data-selected="false" className="circleInput">3</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="4"
					/>
					<span data-selected="false" className="circleInput">4</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="5"
					/>
					<span data-selected="false" className="circleInput">5</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="6"
					/>
					<span data-selected="false" className="circleInput">6</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="7"
					/>
					<span data-selected="false" className="circleInput">7</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="8"
					/>
					<span data-selected="false" className="circleInput">8</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="9"
					/>
					<span data-selected="false" className="circleInput">9</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="10"
					/>
					<span data-selected="false" className="circleInput">10</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="11"
					/>
					<span data-selected="false" className="circleInput">11</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="12"
					/>
					<span data-selected="false" className="circleInput">12</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="13"
					/>
					<span data-selected="false" className="circleInput">13</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="14"
					/>
					<span data-selected="false" className="circleInput">14</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="15"
					/>
					<span data-selected="false" className="circleInput">15</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="16"
					/>
					<span data-selected="false" className="circleInput">16</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="17"
					/>
					<span data-selected="false" className="circleInput">17</span>
				</div>
				<div className="circleNumberInput">
					<input 
						type="radio" 
						name="water" 
						onClick={this.waterInutOnChange.bind(this)} 
						value="18"
					/>
					<span data-selected="false" className="circleInput">18</span>
				</div>
			</div>
		);
	}
}
