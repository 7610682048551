import React, { Component } from 'react';
import {Fruit} from './FoodValueTable';
import SelectSearch from 'react-select-search';
import MacroTable from './MacroTable';
import '../../../../Content/css/searchSelect.css';
import SnackMeals from './SnackMeals';
export default class SnackerInput extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	fruit: [...Fruit.food],
	  	snack: this.props.snacks.snack,
	  	snackMeals: this.props.snacks.snackMeals,
	  	snackTotals: this.props.snacks.snackTotals,
	  	width: 0,
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	handleSnackSearch(snack){
		this.setState({snack})
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	componentDidMount(){
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			snack: this.props.snacks.snack,
	  		snackMeals: this.props.snacks.snackMeals,
	  		snackTotals: this.props.snacks.snackTotals,
		})
	}
	handleSnackerMeal(snack, serving){
		let snackMeals = this.props.snacks.snackMeals;
		let addingSnack = {
			carbs: ((snack.carbs/100)*serving).toFixed(2),
			fat: ((snack.fat/100)*serving).toFixed(2),
			protein: ((snack.protein/100)*serving).toFixed(2),
			name: snack.name,
			serving: serving,
		};
		snackMeals.push(addingSnack);
		let snacks = {
			snack: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  	},
			snackMeals: snackMeals,
			snackTotals: {
		  		fat: this.props.snacks.snackTotals.fat + ((snack.fat/100)*serving),
		  		protein: this.props.snacks.snackTotals.protein + ((snack.protein/100)*serving),
		  		carbs: this.props.snacks.snackTotals.carbs + ((snack.carbs/100)*serving),
		  	}
		}
		this.props.handleSnacks(snacks)
	}
	handleProductServing(snack, serving){
		let snackMeals = this.props.snacks.snackMeals;
		let addingSnack = {
			carbs: ((snack.carbs)*serving).toFixed(1),
			fat: ((snack.fat)*serving).toFixed(1),
			protein: ((snack.protein)*serving).toFixed(1),
			name: snack.name,
			serving: serving,
			product: true,
		};
		snackMeals.push(addingSnack);
		let snacks = {
			snack: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  	},
			snackMeals: snackMeals,
			snackTotals: {
		  		fat: this.props.snacks.snackTotals.fat + ((snack.fat)*serving),
		  		protein: this.props.snacks.snackTotals.protein + ((snack.protein)*serving),
		  		carbs: this.props.snacks.snackTotals.carbs + ((snack.carbs)*serving),
		  	}
		}
		this.props.handleSnacks(snacks)
	}
	handleRemoveMeal(key){
		let snackMeals = this.state.snackMeals;
		let snacks = {
			snack: {
		  		value: '',
		  		fat: 0,
		  		protein: 0,
		  		carbs: 0,
		  		serving: 0,
		  	},
			snackMeals: snackMeals,
			snackTotals: {
		  		fat: this.props.snacks.snackTotals.fat - snackMeals[key].fat,
		  		protein: this.props.snacks.snackTotals.protein - snackMeals[key].protein,
		  		carbs: this.props.snacks.snackTotals.carbs - snackMeals[key].carbs,
		  	}
		}
		snackMeals.splice(key, 1);		
		this.setState({
			snackMeals: snackMeals,
		})
		this.props.handleSnacks(snacks)
	}
	render() {
		let totals = this.props.snacks.snackTotals.fat > 0 || this.props.snacks.snackTotals.carbs > 0 || this.props.snacks.snackTotals.protein;
		let totalsRequired = totals === true && this.props.snacks.snackMeals.length > 1;
		let {snackMeals} = this.props.snacks;
		let snackTyped = this.state.snack.value.length > 0;
		let desktopSize = this.state.width > 767;
		return (
			<div>
				<div>
					<h4>Snacks</h4>
					<SelectSearch 
						options={[...this.state.fruit]} 
						value={this.state.snack.value}
						onChange={this.handleSnackSearch.bind(this)}
						name="fruit"
						placeholder="Search New Image™ Products, snacks food and fruit" 
					/>
					{snackTyped&&
						<MacroTable
							meal={this.state.snack}
							handleSnackerMeal={this.handleSnackerMeal.bind(this)}
							handleProductServing={this.handleProductServing.bind(this)}
							servingValueType="g"
							serving={true}
							desktopLayout={desktopSize}
						/>
					}
				</div>
				{snackMeals &&
					<SnackMeals 
						handleRemoveMeal={this.handleRemoveMeal.bind(this)}
						meals={this.state.snackMeals}
					/>
				}
				{desktopSize?
					<div>	
						{totalsRequired &&
							<table className="table mealMacrosTable text-center">
								<tbody>
								<tr>
									<td className="text-left"><h6>Snack total macros</h6></td>
									<td>{this.props.snacks.snackTotals.fat.toFixed(2)} g</td>
									<td>{this.props.snacks.snackTotals.carbs.toFixed(2)} g</td>
									<td>{this.props.snacks.snackTotals.protein.toFixed(2)} g</td>
									<td className="invisibleText"><h6>Serving</h6></td>
									<td className="text-right">
										<div className="invisibleText">
											<span className="flaticon-round-information-button removeSnackButton"></span>
										</div>
									</td>
								</tr>
								</tbody>
							</table>
						}
					</div>
				:
				<div>	
					<div>
						<table className="table text-center totalsMobileMacroTable">
							<tbody>
							<tr>
								<td><strong>Fat: </strong><br/>{this.props.snacks.snackTotals.fat.toFixed(2)} g</td>
								<td><strong>Carbs: </strong><br/>{this.props.snacks.snackTotals.carbs.toFixed(2)} g</td>
								<td><strong>Protein: </strong><br/>{this.props.snacks.snackTotals.protein.toFixed(2)} g</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			}
			</div>
		);
	}
}
