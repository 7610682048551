import React, { Component } from 'react';
import JournalEntryForm from './JournalEntryForm'
export default class JournalEntryEditor extends Component {
	constructor(props) {
	  super(props)
	  this.state = {
	  	date: [
	  		new Date().getDate(),
	  		new Date().getMonth() + 1,
	  		new Date().getFullYear(),
	  		new Date().getDay()+1,
	  		new Date().getDay(),
	  	],
	  	journalEntry: this.props.journalEntry,
	  	mealPlans: this.props.mealPlans,
	  	timeKeeping: this.props.timeKeeping,
	  	width: 0,
	  };
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	}
	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	componentDidMount(){
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions);
		if(this.props.editEntry){
			const {timestamp, month, year, day} = this.props.editEntry;
			this.setState({date: [timestamp,month,year,day + 1,day,]})
		}
	}

	render() {
		return (
			<JournalEntryForm uid={this.props.uid} 
				timeKeeping={this.props.timeKeeping}
				day={this.state.date[0]} 
				month={this.state.date[1]}
				year={this.state.date[2]}
				weekDay={this.state.date[3]}
				closeOnSave={this.props.closeOnSave}
				mealPlans={this.state.mealPlans}
				trueWeekDay={this.state.date[4]}
				reloadUserData={this.props.reloadUserData}
				user={this.props.user}
				editEntry={this.props.editEntry}
				windowWidth={this.state.width}
				recipesMacroTable={this.props.recipesMacroTable}
				userRecipes={this.props.userRecipes}
			/>
		);
	}
}
