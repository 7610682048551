	import React from 'react';
import {MetTable} from './ExerciseMetTable';
import SelectSearch from 'react-select-search';
import DayWorkOut from './DayWorkOut';
export default class ExerciseInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			metTable: MetTable,
			exercise: {
				name: '',
				value: 0,
			},
			minutes: 0,
			caloriesBurned: 0,
		}
	}
	handleExerciseSearch(exercise){
		let weight = this.props.weight;
		let met = exercise.met;
		let kcal = met * weight;
		let kcalPerMinute = kcal/60;
		this.setState({
			exercise,
			met,
			kcal,
			kcalPerMinute,
		})
		let input = document.getElementById('exerciseMinutes');
		input.focus();
		this.setState({minutes: ''}) 
	}
	handleTime(e){
		this.setState({
			minutes: e.target.value,
			caloriesBurned: this.state.kcalPerMinute * e.target.value,
		})
	}
	
	handleMetCalculator(){
		if(this.state.exercise.name.length > 0 && this.state.minutes > 0){
			let dayWorkOut = this.props.dayWorkOut;
			let calories = this.state.kcalPerMinute * this.state.minutes;
			let workOut = {
				exercise: this.state.exercise.name,
				metValue: this.state.exercise.met,
				calories: calories,
				minutes: this.state.minutes,

			}
			this.props.handleCaloriesBurned(this.state.caloriesBurned)
			dayWorkOut.push(workOut)
			this.props.handleDayWorkOut(dayWorkOut)	
			this.setState({
				exercise: {
					name: '',
					value: 0,
				},
				minutes: 0,
				caloriesBurned: 0,
			})
		}
		
	}
	static getDerivedStateFromProps(props, state) {
		//
		return null
    }
	render() {
		const dayWorkOut = this.props.dayWorkOut.length > 0;
		return (
			<div>
				<h3>Exercise</h3>
				<div className="flex flex-wrap flex-end w-full">
					<div className="sm:w-9/12 w-full md:9/12">
						<div className="my-2">
						<label htmlFor="exercise" className="text-sm">Search exercise and any physical activity</label>
						<SelectSearch 
							options={this.state.metTable} 
							value={this.state.exercise.value}
							onChange={this.handleExerciseSearch.bind(this)}
							name="exercise"
							placeholder="" 
						/>
						</div>
					</div>
					<div className="sm:w-2/12 md:w-2/12 w-10/12">
						<div className="mx-2 sm:pl-4">
						<label htmlFor="exerciseMinutes" className="mb-2 block">Minutes</label>
						<input 
							type="text" 
							onChange={this.handleTime.bind(this)} 
							value={this.state.minutes}
							className="formControl shadow-md"
							id="exerciseMinutes"
							name="exerciseMinutes"
						/>
						</div>
					</div>
					<div className="sm:w-1/12 w-2/12">
						<button onClick={this.handleMetCalculator.bind(this)} className="ml-auto text-right bg-white border-gray-300 py-2 px-3 addExerciseToJournalButton">
								<span className="flaticon-add-1 text-2xl"></span>
								<span className="hidden">calculate</span>
						</button>
						
					</div>
				</div>
				{dayWorkOut && <DayWorkOut  workOuts={this.props.dayWorkOut} handleRemoveWorkOut={this.props.handleRemoveWorkOut}/>}
			</div>
		);
	}
}
