import React, { Component } from 'react';
import {db} from '../../../firebase';
import requireAuth from "../../Authentication/RequireAuthentication";
import '../../../Content/css/profile.css';
import MealPlanSelector from '../../Recipes/MealPlanSelector'
import MealPlanEditor from '../../User/MealPlans/MealPlanEditor';
import FreeMealPlans from '../../FreeMealPlans/MealPlansList';
import { connect } from "react-redux";
import {initialPlan} from './InitialMealPlan';

let gravURL = process.env.REACT_APP_GRAV_URL;
const mapStateToProps = (state, props) => {
	return { user: state.user, uid: state.uid, recipes: props.recipes, content: props.content};
  };
class MealPlans extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	user: this.props.user,
	  	uid: this.props.uid,
	  	editorMode: false,
	  	navigationMode: true,
	  	count: 0,
	  	currentPlan: 0,
	  	initplan: initialPlan,
	  	createdNewPlan: false,
	  	//meal editor notifications
	  	showNotification: false,
	  	notificationMessage: '',
	  	notificationMeal: 0,
	  	notificationDay: 0,
	  	notificationButtonLabel: '',
	  	notificationTransition: '',
	  	verified: this.props.user.verified,
	  	mealPlans: [],
	  	allRecipes: this.props.recipes, 
		recipeImages: this.props.recipes,
		favorites: []
	  };
	}

	resetPlan(){
		this.setState({
			plan: initialPlan,
		})
	}
	componentDidMount() {
		let user = this.props.user;
		let recipes = this.props.recipes;
		let favorites = recipes.map((item)=>{
			if(item.favorite){
				return item
			}
			return null
		})
		this.setState({
			user: user,
			favorites,
		})
		if(user.Favorite_Recipes){
			this.setState(
				{userFavorites: user.Favorite_Recipes}
			)
		}
		if(user.verified === true){
			this.setState({verified: true})
		}
		else {
			this.setState({ gravContent: this.props.content.mealPlans.frontmatter, mealPlans: this.props.content.mealPlans.mealPlans });
		}
	}
	componentDidUpdate(prevProps) {
		if(prevProps !== this.props){
			const Meal_Plans = this.props.user.Meal_Plans;
			if(typeof Meal_Plans !== 'undefined'){
				this.setState({
					mealPlans: Meal_Plans
				})
			}
			let user = this.props.user;
			if(user.verified === true){
				this.setState({verified: true})
			}
			else {
				this.setState({verified: false})
			}
		}
	}
	
	reloadUserData(){
		db.getUserInfo(this.props.uid).then((user)=>{
			this.props.storeUser(user);
		})
	}

	addNewMealPlan(count){
		
		if(count >= 0 && count <= 5){
			this.setState({
				editorMode: true,
		  		navigationMode: false,
		  		plan: initialPlan,
		  		newPlanId: count,
		  		currentPlan: count,
		  		createdNewPlan: true,
			})
			db.startInitMealPlan(this.props.uid, count, this.state.initplan)
		}
		else{
			alert('You have reached the maximum number of meal plans')	
		}
		
	}
	handleMealPlanNav(){
		if(this.state.editorMode === true && this.state.navigationMode === false){
			this.setState({editorMode: false,navigationMode: true,})
			if(this.state.createdNewPlan === false){
				this.reloadUserData()
			}
			else{
				window.location.reload()
			}
		}
		else{
			this.setState({editorMode: true, navigationMode: false})
		}
	}

	handleExistingMealPlan(week){
		this.setState({
			plan: this.props.user.Meal_Plans[week].plan,
			currentPlan: week,
			editorMode: true,
			navigationMode: false, 
		})
	}
	render() {
		const verified = this.state.verified === true;
		const navigation = this.state.navigationMode === true;
		const editor = this.state.editorMode === true;
		const {uid, recipes, user} = this.props
		let count = 0
			if(this.props.user.Meal_Plans){
				count = Object.keys(this.props.user.Meal_Plans).length
			}
			return (
				<React.Fragment>
					{verified?
						<React.Fragment>
							{navigation&&
								<div className={"mealPlansNav grey " + this.state.navigationMode} key={"navigation"+this.state.createdNewPlan}>
									<MealPlanSelector
										plans={this.state.mealPlans}
										count={count}
										uid={uid}
										editWeek={this.handleExistingMealPlan.bind(this)}
										addNewMealPlan={this.addNewMealPlan.bind(this)}
										user={user}
										recipeImages={this.state.recipeImages}
										style={this.props.style}
									/>
								</div>
							}
							{editor&&
								<main>
									<div key={"editor" + this.state.createdNewPlan}>
										<section className="mealPlanPageWrapper" style={{position: 'relative'}}>
											<div className="clearfix pageGrid">			
												<div className={"mealPlanEditor " + this.state.editorMode}>
													
													<MealPlanEditor 
														favorites={this.state.favorites}
														user={user}
														gravUrl={gravURL}
														allRecipes={recipes}
														recipes={recipes}
														handleMealPlanNav={this.handleMealPlanNav.bind(this)}
														uid={uid}
														plan={this.state.plan}
														currentPlan={this.state.currentPlan}
														reloadUserData={this.reloadUserData.bind(this)}
														recipeImages={recipes}
														style={this.props.style}
														initialPlanState={initialPlan}
														resetPlan={this.resetPlan.bind(this)}

													/>
													
												</div>
											</div>							
										</section>						
									</div>
								</main>
							}
						</React.Fragment>
						:
						<main>
							<FreeMealPlans verified={this.state.verfied} mealPlans={this.props.content.mealPlans.mealPlans}/>
						</main>
					}
				</React.Fragment>
			)
		}
		
		
	}


const MealPlansConnected = connect(mapStateToProps)(MealPlans);

export default requireAuth(MealPlansConnected);