import React from 'react';

const CalorieGoalChart = (props) => {
	const {goal, total} = props
	const barStyle = {width: ((total / goal)*100) + '%',}
	const goalMarkerStyle = {left: (((total-goal) / goal)*100) + '%',}
	const overflow = ((total / goal)*100) > 100;
	return (
		<div className="calorieGoalChartWrapper clearfix">
			<div className="chart">
				<div className="barWrapper">
					{overflow? <div className="goalMarker" style={goalMarkerStyle}></div>:null}
					<div className="bar" style={barStyle}></div>
				</div>
			</div>
			<strong>{((total / goal)*100).toFixed(1)} %</strong>
		</div>
	);
}

export default CalorieGoalChart;