import React from 'react';
import { keyframes } from 'styled-components';
import '../../Content/css/circleChart.css'
const CircleChart = (props) => {
	let percentage = props.percentage;
	let difference = 100 - props.percentage;
	let percentageArray = percentage + ',' + difference;
	let {grams} = props;

	const keyFrameExampleOne = keyframes`
		0% {
			stroke-dasharray: 0,100;
		}
		100% {
			stroke-dasharray: ${percentage}, ${difference}
		}`
	let style = {
		animation:` ${keyFrameExampleOne} 3s`,
	}
	return (
		<div className="svg-item">
			{percentage?
				<svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
					<circle className="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#f9f9f9"></circle>
					<circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
					<circle className="donut-segment donut-segment-4 shadow-lg" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray={percentageArray} strokeDashoffset="25" style={style}></circle>
					<g className="donut-text donut-text-3">
					<text y="30%" transform="translate(0, 2)">
						<tspan x="50%" textAnchor="middle" className="donut-label">{props.label}</tspan>
					</text>
					<text y="50%" transform="translate(0, 2)">
						<tspan x="50%" textAnchor="middle" className="donut-percent">{props.grams.toFixed(0)}</tspan>
					</text>
					<text y="58%" transform="translate(0, 2)">
						<tspan x="50%" textAnchor="middle" className="donut-grams">Grams</tspan>   
					</text>
					<text y="65%" transform="translate(0, 2)">
						<tspan>
								{grams?
								<tspan x="50%" textAnchor="middle" className="donut-data"><br/>{parseInt(props.percentage).toFixed(0)}%</tspan>
								:
								<tspan x="50%" textAnchor="middle" className="donut-data"></tspan>
								}
						</tspan>   
					</text>
					</g>
				</svg>
			:<span></span>}
		</div>
	);	
}
export default CircleChart;
