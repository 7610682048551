import React from "react";
import requireAuth from "../../Authentication/RequireAuthentication";
import { connect } from "react-redux";
import Journal from './Journal';
const mapStateToProps = (state, props) => {
	return { user: state.user, uid: state.uid, recipes: props.recipePages};
  };
  const JournalPage = ({ user, uid, recipes }) => (
    <Journal user={user} uid={uid} recipes={recipes}/>
  ); 
const JournalConnected = connect(mapStateToProps)(JournalPage);
export default requireAuth(JournalConnected);