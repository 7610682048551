import React from 'react';
import RecipeMacroTable from './RecipeMacroTable';
export default class FullRecipePeek extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			viewIngredients: false,
			quantityInput: 100,
			quantityChanged: false,
		}
	}
	handleAddMealToPlan(){
		if(this.state.quantityChanged === true){
			let meal = this.props.meal;
			let carbs = (parseInt(meal.totalCarbs)/100) * this.state.quantityInput;
			let protein = (parseInt(meal.totalProtein)/100) * this.state.quantityInput;
			let fat = (parseInt(meal.totalFat)/100) * this.state.quantityInput;
			meal.totalCarbs = carbs.toFixed(1);
			meal.totalProtein = protein.toFixed(1);
			meal.totalFat = fat.toFixed(1);
			meal.recipe.totalCarbs = carbs.toFixed(1);
			meal.recipe.totalProtein = protein.toFixed(1);
			meal.recipe.totalFat = fat.toFixed(1);
			let ingredientParts = [{ingredients: [{
				category: meal.recipe.category,
				ingredient: meal.title,
				measurement: 'g',
				recipes: [meal.title],
				ticked: false,
				value: this.state.quantityInput,
				rawValue: 'g',
			}] }]
			meal.recipe.ingredients_parts = ingredientParts;
			this.props.handleAddMealToPlan(meal);
		}
		else {
			
			this.props.handleAddMealToPlan(this.props.meal);
		}
	}
	handleViewIngredients(){
		this.setState({
			viewIngredients: true,
		})
	}
	handlePreparedFoodQuantity(e){
		let quantityInput = e.target.value;
		this.setState({quantityInput, quantityChanged: true});


	}
	render() {
		const {picture, recipe} = this.props.meal;
		let url = this.props.gravUrl;
		let requireSaveButton = this.props.requireSaveButton === true;
		let isCustomRecipe = this.props.meal.recipe.method === 'Custom recipe';
		let isPreMade = this.props.meal.recipe.method === 'Prepared meal';
		let isRecipe = isCustomRecipe === false && isPreMade === false;
		return (
				<div className="px-5 flex-wrap lg:flex">
					{isRecipe?
						<>
							<div className="lg:w-7/12">
								<div>
									<div><h3 className="text-primary text-2xl font-medium">{this.props.meal.title}</h3></div>
									{recipe&&
										<RecipeMacroTable macros={this.props.meal.recipe}/>
									}
									<div className="mt-2 mb-4">
										{requireSaveButton&&
											<button onClick={this.handleAddMealToPlan.bind(this)} className="bg-primary py-2 px-4 rounded-lg text-white">
												Add to meal plan
											</button>
										}
									</div>
								</div>
							</div>
							<div className="lg:w-1/12"></div>
							<div className="lg:w-4/12">
								{picture&&
									<img src={url + '/' + this.props.meal.picture} alt={this.props.meal.title} className="mealPlanPreviewImg" height="600px" width="600px"/>
								}
								{recipe&& 
									<p className="text-center mt-2"><strong>Serves:</strong> {this.props.meal.recipe.serves}</p>
								}
							</div>
						</>
					:
						<div className="">
							<div className="md:flex flex-wrap">
								<div className="w-full md:w-6/12 lg:w-4/12 mt-2 mb-2">
									<>
										<RecipeMacroTable macros={this.props.meal}/>
										<label className="w-full block my-2">
											Change quantity serving
											<input type="text" className="formControl my-2" onChange={this.handlePreparedFoodQuantity.bind(this)} value={this.state.quantityInput}/>
											grams
										</label>
									</>
								</div>
							</div>
						</div>
					}
				</div>
		);
	}
}
