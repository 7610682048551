import React from 'react';
import CircleChart from '../../KetoduxCalculator/CircleChart';

const CurrentProgressStats = (props) => {
	const carbs = props.stats.dailyCarbGrams,
	fat = props.stats.dailyFatGrams,
	protein = props.stats.dailyProteinGrams,
	totalGrams = carbs + fat + protein,
	fatRatio = (fat/totalGrams) * 100,
	carbsRatio = (carbs/totalGrams) * 100,
	proteinRatio = (protein/totalGrams) * 100;
	return (
		<section>
			<div className="currentProgressStats">
				<div className="text-center mb-5">
					<h2 className="text-2xl font-medium text-primary">Recommended daily intake</h2>
					<p>Use these values (based on your last recorded stats) to help track your progress</p>
				</div>
			</div>
			<div className="flex max-w-2xl w-full mx-auto">
				<div className="text-center">
					<div className="progressCircleChart">
						<CircleChart strokeColor="#f15a22" percentage={carbsRatio} label="Carbs" grams={carbs}/>
					</div>
				</div>
				<div className="text-center">
					<div className="progressCircleChart">
						<CircleChart strokeColor="#f15a22" percentage={fatRatio} label="Fat" grams={fat}/>
					</div>
				</div>
				<div className="text-center">
					<div className="progressCircleChart">
						<CircleChart strokeColor="#f15a22" percentage={proteinRatio} label="Protein" grams={protein}/>
					</div>
				</div>
			</div>
		</section>
	);
}
export default CurrentProgressStats;