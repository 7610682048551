import React, { Component } from 'react';

export default class AddMealToJournalOption extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	options: this.props.options,
	  };
	}
	componentDidMount(){
		this.setState({
			options: this.props.options,
		})
		
	}
	handleAddMeal(){
		let fat = 0 + this.state.options.recipe.totalFat
		let carbs = 0 + this.state.options.recipe.totalCarbs
		let protein = 0 + this.state.options.recipe.totalProtein
		
		let MealObject = {
			label: this.state.options.title,
			fat: fat,
			carbs: carbs,
			protein: protein,
		}

		this.props.handleAddMeal(MealObject);
		
	}
	render() {
		const {recipe} = this.props.options;
		return (
			<div>
				{recipe?
					<p>Scheduled meal in meal plan:&emsp;
						<button className="addMealToJournalButton" onClick={this.handleAddMeal.bind(this)}>
							<span className="flaticon-add"></span> {this.state.options.title}
						</button>
					</p>
				:null}
				
				
			</div>
		);
	}
}
