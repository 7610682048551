import React, { Component } from 'react';
import JournalEntryForm from './JournalEntryForm';
import CurrentDayFinder from './CurrentDayFinder';
import JournalEntryRow from './JournalEntryRow';
import {animateScroll as scroll } from 'react-scroll'
let weekday = require('weekday');
export default class ExistingJournalEntries extends Component {
	constructor(props) {
	  super(props);
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	  this.state = {
	  	mode: 'showEntries',
	  	editEntry: {},
	  	timeKeeping: this.props.timeKeeping,
	  	width: 0,
	  };
	}
	componentDidMount(){
		this.updateWindowDimensions();
	  	window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({timeKeeping: this.props.timeKeeping})
	}
	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	handleStartEditingItem(entry){
		this.props.handleMode('edit', entry);
		this.setState({
			editEntry: entry,
			mode: 'editEntry',
			weekDay: entry.weekdayNumber,
		})
		this.handleScroll();
	}
	handleScroll(){
		scroll.scrollTo(0, {duration: 0,smooth: false,})
	}
	closeOnSave(){
		this.props.handleMode('init');
		this.setState({
			editEntry: {},
			mode: 'showEntries'
		})
		this.handleScroll();
	}
	render() {
		if(this.state.mode === 'showEntries'){
			return (
				<div className="journalEntriesForm">
					<section>
						<CurrentDayFinder/>
						<div className="hidden lg:block">
							<div className="lg:flex w-full journalEntriesTableRow py-2 shadow-sm border-gray-300 border mb-2 rounded-lg bg-white">
								<div className="w-3/12 px-2 text-left"><strong>Date</strong></div>
								<div className="w-2/12 px-2 text-left"><strong>Calorie Intake</strong></div>
								<div className="w-2/12 px-2 text-left"><strong>Goal</strong></div>
								<div className="w-2/12 px-2 text-left"><strong>Calories Burned</strong></div>
								<div className="w-2/12 px-2 text-left"><strong>Ketone Reading</strong></div>
								<div className="w-1/12 px-2 text-right"><strong>Update</strong></div>
							</div>
						</div>
						{Object.keys(this.props.journalEntries).map((yearName, i) => (
						    <span key={i}>{Object.keys(this.props.journalEntries[yearName]).map((monthName, i) => (
					     		<span key={i}>{Object.keys(this.props.journalEntries[yearName][monthName]).map((dayName, i) => (
						     		<span key={i}>
						     			<CurrentDayFinder day={dayName} month={monthName} year={yearName}/>
										<div className="journalRow">
											<JournalEntryRow timestamp={this.props.journalEntries[yearName][monthName][dayName].journal.timestamp}
												day={dayName} month={monthName} year={yearName}
												weekday={weekday(this.props.journalEntries[yearName][monthName][dayName].journal.weekDay)}
												dayCalories={this.props.journalEntries[yearName][monthName][dayName].journal.totals.dayCalories.toFixed(0)}
												dayCaloriesGoal={this.props.journalEntries[yearName][monthName][dayName].journal.totals.dailyCalorieGoal.toFixed(0)}
												totalBurn={this.props.journalEntries[yearName][monthName][dayName].journal.totalBurn.toFixed(0)}
												entry={this.props.journalEntries[yearName][monthName][dayName].journal}
												handleEntry={this.handleStartEditingItem.bind(this)}
												userRecipes={this.props.userRecipes}
												ketoneReading={this.props.journalEntries[yearName][monthName][dayName].journal.ketoneReading} 
											/>
										</div>
						     		</span>
							    ))}
							</span>))}
						    </span>
						))}
					</section>
				</div>	
			);
		}
		if(this.state.mode === 'editEntry'){
			return(
				<div>
					<JournalEntryForm uid={this.props.uid} 
						editEntry={this.state.editEntry} 
						closeOnSave={this.closeOnSave.bind(this)}
						mealPlans={this.props.mealPlans}
						reloadUserData={this.props.reloadUserData}
						timeKeeping={this.props.timeKeeping}
						recipesMacros={this.props.recipesMacros}
						user={this.props.user}
						day={this.state.editEntry.day} 
						month={this.state.editEntry.month}
						year={this.state.editEntry.year}
						weekDay={this.state.editEntry.weekDay}
						trueWeekDay={this.state.editEntry.weekDay}
						windowWidth={this.state.width}
						recipesMacroTable={this.props.recipesMacroTable}
						userRecipes={this.props.userRecipes}
					/>
				</div>
			);
		}					     
	}
}
